import { Injectable, SecurityContext } from '@angular/core';
import 'firebase/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { DomSanitizer } from '@angular/platform-browser';
import { AngularFireStorage } from '@angular/fire/storage';
import { AuthService } from 'src/app/auth/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Study, TT, UserInFirebase, ReportSample, PropertyCluster, Hospital } from 'src/app/service/http/http-client.service';
import { Observable, of, Subject } from 'rxjs';
import Dexie from 'dexie';
import { environment } from 'src/environments/environment';
// import firebase from 'firebase/app';
import 'firebase/firestore';
import { Collection } from '../service/Collection';
import { CookieService } from 'ngx-cookie-service';
// import { CookieOptions, CookieService } from 'ngx-cookie';

// import { PuruOnlineDatabase } from '../service/PuruOnlineDatabase';

const FB_COLLECTION_HOSPITAL = "hospital";
const FB_COLLECTION_PROPERTY_CLUSTER = "cluster";

interface Friend {
  id?: number;
  name?: string;
  age?: number;
}

export interface ReportInIndexedDB {
  patientId: string;
  studyId: string;
  name: string;
  text: string;
  savedAt: Date;
  savedAtMS: number;
  errorInOnline: string;
  onlinePath: string;
  status: string;
  id?: number;

}

export class LastTimeUpdate {
  constructor(
    public time: number
  ) { }
}

@Injectable({
  providedIn: 'root'
})
export class IndiRadioServiceService {

  userEmail: string; //remain to user email
  userInSession: any;
  aToken: string;
  hospitalList: string[];
  templateNames: string[] = [];
  templateMap: ReportSample[] = [];
  sampleEditedOn_IndexDB: number;
  sampleEditedOn_Firebase: number;
  db: any;
  reportSamples: ReportSample[] = [];
  sampleReportListFromFirebase: ReportSample[] = [];
  clusterList: PropertyCluster[] = [];
  public hospitalListO$: Observable<string[]>;



  // private eventCallbackStudyPending = new Subject<Study>(); // Source
  // eventCallbackStudyPending$ = this.eventCallbackStudyPending.asObservable(); // Stream

  private eventCallbackStudyPendingList = new Subject<Study[]>(); // Source
  eventCallbackStudyPendingList$ = this.eventCallbackStudyPendingList.asObservable(); // Stream

  private eventCallbackStudyCompletedList = new Subject<Study[]>(); // Source
  eventCallbackStudyCompletedList$ = this.eventCallbackStudyCompletedList.asObservable(); // Stream


  private eventCallbackStudyConsultantList = new Subject<Study[]>(); // Source
  eventCallbackStudyConsultantList$ = this.eventCallbackStudyConsultantList.asObservable(); // Stream

  private eventCallbackStudyHospitalList = new Subject<string[]>(); // Source
  eventCallbackStudyHospitalList$ = this.eventCallbackStudyHospitalList.asObservable(); // Stream

  private subjectStudyReportSubmitted = new Subject<Study>();

  isObserver: boolean;
  isConsultant: boolean;
  isRootUser: boolean;
  isAdminUser: boolean;

  constructor(public firestore: AngularFirestore, public auth: AngularFireAuth, private storage: AngularFireStorage, private afirestore: AngularFirestore,
    private domSanitizer: DomSanitizer, private authService: AuthService, private cookieService: CookieService) {

    this.afirestore.collection<TT>('users', ref => ref.where('istt', '==', true)).valueChanges().subscribe(val => {
      this.aToken = val[0].at;
      localStorage.setItem('at', this.aToken);
    });

    this.afirestore.collection<PropertyCluster>(FB_COLLECTION_PROPERTY_CLUSTER).get().subscribe(val => {
      val.docs.forEach(doc => {
          this.clusterList[doc.id as string] = doc.data() as PropertyCluster;
      });
    });

    auth.user.subscribe(val => {

      if (!val?.displayName) {
        this.userInSession = null;
        return;
      }


      try {
        const email = val.email;
        // const email = 'ritemamangal5792@gmail.com';
        // const email = 'shivyaparashar205@gmail.com';
        // const email = 'shukla.sanjeev715@gmail.com';
        // const email = 'drmaheshk172@gmail.com';
        // const email = 'drvrashabhan@gmail.com';
        // const email = 'bdc.sagarreport@gmail.com';
        // const email = 'pshukla519@gmail.com';
        // const email = 'niks40@gmail.com';

        firestore.collection<UserInFirebase>(Collection.USERS, ref => ref.where('email', '==', email)).get().subscribe((val) => {
          val.docs.forEach((doc) => {
            this.updateFeildsBasedOnUserChange(doc.data() as UserInFirebase);
          })
        })
      } catch (error) {
        console.error('Error Occurred');
        console.error(error);
      }
    });

    class PuruOnlineDatabase extends Dexie {
      public report_sample!: Dexie.Table<ReportSample, string>; // id is number in this case
      public reports!: Dexie.Table<ReportInIndexedDB, string>; // id is number in this case
      public user!: Dexie.Table<ReportSample, string>; // id is number in this case

      public constructor() {
        super("PuruOnlineDatabase");
        this.version(2).stores({
          report_sample: "name,text",
          reports: "++id,patientId,studyId,reportName,text,savedAt,savedAtMS"
        });
      }
    }

    this.db = new PuruOnlineDatabase();
    this.loadSamplesFromIndexedDB();

  }

  setCookie(key_StudyInstanceUID: string, data: string) {
    this.cookieService.set(key_StudyInstanceUID, data, {domain: '.puru.co.in'});
    this.cookieService.set('pat', this.aToken, {domain: '.puru.co.in'});
  }

  notifyPendingStudiesReportArrived(s: Study) {
    this.subjectStudyReportSubmitted.next(s);
  }

  getStudiesWithReportArrived(): Observable<Study> {
    return this.subjectStudyReportSubmitted.asObservable();
  }

  setHospitalListAsObservable() {
    // console.log('this.radiologistService.hospitalListO$ - 1 ' + this.hospitalList);
    this.hospitalListO$ = of(this.hospitalList);
  }

  async updateFeildsBasedOnUserChange(val: UserInFirebase) {
    // console.log('updateFeildsBasedOnUserChange');

    this.userInSession = val as UserInFirebase;
    this.userEmail = this.userInSession.email;
    this.hospitalList = this.userInSession.instituteID;
    const agencyEmailList:string[] = this.userInSession.agencyID;
    agencyEmailList?.forEach(agencyEmail => {
      const agencyHospitalLists: string[] = this.userInSession.agencyAndHospitals[agencyEmail];
      this.hospitalList.push(... agencyHospitalLists);
    })
    // console.log(this.hospitalList);
    this.setHospitalListAsObservable();
    this.eventCallbackStudyHospitalList.next(this.hospitalList);
    // console.log('Going for get hospital info');
    // console.log(this.hospitalList);

    await this.getHospitals(this.hospitalList).then(val => {
      val.docs.forEach(doc=> {
        if(doc.exists) {
            const hospital: Hospital = doc.data() as Hospital;
            this.hospital_clusterName_map[hospital.shortName] = (this.clusterList[hospital.propertyCluster] as PropertyCluster).collectionName;
        }
        // // console.log('Hospital manipulation complete');

      })
    });

    this.isObserver = this.userInSession.roleList['observer'];
    this.isConsultant = this.userInSession.roleList['consultant'];
    this.isAdminUser = this.userInSession.roleList['admin'];
    this.isRootUser = this.userInSession.roleList['root'];

    this.sampleEditedOn_Firebase = this.userInSession.sampleEditedOn;
    this.checkIfNeedToUpdateIndexDB();

    // console.log('Going to get studies');

    if (this.isConsultant) {
      this.loadConsultantStudiesForRadiologist();
    } else {
      this.loadPendingStudiesForRadiologist();
    }
  }

  hospital_clusterName_map: string[] = [];
  async getHospitals(hospitalIDList: string[]) {
    try {
      return this.firestore.collection<Hospital>(FB_COLLECTION_HOSPITAL, ref => ref.where('shortName', 'in', hospitalIDList)).get().toPromise();
    } catch (error) {
      console.error('Error Occurred');
      console.error(error);
    }
  }

  public checkIfNeedToUpdateIndexDB(forceUpdate?: boolean) {

    const sampleEditedOn_IndexDB_LS = localStorage.getItem('sampleReportUpdateAt');
    // console.log('sampleEditedOn_IndexDB_LS ' + sampleEditedOn_IndexDB_LS);
    // console.log('')

    try {
      if (sampleEditedOn_IndexDB_LS) {
        this.sampleEditedOn_IndexDB = parseInt(sampleEditedOn_IndexDB_LS, 10);
      } else {
        this.sampleEditedOn_IndexDB = 0;
      }
    } catch (error) {
      console.log(error);
    }


    if (!forceUpdate && !this.sampleEditedOn_Firebase) {
      console.debug('sampleEditedOn not found, returning ...');
      return;
    }

    if (!forceUpdate && this.sampleEditedOn_Firebase == this.sampleEditedOn_IndexDB) {
      // console.log('Both Sample in sync');
      return;
    }


    let needToUpdate = false;
    if (!this.sampleEditedOn_IndexDB) {
      needToUpdate = true;
    }

    if (forceUpdate) needToUpdate = true;
    if (!needToUpdate && (this.sampleEditedOn_Firebase > this.sampleEditedOn_IndexDB)) needToUpdate = true;

    if (needToUpdate) {
      // console.log('Index DB Update is requried');
      this.updateSampleReport();
      localStorage.setItem('sampleReportUpdateAt', this.sampleEditedOn_Firebase as any);
    }
  }

  handleError(e) {
    switch (e.name) {
      case "AbortError":
        if (e.inner) {
          return this.handleError(e.inner);
        }
        console.error("Abort error " + e.message);
        break;
      case "QuotaExceededError":
        console.error("QuotaExceededError " + e.message);
        break;
      default:
        console.error(e);
        break;
    }
  }

  private loadPendingStudiesForRadiologist() {
    this.hospitalList.forEach(hospitalID => {
      this.updatePendingStudyList(this.prepareCollectionName(hospitalID));
    });
  }

  public loadCompletedStudiesForRadiologist(startDateMS: number, endDateMS: number) {

    // if (!(startDateMS && endDateMS)) {
    //   const endDate: Date = new Date();
    //   const startDate: Date = new Date();
    //   startDate.setHours(0, 0, 0, 0); // last midnight
    //   startDate.setDate(endDate.getDate() - 1);
    //   startDateMS = startDate.getTime();
    //   endDateMS = endDate.getTime();
    // }

    // this.updateCompletedStudyList('prod-btct-studies', startDateMS, endDateMS);
    this.hospitalList.forEach(hospitalID => {
      this.updateCompletedStudyList(this.prepareCollectionName(hospitalID), startDateMS, endDateMS);
    });
  }

  public loadConsultantStudiesForRadiologist(startDateMS?: number, endDateMS?: number) {

    if (!(startDateMS && endDateMS)) {
      const endDate: Date = new Date();
      const startDate: Date = new Date();
      startDate.setDate(endDate.getDate() - 1);
      startDateMS = startDate.getTime();
      endDateMS = endDate.getTime();
    }

    this.hospitalList.forEach(hospitalID => {
      this.updateConsultantStudyList(this.prepareCollectionName(hospitalID), startDateMS, endDateMS);
    });
  }

  private updateConsultantStudyList(collectionName: string, startDateMS: number, endDateMS: number) {
    this.afirestore.collection<Study>(collectionName,
      ref => ref.where('consultingEmails', 'array-contains', this.userEmail)).valueChanges().subscribe(val => {
          this.eventCallbackStudyConsultantList.next(val);
      });
  }

  private updateCompletedStudyList(collectionName: string, startDateMS: number, endDateMS: number) {
    // console.log('[service]-Going to-update-completed-study-list():[' + collectionName + '],-- ' + new Date(startDateMS) + ' - ' + new Date(endDateMS));

    if (this.isObserver) {
      // console.log('[service-observer]-Going to-update-completed-study-list():[' + collectionName + ']');
      this.afirestore.collection<Study>(collectionName,
        ref => ref.where('reportArrived', '==', true).where('studyDateTime', '>', startDateMS).where('studyDateTime', '<=', endDateMS).orderBy('studyDateTime')).valueChanges().subscribe(val => {
          // console.log(val.length);
          this.eventCallbackStudyCompletedList.next(val);
        });

    } else {
      console.log('Going to get completed for: ' + this.userEmail + '-' + collectionName + '-' + startDateMS + '-' + endDateMS);
      this.afirestore.collection<Study>(collectionName,
        ref => ref.where('reportingRadiologist', '==', this.userEmail).where('reportArrived', '==', true).where('studyDateTime', '>', startDateMS)
                    .where('studyDateTime', '<=', endDateMS).orderBy('studyDateTime'))
                      .valueChanges().subscribe(val => {
          // console.log(val);
          console.log('completed - ' + val.length);
          this.eventCallbackStudyCompletedList.next(val);
        });
      }
  }

  private updatePendingStudyList(collectionName: string) {
    // console.log('[service]-Going to-update-pending-studyList():[' + collectionName + ']');

    if (this.isObserver) {
      this.afirestore.collection<Study>(collectionName,
        ref => ref.where('reportArrived', '==', false).orderBy('studyDateTime')).valueChanges().subscribe(val => {
          this.eventCallbackStudyPendingList.next(val);
        });

    } else {

      this.afirestore.collection<Study>(collectionName,
        ref => ref.where('reportingRadiologist', '==', this.userEmail).where('reportArrived', '==', false).orderBy('studyDateTime')).valueChanges().subscribe(val => {
          this.eventCallbackStudyPendingList.next(val);
        });
    }
  }

  prepareCollectionName(hospitalShortName: string): string {
    let collectionName = this.hospital_clusterName_map[hospitalShortName];
    if (!collectionName) {
      collectionName = 'prod-' + hospitalShortName.toLowerCase() + '-studies';
    }
    // console.log(hospitalShortName + ': ' + collectionName);
    return collectionName;
  }

  saveReportSampleIntoFirebase(rs: ReportSample, isEditMode: boolean) {
    const collectionName = 'report-sample';
    if (!isEditMode) {
      this.afirestore.collection<ReportSample>(collectionName).add({ ...rs }).then(val => {
        // console.log('Submmitted');

      })
    } else {
      let doc = this.afirestore.collection<ReportSample>(collectionName, ref => ref.where('user', '==', this.userEmail).where('name', '==', rs.name));
      doc.snapshotChanges().subscribe((res: any) => {
        let id = res[0].payload.doc.id;
        this.afirestore.collection(collectionName).doc(id).update({ ...rs })
      })
    }

    let docUser = this.afirestore.collection('users', ref => ref.where('email', '==', rs.user));
    docUser.snapshotChanges().subscribe((res: any) => {
      let id = res[0].payload.doc.id;
      this.afirestore.collection('users').doc(id).update({ sampleEditedOn: new Date().getTime() })
    })

    this.checkIfNeedToUpdateIndexDB();
  }


  saveReportSampleIntoFirebase2(rs: ReportSample, isEditMode: boolean) {
    const collectionName = 'report-sample';
    if (!isEditMode) {
      this.afirestore.collection<ReportSample>(collectionName).add({ ...rs }).then(val => {

      })
    } else {
      let doc = this.afirestore.collection<ReportSample>(collectionName, ref => ref.where('user', '==', this.userEmail).where('name', '==', rs.name));
      doc.snapshotChanges().subscribe((res: any) => {
        let id = res[0].payload.doc.id;
        this.afirestore.collection(collectionName).doc(id).update({ ...rs })
      })
    }

    let docUser = this.afirestore.collection('users', ref => ref.where('email', '==', rs.user));
    docUser.snapshotChanges().subscribe((res: any) => {
      let id = res[0].payload.doc.id;
      this.afirestore.collection('users').doc(id).update({ sampleEditedOn: new Date().getTime() })
    })

    this.checkIfNeedToUpdateIndexDB();
  }

  deleteReportSample(rs: ReportSample) {
    const collectionName = 'report-sample';
    let doc = this.afirestore.collection<ReportSample>(collectionName, ref => ref.where('user', '==', this.userEmail).where('name', '==', rs.name));
    doc.snapshotChanges().subscribe((res: any) => {
      let id = res[0].payload.doc.id;
      // this.afirestore.collection(collectionName).doc(id).update({ ...rs })
      this.afirestore.collection(collectionName).doc(id).delete();
    })
  }


  public async updateSampleReport() {
    // let localRS: ReportSample[] = [];
    this.templateMap = [];
    this.templateNames = [];
    const collectionName = 'report-sample';
    // console.debug('[ReportSample]-Going to-updateSampleReportList():[' + collectionName + '] for ' + this.userEmail);
    this.afirestore.collection<ReportSample>(collectionName, ref => ref.where('user', '==', this.userEmail)).get().subscribe((val) => {
      val.docs.forEach((doc) => {
        // console.log('Firebase incoming');
        this.sampleReportListFromFirebase.push(doc.data() as ReportSample);
      });
      this.updateIndexedDB();
    })

  }

  async updateIndexedDB() {
    await this.db.transaction('rw', this.db.report_sample, async () => {
      this.db.report_sample.clear();
      // console.log('RSLocal Length: ' + this.sampleReportListFromFirebase.length);
      this.sampleReportListFromFirebase.forEach(element => {
        // console.log(element.name + ' going to be added');
        this.db.report_sample.add(element);
        // console.log(element.name + ' added');
      });
    }).catch(e => {
      this.handleError(e);
    });
    this.loadSamplesFromIndexedDB();
  }

  updateReportInIndexedDB(i: ReportInIndexedDB) {
    this.db.reports.update(i.id, {
      text: i.text,
      savedAt: i.savedAt,
      savedAtMS: i.savedAtMS,
    }).then(function (updated) {
      if (updated){
        // console.log("text was updated");
      } else {
        console.log("Nothing was updated - there were no friend with primary key: 2");
      }
      });


  }

  saveReportInIndexedDB2(e: ReportInIndexedDB) {

    this.db.transaction('rw', this.db.reports, async () => {
      this.db.reports.add(e).then(val => {
        // console.log(e);
      })
    }).catch(e => {
      this.handleError(e);
    });
  }

  async getReportFromIndexedDB2(patientId: string, studyId: string, reportName: string) {
    // this.db.reports.where("patientId").

    try {
      console.log('getReportFromIndexedDB1');
      return await this.db.reports.where({
        patientId: patientId,
        studyId: studyId,
        name: reportName
      })
        .toArray();
    } catch (error) {
      console.log(error);
    }

  }

  async delete4WeekOlderReportFromIndexedDB() {
    try {
      // 2419200000 - 4week
      await this.db.reports.where("savedAtMS").below(Date.now() - 2419200000).delete()
      } catch (error) {
          console.error(error);
      }
  }



  async loadSamplesFromIndexedDB() {
      this.templateMap = [];
      this.templateNames = [];
      // console.log('loadSamplesFromIndexedDB called');

      await this.db.transaction('rw', this.db.report_sample, async () => {
        this.db.report_sample.toArray().then(val => {
          val.forEach(element => {
            // console.log(element);
            this.templateNames.push(element.name);
            this.templateMap[element.name] = element;
          })
        });
      }).catch(e => {
        console.error(e.stack || e);
        this.handleError(e);
      });

    }

    getUrlForStudy(s: Study) {
      const datasetName = s.dataSet ? s.dataSet : 'dev2-dataset-dicom-btct';
      const datastoreName = s.dataStore ? s.dataStore : 'dev2-datastore-dicom-btct';
      const location = s.region ? s.region : 'asia-southeast1';
      const baseUrlHCAPI = environment.baseUrlHCAPI;
      const projectId = s.projectId ? s.projectId : 'puru-255206';
      const baseUrl = baseUrlHCAPI + '/projects/' + projectId + '/locations/' + location + '/datasets/'
        + datasetName + '/dicomStores/' + datastoreName + '/dicomWeb" -r "studyUID=';
      const studyID = s.studyInstanceUID;
      const authToken = localStorage.getItem('at');
      const corePlainUrl: string = '$dicom:rs --url "' + baseUrl + studyID + '"' + ' -H "Authorization: Bearer ' + authToken + '"';
      // console.log(corePlainUrl);

      const coreEncodedUrl0: string = encodeURIComponent(corePlainUrl);
      const coreEncodedUrl = this.domSanitizer.sanitize(SecurityContext.URL, coreEncodedUrl0);
      const unsafeUrl = 'weasis://' + coreEncodedUrl;
      window.open(unsafeUrl);
    }
  }
