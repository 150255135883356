import { Component, OnInit, Inject, SecurityContext } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularFireStorage } from '@angular/fire/storage';
import { DomSanitizer } from '@angular/platform-browser';

export interface ViewDocumentData {
  link: string;
}

@Component({
  selector: 'app-view-document',
  templateUrl: './view-document.component.html',
  styleUrls: ['./view-document.component.css']
})
export class ViewDocumentComponent implements OnInit {

  fileUrl: string;
  mainUrl: string;
  isLoading = false;
  constructor(public dialogRef: MatDialogRef<ViewDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ViewDocumentData, private storage: AngularFireStorage, public domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.previewFile();
  }

  previewFile() {
    const fileRef = this.storage.ref(this.data.link);
    fileRef.getDownloadURL().subscribe(val => {
      // console.log(val);
      // window.open(val);
      this.fileUrl = val;
      this.fileUrl = this.domSanitizer.sanitize(SecurityContext.URL, this.fileUrl);
      this.fileUrl = encodeURIComponent(this.fileUrl);
      this.mainUrl = 'https://docs.google.com/gview?url=' + this.fileUrl +'&embedded=true';
      this.isLoading = false;
      return this.mainUrl;
    }, err => {
      console.log(err);
    });
  }

  downloadFile() {
    const filePath = this.data.link;
    const fileRef = this.storage.ref(filePath);
    fileRef.getDownloadURL().subscribe(val => {
      // console.log(val);
      window.open(val);
    }, err => {
      console.log(err);
    });
  }



}
