import { Component, OnInit } from '@angular/core';
import { Hospital } from 'src/app/service/http/http-client.service';

@Component({
  selector: 'app-hospital-dashboard',
  templateUrl: './hospital-dashboard.component.html',
  styleUrls: ['./hospital-dashboard.component.css']
})
export class HospitalDashboardComponent implements OnInit {

  selectedHospital: Hospital;
  constructor() { }

  ngOnInit(): void {
  }

  captureHospitalSelection(hospital: Hospital) {
    this.selectedHospital = hospital;
  }

}
