// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  // studyCollectionName: 'dev2-btct-studies',
  location : 'asia-southeast1',
  baseUrlHCAPI : 'https://healthcare.googleapis.com/v1',
  viewerBaseUrl: 'http://localhost:3000',
  offlineRequestTopic: 'dev-btct-dicom-to-be-uploaded',
  firebase: {
    apiKey: 'AIzaSyC8xG1onsEY9rvAdfBxNTXUJBK1gpsJa0Y',
    // authDomain: 'puru-255206.firebaseapp.com',
    authDomain: 'puru.co.in',
    databaseURL: 'https://puru-255206.firebaseio.com',
    projectId: 'puru-255206',
    storageBucket: 'puru-255206.appspot.com',
    appId: '1:766256023666:web:a0ab7ae86f47f5a3679aeb'
    // messagingSenderId: 'puru-255206'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
