import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Collection } from 'src/app/service/Collection';
import { FirebaseService } from 'src/app/service/firebase/firebase.service';
import { UserInFirebase } from 'src/app/service/http/http-client.service';


@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {
  dataSource: MatTableDataSource<UserInFirebase>;

  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  // @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = ['col1'];
  @Output() UserInFirebaseSelectionEvent = new EventEmitter<UserInFirebase>();

  list: UserInFirebase[] = [];
  selectedUserInFirebase: UserInFirebase;
  constructor(public fs: FirebaseService) {
    this.populateUserInFirebaseList();
  }

  ngOnInit(): void {
  }

  private populateUserInFirebaseList() {
    this.fs.getAllDocuments(Collection.USERS).subscribe(val => {
      val.docs.forEach(doc => {
          const u: UserInFirebase = doc.data() as UserInFirebase;
        if (!u.name.includes('token')) this.list.push(u);
      });
      this.configureDataSource();
    });
  }


  select(h: UserInFirebase) {
    this.selectedUserInFirebase = h;
    // console.log(h);
    this.UserInFirebaseSelectionEvent.emit(this.selectedUserInFirebase);

  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  private configureDataSource() {
    this.dataSource = new MatTableDataSource(this.list);
  }

}
