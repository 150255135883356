import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Collection } from '../Collection';
import { Hospital, UserInFirebase } from '../http/http-client.service';



@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  hospitalList: Hospital[] = [];
  
  
  getUserInFirebaseByEmail(email: string) {
    throw new Error('Method not implemented.');
  }


  constructor(public firestore: AngularFirestore) { 
    console.log('init FirebaseService');
    // this.populateHospitalList();

  }

  getAllDocuments(collectionName: string) {
    return this.firestore.collection(collectionName).get();
  }

  getHospitalByEmail(email: string) {
    return this.firestore.collection(Collection.HOSPITAL, ref => ref.where('email', '==', email)).get();
  }

  saveHospital(hospital: Hospital) {
    return this.firestore.collection(Collection.HOSPITAL).doc(hospital.shortName).set(hospital);
  }

  saveUserInFirebase(finalUserInFirebase: UserInFirebase) {
    return this.firestore.collection(Collection.USERS).doc(finalUserInFirebase.email).set(finalUserInFirebase);
  }


}
