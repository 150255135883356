import { StudyInHomeController } from './../home/home.component';
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpClientService, Patient, Study, UserInFirebase } from './../service/http/http-client.service';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
// import * as dicomParser from 'dicom-parser';
import { FormControl } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import 'firebase/firestore';
import { environment } from 'src/environments/environment';

// export interface UserInFirebase {
//   name: string;
//   email: string;
//   roleList: string[];
//   instituteID: string[];
//   agencyID: string[];
//   agencyHospitals: string[];
// }



// export interface InstituteTechnician {
//   tEmail: string;
//   instituteId: string;
// }

// export interface RadiologistInstitute {
//   doctorEmail: string;
//   institu teId: string;
// }

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {

  // patientHistoryFormControl = new FormControl();
  isWaiting = false;
  seriesList: string[] = [];
  instanceList: string[] = [];
  patientID: string;
  patientName: string;
  seriesInstanceUID: string;
  studyInstanceUID: string;
  studyId: string;
  studyDateTime: string;
  instituteName = 'BTCT_1';
  uploadPercent: Observable<number>;
  patientHistoryFormControl = new FormControl();


  userInSession: UserInFirebase;
  instituteIDInSession: string;
  doctorsEmailsOfInstitute: string[];
  mainFolder = 'dicom-to-hcapi';


  files: any[] = [];
  userCollection: AngularFirestoreCollection<UserInFirebase>;
  studyCollection: AngularFirestoreCollection<Study>;
  modality: string;
  studyDesc: string;


  datastore = 'prod-datastore-dicom-xps';
  dataset = 'prod-dataset-dicom-xps';
  hcapiRegion = 'asia-southeast1';
  projectId = 'puru-255206';
  reportingRadiologist = 'agam.puru@gmail.com';

  constructor(auth: AngularFireAuth, firestore: AngularFirestore, private storage: AngularFireStorage,
    private httpClient: HttpClientService) {
    this.userCollection = firestore.collection<UserInFirebase>('users');
    // this.studyCollection = firestore.collection<StudyInHomeController>('dev-upload-studies');

    auth.user.subscribe(val => {
      const userEmail = val.email;
      console.log('UploadComponent: '+ userEmail);
      // firestore.collection<UserInFirebase>('users', ref => ref.where('email', '==', val.email)).valueChanges().subscribe(val2 => {
      //   this.userInSession = val2[0] as UserInFirebase;
      //   console.log(this.userInSession);
      //   // find its institue
      //   if (this.userInSession.roleList[0]?.startsWith('r') || this.userInSession.roleList[0]?.length > 1) {
      //     const localInsIDs: string[] = this.userInSession.instituteID;
      //     if (localInsIDs.length === 1) {
      //       this.instituteIDInSession = this.userInSession.instituteID[0];
      //       console.log(this.instituteIDInSession);
      //       const insNameInLowerCase = this.instituteIDInSession.toLowerCase();
      //       this.studyCollection = firestore.collection<Study>('prod-' + insNameInLowerCase + '-studies');
      //       this.datastore = 'prod-datastore-dicom-' + insNameInLowerCase;
      //       this.dataset = 'prod-dataset-dicom-' + insNameInLowerCase;


      //     } else {
      //       // multi organisation doctor
      //     }

      //   } else {
      //     this.instituteIDInSession = this.userInSession.instituteID[0];
      //     console.log(this.instituteIDInSession);
      //     const insNameInLowerCase = this.instituteIDInSession.toLowerCase();
      //     this.studyCollection = firestore.collection<Study>('prod-' + insNameInLowerCase + '-studies');
      //     this.datastore = 'prod-datastore-dicom-' + insNameInLowerCase;
      //     this.dataset = 'prod-dataset-dicom-' + insNameInLowerCase;
      //   }


      // });

    });
  }

  ngOnInit(): void {


  }



  // readFile(file: File) {
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     const dicomPart10AsArrayBuffer: any = reader.result;
  //     const byteArray = new Uint8Array(dicomPart10AsArrayBuffer);
  //     const dataSet = dicomParser.parseDicom(byteArray);

  //     const patientName = dataSet.string('x00100010');
  //     const patientId = dataSet.string('x00100020');
  //     const studyInstanceUId = dataSet.string('x0020000d');
  //     const studyId = dataSet.string('x00200010');
  //     const seriesInstanceUId = dataSet.string('x0020000e');
  //     console.log('Patient Name = ' + patientName);
  //     return false;
  //     // study id
  //     // study instance uid
  //     // series id
  //   };
  //   return reader.readAsArrayBuffer(file);

  // }



  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    this.uploadFilesSimulator(0);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  uploadFile() {
    // let currentfileCount = 0;
    // for (let index = 0; index < this.files.length; index++) {
    //   const f = this.files[index];
    //   // }
    //   // this.files.forEach(f => {
    //   let isFileValid = true;
    //   let instanceUID: string;
    //   const reader = new FileReader();
    //   reader.onload = () => {
    //     const dicomPart10AsArrayBuffer: any = reader.result;
    //     const byteArray = new Uint8Array(dicomPart10AsArrayBuffer);
    //     // const dataSet = dicomParser.parseDicom(byteArray);

    //     const localPatientName = dataSet.string('x00100010');
    //     const localPatientId = dataSet.string('x00100020');
    //     const localStudyInstanceUId = dataSet.string('x0020000d');
    //     const localStudyId = dataSet.string('x00200010');
    //     const localSeriesInstanceUID = dataSet.string('x0020000e');
    //     instanceUID = dataSet.string('x00080018');
    //     if (index === 0) {
    //       this.patientID = localPatientId;
    //       this.patientName = localPatientName;
    //       this.studyInstanceUID = localStudyInstanceUId;
    //       this.studyId = localStudyId;
    //       this.modality = dataSet.string('x00080060');
    //       this.studyDesc = dataSet.string('x00081030')

    //     }
    //     // console.log('Patient Name = ' + patientName + ' IUID: ' + instanceUID);
    //     if (localStudyInstanceUId !== this.studyInstanceUID) {
    //       isFileValid = false;
    //     } else {
    //       // check if series instance is new .. insert or update.
    //       if (this.seriesList.indexOf(localSeriesInstanceUID) === -1) {
    //         this.seriesList.push(localSeriesInstanceUID);
    //       }
    //     }
    //     if (isFileValid) {
    //       // console.log('IUID: ' + instanceUID + ' ->sending to upload');
    //       // const file = f;
    //       const filePath = '/' + this.mainFolder + '/' + this.instituteIDInSession + '/' + this.studyInstanceUID + '/' + f.name;
    //       // const fileRef = this.storage.ref(filePath);
    //       try {
    //         console.log('going to upload ' + filePath);
    //         const task = this.storage.upload(filePath, f);

    //         // observe percentage changes
    //         this.uploadPercent = task.percentageChanges();
    //         // get notified when the download URL is available
    //         task.snapshotChanges().pipe(
    //           finalize(
    //             () => {
    //               currentfileCount++;
    //               if (currentfileCount === this.files.length) {
    //                 // TODO: submit info to db


    //                 console.log('completed');

    //                 // TODO: call the function
    //                 const bucketName = environment.firebase.storageBucket;
    //                 let filePathForCloud = this.mainFolder + '/' + this.instituteIDInSession + '/' + this.studyInstanceUID;
    //                 // console.log('going for -:cf:- call path: ' + filePathForCloud);
    //                 this.httpClient.postToCloudFunction(filePathForCloud, bucketName, this.dataset, this.datastore, this.hcapiRegion).subscribe(val => {
    //                   console.log(val);
    //                 }, err => {
    //                   console.log(err);
    //                 });

    //                 // this.httpClient.postToCloudFunction(filePathForCloud);

    //               }
    //               // console.log(currentfileCount);
    //             }
    //           )
    //         ).subscribe();
    //       } catch (error) {
    //         console.log(error);

    //       }
    //     }
    //   }
    //   reader.readAsArrayBuffer(f);
    // }
  }

  addStudy(study: Study) {
    // this.studyCollection.add(study);
    study.instituteId = this.instituteIDInSession;
    console.log(study);
    this.studyCollection.doc(study.patient.patientID + '-' + study.studyID).set({...study});
    // this.studyCollection.doc(study.patient.patientID + '-' + study.studyID).set(Object.assign({}, study));
  }

  uploadStudy() {
    // const patient: Patient = new Patient(null, this.patientID, this.patientName, null, null, null, null, null);
    // const patient2: Patient = {
    //   patientID: this.patientID,
    //   patientName: this.patientName
    // }


    // No of Series: 1
// No of Images: 4
  this.patientName = 'RAJENDRA PRASAD ATHYA';
  this.patientID = '22598';
  this.studyId = '22598';
  this.studyInstanceUID='1.2.840.113619.2.81.290.1.22598.20200229.274623';
  this.modality='CT';
  this.studyDesc='BRAIN';

    const patient2: Patient = new Patient(0, this.patientID, this.patientName, null, null, null, null);

    let studyToBeSend: Study = new Study(this.studyId, this.studyDesc,this.studyDesc, this.studyInstanceUID, this.studyId, this.modality, new Date(),
      null, null, null, null, new Date(), null, this.files.length, 'true', false, this.instituteIDInSession, 0, {...patient2}, null,
      this.patientHistoryFormControl.value, new Date(), this.projectId, this.hcapiRegion, this.dataset, this.datastore,
      false, 'agamrjain@gmail.com', true, false, null, 1, null, false, false);


    // const study: Study = {
    //   studyID: this.studyId,
    //   studyInstanceUID: this.studyInstanceUID,
    //   modality: this.modality,
    //   studySendDate: new Date(),
    //   instituteID: this.instituteIDInSession,
    //   patientHistory : this.patientHistoryFormControl.value,

    //   dataSet: this.dataset,
    //   dataStore: this.datastore,
    //   projectId: this.projectId,
      // publishedToDoctor: true,
      // reportArrived: false,
      // reportingRadiologist: this.reportingRadiologist,
    // }

    // let studyToBeSend: Study = new Study(null, this.studyId, null, this.studyInstanceUID, null,
    //   this.mortality, new Date(), null, null, null, null, null, new Date(), null, this.files.length, true, 0, this.instituteIDInSession, null,
    //   patient, null, null, new Date(), null);

    // studyToBeSend.additionalDocument = this.downloadURLList;
    studyToBeSend.patientHistory = this.patientHistoryFormControl.value;
    studyToBeSend.studySendTime = new Date();
    // console.log(this.studyToBeSend);
    this.addStudy(studyToBeSend); 

  }
}
