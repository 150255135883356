<div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px" class="report-dialog-container">

    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px" fxFill>
        <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="8em" class="patient-info">
            <div fxFlex="30" fxLayout fxLayoutAlign="start start" fxLayoutGap="10px">
                <span>Patient ID:</span>
                <span>{{study.patient.patientID}}</span>
            </div>
            <div fxFlex="25">

                <button mat-button (click)="radiologistService.getUrlForStudy(study)" matTooltip="Click to View"
                    [ngClass]="{'opened': study.studyUnderObservation}">
                    {{study.patient.name}}
                    <mat-icon color=primary>open_in_new</mat-icon>
                </button>


                <!-- {{study.patient.name}}</div> -->
            </div>
            <div fxFlex>[ {{study.modality}} ] {{study.description}}</div>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="2em">
        <div fxFlex="15" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px">
            <div fxFlex="15" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="5px"
                class="patient-history-panel">
                <div class="puru-label">History</div>
                <div>{{study.patientHistory}}</div>
            </div>
            <h4 fxFlex="5" *ngIf="!study.isConsultant">Template Suggestions</h4>
            <!-- activeTab: {{activeTabIndex}} -->
            <!-- {{options.length}} -->
            <mat-form-field *ngIf="!study.isConsultant" fxFlex="10" tabindex="-1" class="mat-feild-templateName">
                <!-- <mat-label>All Template Names</mat-label> -->
                <input type="text" placeholder="Pick one" matInput [formControl]="templateNameFC" #tName
                    [matAutocomplete]="auto">
                <button mat-button matSuffix *ngIf="templateNameFC.value" matSuffix mat-icon-button aria-label="Clear"
                    (click)="resetTemplateNameFC()">
                    <mat-icon>close</mat-icon>
                </button>
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option" matTooltip="{{option}}"
                        class="mat-option-template-autocomplete"
                        (focusout)="loadTemplate($event.target.textContent, true)">
                        {{option}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <mat-chip-list fxFlex="50" fxLayout="column" fxLayoutAlign="center center" *ngIf="!study.isConsultant">
                <mat-chip color="primary" selected class="suggestion-chip" (click)="loadTemplate(rS)"
                    *ngFor="let rS of suitableTemplateName">{{rS}}</mat-chip>
            </mat-chip-list>


            <ng-container *ngIf="!study.isConsultant && showNewReportNameInput; else elseTemplate">

                <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="1px" fxFill>
                    <mat-form-field fxFlex="90" tabindex="-1" class="mat-feild-templateName">
                        <input type="text" placeholder="Write New Report Name" matInput [formControl]="newReportNameFC">
                    </mat-form-field>
                    <button mat-icon-button (click)="addNewReport()">
                        <mat-icon>add</mat-icon>
                    </button>

                </div>
            </ng-container>
            <ng-template #elseTemplate>

                <button mat-stroked-button fxFlex color="primary" (click)="showNewReportNameInput = true" *ngIf="!study.isConsultant">
                    <span class="material-icons">
                        add
                    </span>
                    Add New Report
                </button>

            </ng-template>








            <button mat-stroked-button fxFlex color="warn" (click)="closePanel()">
                <span class="material-icons">
                    close
                </span>
                Cancel
            </button>
        </div>
        <div fxFlex>

            <ng-container *ngIf="reportCount == 1; else elseTemplate1">

                <app-report-writing-pad [study]="study" [tabIndex]="0" [activeTabIndex]="0"
                    (reportSubmitted)="reportSubmitted($event)" [selectedReportSample]="selectedReportSample"
                    [reportName]="reportNameList[0]"></app-report-writing-pad>
            </ng-container>
            <ng-template #elseTemplate1>

                <mat-tab-group (selectedTabChange)="tabChanged($event)">
                    <mat-tab label="{{reportName}}" *ngFor="let reportName of reportNameList; let i = index" fxFlex>
                        <app-report-writing-pad [reportName]="reportName" [study]="study" [tabIndex]="i"
                            [activeTabIndex]="activeTabIndex" (reportSubmitted)="reportSubmitted($event)"
                            [selectedReportSample]="selectedReportSample"></app-report-writing-pad>
                    </mat-tab>
                </mat-tab-group>

            </ng-template>
        </div>


    </div>
</div>
