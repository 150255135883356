import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { IndiRadioServiceService as IndiRadioService } from '../individual-radiologist-fn/indi-radio-service.service';
import { ReportSample } from '../service/http/http-client.service';

@Component({
  selector: 'app-add-report-sample',
  templateUrl: './add-report-sample.component.html',
  styleUrls: ['./add-report-sample.component.css']
})
export class AddReportSampleComponent implements OnInit {
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '25rem',
    minHeight: '20rem',
    maxHeight: '60rem',
    width: '60em',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: 'div',
    defaultFontName: 'Calibri',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    outline: true,
    toolbarHiddenButtons: [

    ]
  };


  selectedTemplate: string;
  modalityFC = new FormControl('', Validators.required);
  reportTemplateName = new FormControl('', Validators.required);
  templateContent = new FormControl();
  dataSource: MatTableDataSource<string>;
  displayedColumns = ['name'];
  isEditMode = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(private radiologistService: IndiRadioService) {
    this.configureDataSource();
  }

  ngOnInit(): void {
  }

  private configureDataSource() {

    // console.log(this.radiologistService.templateNames);
    
    this.dataSource = new MatTableDataSource(this.radiologistService.templateNames);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  
  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  
  forceUpdate(){
    // this.radiologistService.checkIfNeedToUpdateIndexDB(true);
    this.radiologistService.updateSampleReport();
  }

  selectTemplate(templateName?: string) {
    
    this.templateContent.reset();
    this.reportTemplateName.reset();
    if (templateName) {
      this.selectedTemplate = templateName;
      this.isEditMode = true;
      this.reportTemplateName.setValue(templateName);
      this.modalityFC.setValue(this.radiologistService.templateMap[templateName].modality);
      this.templateContent.setValue(this.radiologistService.templateMap[templateName].text);
    } else {
      this.isEditMode = false;
    }
    // else this.selectedRoom = new Room(0, null, null, null, null, null, null, null);
    
    // this.hasService.sendRoomForDetails(this.selectedRoom); 
  }
  
  
    submit(isDelete?: boolean) {

      let name: string = this.reportTemplateName.value as string;
      name = name.trim();
      const content = this.templateContent.value;
      let id: string = name.toLowerCase().replace(' ', '_');
      const modality = this.modalityFC.value as string;
      const rs: ReportSample = new ReportSample(id, modality, name, content, this.radiologistService.userEmail, '');
      
      if (isDelete) {
        this.radiologistService.deleteReportSample(rs);
        this.reportTemplateName.reset();
        this.templateContent.reset();
        return;
      }
      if (this.isEditMode){
        this.radiologistService.saveReportSampleIntoFirebase(rs, this.isEditMode);
        // console.log('Content Edited');
        return;
      }
  
      const isNameExist = this.radiologistService.templateNames.includes(name);
      if ((this.reportTemplateName.valid && !isNameExist)) {
        this.radiologistService.saveReportSampleIntoFirebase(rs, this.isEditMode);
        // console.log('Content Saved');
        this.reportTemplateName.reset();
        this.templateContent.reset();
      } else {
        console.log('Name already exists');
      }
    }


  // delete() {

  // }
}
