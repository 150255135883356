import { DomSanitizer } from '@angular/platform-browser';
// import { StudyInHomeController } from './../home.component';
import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnInit, Input, SecurityContext, Inject, AfterViewInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ViewReportDialogComponent } from 'src/app/src/app/home/completed-studies/view-report-dialog/view-report-dialog.component';
import { Report, Study, HttpClientService } from 'src/app/service/http/http-client.service';
import { environment } from 'src/environments/environment';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth/auth.service';
import { ViewDocumentComponent } from 'src/app/view-document/view-document.component';
import { FormControl } from '@angular/forms';

export interface DialogData {
  report: '';
}
@Component({
  selector: 'app-completed-studies',
  templateUrl: './completed-studies.component.html',
  styleUrls: ['./completed-studies.component.css']
})
export class CompletedStudiesComponent implements OnInit, AfterViewInit {
  
  @Output() sendStudyForReportingEvent = new EventEmitter<Study>();
  @Input('instituteIdList') instituteIdList: string[];
  _collectionName: string;

  aToken: string;
  // _completedStudies: StudyInHomeController[];
  list: Study[];
  searchBarFormControl = new FormControl();
  startDateFormControl = new FormControl();
  endDateFormControl = new FormControl();
  todayDate = new Date();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = ['Count', 'name', 'modality', 'referringPhysicianName','studyDateTime', 'PatientHistory', 'description', 'AttachedDocument', 'ViewReport'];
  dataSource: MatTableDataSource<any>;
  offlineRequested: string;

  constructor(private storage: AngularFireStorage, private domSanitizer: DomSanitizer,
    public dialog: MatDialog, private firestore: AngularFirestore,
    private authService: AuthService, private snackBar: MatSnackBar, private httpClient: HttpClientService) {
    // console.log(this._pendingStudies);

  }

  ngOnInit(): void {

  }

  @Input()
  set collectionName(collectionName: string) {
    this._collectionName = collectionName;
    // console.log('[CS]-collectionName in ngAfterViewInit: ' + this._collectionName);
    // this.studyCollection = this.afirestore.collection<Study>(this._collectionName);
    // this.updateStudyList();

    const endDate: Date = new Date();
    const startDate: Date = new Date();
    startDate.setDate(endDate.getDate() - 1);
    
    let startDateMS = startDate.getTime();
    let endDateMS = endDate.getTime();

    this.display(startDateMS, endDateMS);
  }

  ngAfterViewInit(): void {
    this.aToken = localStorage.getItem('at');
    // const instituteIdList = ['BTCT'];
  }

  sendToPubsub(study: Study) {
    this.offlineRequested = study.studyInstanceUID;
    // dev-btct-dicom-to-be-uploaded
    if (study.offlineAvailable){
      this.downloadImagesZipFile(study);
    } else {
      this.httpClient.postToPubSub(study.studyInstanceUID, this.aToken).subscribe(val => {
        this.openSnackBar('Study requested for offline access', null, 2000);
      }, err => {
        console.log(err);
      })
    }
  }

  downloadImagesZipFile(s: Study) {
    // gs://puru-255206.appspot.com/viewer/weasis-windows-6.0.4.zip
    const filePath = 'BTCT/review_study/' + 'BTCT ' + s.patient.name.replace('/', '_').trim() + '.zip';
    console.log('filePath to be downloaded: ' + filePath);
    const fileRef = this.storage.ref(filePath);
    fileRef.getDownloadURL().subscribe(val => {
      this.offlineRequested = null;
      window.open(val);
    }, err => {
      console.log(err);
    });
  }

  display(startDateMS?: number, endDateMS?: number){
    
    if (!(startDateMS && endDateMS)) {
      const startDate: Date = (this.startDateFormControl.value).toDate();
      const endDate: Date = (this.endDateFormControl.value).toDate();
      endDate.setDate(endDate.getDate() + 1);
      
      startDateMS = startDate.getTime();
      endDateMS = endDate.getTime();
    }



    // console.log(startDateMS);
    // console.log(endDateMS);
    
    // console.log('[Date-Filter]: Going to-updateStudyList(): '+ this._collectionName);
    this.firestore.collection<Study>(this._collectionName,
      ref => ref.where('reportArrived', '==', true).where('studyDateTime', '>', startDateMS).where('studyDateTime', '<=', endDateMS).orderBy('studyDateTime', 'desc')).valueChanges().subscribe(val => {
      // ref => ref.where('reportArrived', '==', true).where('studySendTime', '>', '2021-05-01').where('studySendTime', '<=', '2021-06-20').orderBy('studySendTime', 'desc')).valueChanges().subscribe(val => {
        if (this.offlineRequested) {
          for (let index = 0; index < val.length; index++) {
            const element = val[index];
            if (element.offlineAvailable && this.offlineRequested === element.studyInstanceUID) {
              this.downloadImagesZipFile(element);
              break;
            } else if (!element.offlineAvailable && this.offlineRequested === element.studyInstanceUID) {
              this.openSnackBar('Can not download, some error occurred', null, 2000);
              this.offlineRequested = null;
            }
          }
        }
        this.list = val;
        this.dataSource = new MatTableDataSource(this.list);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.applyFilterPredicate();
      });


  }

  // updateStudyList() {
  //   console.log('Going to-updateStudyList(): '+ this._collectionName);
  //   this.firestore.collection<Study>(this._collectionName,
  //     ref => ref.where('reportArrived', '==', true).orderBy('studyDateTime', 'desc')).valueChanges().subscribe(val => {
  //       if (this.offlineRequested) {
  //         for (let index = 0; index < val.length; index++) {
  //           const element = val[index];
  //           if (element.offlineAvailable && this.offlineRequested === element.studyInstanceUID) {
  //             this.downloadImagesZipFile(element);
  //             break;
  //           } else if (!element.offlineAvailable && this.offlineRequested === element.studyInstanceUID) {
  //             this.openSnackBar('Can not download, some error occurred', null, 2000);
  //             this.offlineRequested = null;
  //           }
  //         }
  //       }
  //       this.list = val;
  //       this.dataSource = new MatTableDataSource(this.list);
  //       this.dataSource.paginator = this.paginator;
  //       this.dataSource.sort = this.sort;
  //       this.applyFilterPredicate();
  //     });
  // }

  // @Input()
  // set completedStudies(completedStudies: StudyInHomeController[]) {
  //   // console.log('setter2 called' + JSON.stringify(completedStudies));
  //   this._completedStudies = completedStudies;
  // }

  // get completedStudies(): StudyInHomeController[] {
  //   return this._completedStudies;
  // }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    // if (this.dataSource.paginator) {
    //   this.dataSource.paginator.firstPage();
    // }
  }

  openDialog(text) {
    this.dialog.open(DialogDataExampleDialog, {
      data: {
        report: text
      }
    });
  }

  openReportDialog(report: Report[]) {
    this.dialog.open(ViewReportDialogComponent, {
      data: {
        report
      }
    });
  }

  openViewDocDialog(link: string) {
    this.dialog.open(ViewDocumentComponent, {
      // width: '70%',
      // maxWidth: '80%',
      data: {
        link
      }
    });
  }

  getUrlForStudy(s: Study) {
    const datasetName = s.dataSet ? s.dataSet : 'dev2-dataset-dicom-btct';
    const datastoreName = s.dataStore ? s.dataStore : 'dev2-datastore-dicom-btct';
    const location = s.region ? s.region : 'asia-southeast1';
    const baseUrlHCAPI = environment.baseUrlHCAPI;
    const projectID = s.projectId ? s.projectId : 'puru-255206';
    const baseUrl = baseUrlHCAPI + '/projects/' + projectID + '/locations/' + location + '/datasets/'
      + datasetName + '/dicomStores/' + datastoreName + '/dicomWeb" -r "studyUID=';
    const studyID = s.studyInstanceUID;
    const authToken = localStorage.getItem('at');
    const corePlainUrl: string = '$dicom:rs --url "' + baseUrl + studyID + '"' + ' -H "Authorization: Bearer ' + authToken + '"';
    const coreEncodedUrl0: string = encodeURIComponent(corePlainUrl);
    const coreEncodedUrl = this.domSanitizer.sanitize(SecurityContext.URL, coreEncodedUrl0);
    const unsafeUrl = 'weasis://' + coreEncodedUrl;
    window.open(unsafeUrl);

  }

  getDocName(filePath: string) {
    const array = filePath.split('/');
    return array[array?.length - 1];
  }

  filterStudyOnEndDate() {
    console.log('filterStudyOnEndDate() called');
    const startDate = (this.startDateFormControl.value).toDate();
    const endDate = (this.endDateFormControl.value).toDate();
    endDate.setDate(endDate.getDate() + 1);

    const filteredList = this.list.filter(e => e.studyDateTime >= startDate && e.studyDateTime <= endDate);
    this.dataSource = new MatTableDataSource(filteredList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.applyFilterPredicate();


    // this.dataSource.data.filter(e => e.studyDateTime >= startDate && e.studyDateTime <= endDate);
  }

  openSnackBar(message: string, action: string, duration?: number) {
    if (!duration) {
      duration = 120000;
    }
    this.snackBar.open(message, action, {
      duration,
    });
  }

  getIcon(filePath: string) {
    let fileName = 'image-icon.png';
    if (filePath.endsWith('pdf')) {
      fileName = 'pdf-icon.png';
    } else if (filePath.endsWith('jpeg') || filePath.endsWith('jpg') || filePath.endsWith('png')) {
      fileName = 'image-icon.png';
    } else if (filePath.endsWith('doc') || filePath.endsWith('docx')) {
      fileName = 'word-icon.png';
    }

    return '/assets/images/' + fileName;

  }

  downloadFile(filePath: string) {
    const fileRef = this.storage.ref(filePath);
    fileRef.getDownloadURL().subscribe(val => {
      console.log(val);
      window.open(val);
    }, err => {
      console.log(err);
    });
  }

  applyFilterPredicate() {
    this.dataSource.filterPredicate = (data, filter) => {
      const str = data.patient.name?.toLowerCase() + data.description?.toLowerCase() + data.patientHistory?.toLowerCase();
      return str.indexOf(filter.trim().toLowerCase()) != -1;
    }
  }

  // openReportPanel(study: Study) {
  //   this.sendStudyForReportingEvent.emit(study);
  // }

  openReportPanel(study: Study) {
    let oldApproach = false;
    try {
      oldApproach = !study.report[0].mode || study.report[0].mode === 'doc_file';
    } catch (error) {
      
    }

    if (!oldApproach) this.sendStudyForReportingEvent.emit(study);
    else this.openReportDialog(study.report);
  }


}

@Component({
  selector: 'dialog-data-example-dialog',
  templateUrl: 'dialog-data-example-dialog.html',
})
export class DialogDataExampleDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) { }
}
