import { DomSanitizer } from '@angular/platform-browser';
// import { StudyInHomeController } from './../home.component';
import { Component, OnInit, Input, SecurityContext, ViewChild, AfterViewInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
// import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/auth.service';
// import { DataSource } from '@angular/cdk/table';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
// import { StudyService } from 'src/app/service/study-service.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Study, HttpClientService, TT } from 'src/app/service/http/http-client.service';
import { MatSnackBar } from '@angular/material/snack-bar';
// import { ViewDocumentComponent } from 'src/app/view-document/view-document.component';
import { MatDialog } from '@angular/material/dialog';
// import  auth  from 'firebase';
// import firestore from 'firebase/app';
import firebase from 'firebase/app';
import 'firebase/firestore';
// import { Subscription, interval } from 'rxjs';
// import { take, map } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-upload-report-in-bulk',
  templateUrl: './upload-report-in-bulk.component.html',
  styleUrls: ['./upload-report-in-bulk.component.css']
})
export class UploadReportInBulkComponent implements OnInit {

  _collectionName = 'prod-btct-studies';
  startDateFormControl = new FormControl();
  endDateFormControl = new FormControl();
  private studyCollection: AngularFirestoreCollection<Study>;
  todayDate = new Date();
  studyList: Study[];
  studyList_reportArrivedFalse: Study[];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  // displayedColumns = ['Count', 'Name', 'modality', 'instituteId', 'refdoc', 'studyDateTime', 'totalImages', 'PatientHistory', 'AttachedDocument', 'Upload', 'Options'];
  displayedColumns = ['Count', 'Name', 'Upload', 'modality', 'instituteId', 'studyDateTime', 'reportDownloadedAtRt', 'reportArrived'];
  dataSource: MatTableDataSource<any>;
  constructor(private storage: AngularFireStorage, private afirestore: AngularFirestore,
    private domSanitizer: DomSanitizer, private authService: AuthService, private snackBar: MatSnackBar, private httpClient: HttpClientService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.studyCollection = this.afirestore.collection<Study>(this._collectionName);
  }

  public updateStudyList() {
    // console.log('Going to-updateStudyList(): '+ this._collectionName);

    // this.afirestore.collection<Study>(this._collectionName, ref => ref.where('reportArrived', '==', false).orderBy('studyDateTime')).valueChanges().subscribe(val => {
    //     this.studyList = val;
    //   });

    this.studyList = [];
    const startDate: Date = (this.startDateFormControl.value).toDate();
    const endDate: Date = (this.endDateFormControl.value).toDate();
    endDate.setDate(endDate.getDate() + 1);

    let startDateMS = startDate.getTime();
    let endDateMS = endDate.getTime();
    this.afirestore.collection<Study>(this._collectionName, ref => ref.where('reportArrived', '==', false).where('studyDateTime', '>', startDateMS).where('studyDateTime', '<=', endDateMS).orderBy('studyDateTime', 'desc')).get().subscribe((val) => {
      val.docs.forEach((doc) => {
        this.studyList.push(doc.data() as Study);
      })

      this.dataSource = new MatTableDataSource(this.studyList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }


  public updateStudyListForReportArrivedFalseDownloadedTrue_CorrectThem() {
    // console.log('Going to-updateStudyList(): '+ this._collectionName);

    // this.afirestore.collection<Study>(this._collectionName, ref => ref.where('reportArrived', '==', false).orderBy('studyDateTime')).valueChanges().subscribe(val => {
    //     this.studyList = val;
    //   });

    this.studyList = [];
    const startDate: Date = (this.startDateFormControl.value).toDate();
    const endDate: Date = (this.endDateFormControl.value).toDate();
    endDate.setDate(endDate.getDate() + 1);

    let startDateMS = startDate.getTime();
    let endDateMS = endDate.getTime();
    this.afirestore.collection<Study>(this._collectionName, ref => ref.where('reportArrived', '==', false).where('reportDownloadedAtRt', '==', true).where('studyDateTime', '>', startDateMS).where('studyDateTime', '<=', endDateMS).orderBy('studyDateTime', 'desc')).get().subscribe((val) => {
      val.docs.forEach((doc) => {
        this.studyList_reportArrivedFalse.push(doc.data() as Study);
      })

      this.dataSource = new MatTableDataSource(this.studyList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }


  fixStudyList_reportArrivedFalse(study: Study) {
    if (!study.reportArrived && study.reportDownloadedAtRt) {

      this.afirestore.collection(this._collectionName).doc(study.patient.patientID + '-' + study.studyID).update({
        reportArrived: true
      });
      this.studyList_reportArrivedFalse.forEach((study: Study) => {
      })

      //   this.afirestore.collection(this._collectionName).doc(study.patient.patientID + '-' + study.studyID).update({
      //       reportArrived: true
      //     }
      //   });
      // })
    } else {
      console.log('condition not met to alter flag');

    }
  }


  uploadReport(study: Study) {

    try {


      console.log('send file to storage and save path in db');
      const file = this.files[study.studyID];
      const insID = study.instituteId;
      const fileName = study.patient.name + '_' + study.studyID + '_' + file.name;
      const filePath = '/' + insID + '/' + study.modality + '/' + study.patient.patientID + '/' + study.studyID + '/report/' + fileName;
      // const filePath = '/' + 'BTCT' + '/' + study.modality + '/'+ study.patient.patientID + '/' + study.studyID + '/report/' + file.name;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, file);
      // this.downloadURLList.push(filePath);
      // console.log(filePath);
      console.log(study.patient.patientID + '-' + study.studyID);
      this.afirestore.collection(this._collectionName).doc(study.patient.patientID + '-' + study.studyID).update({
        // this.studyCollection.doc(study.patient.patientID + '-' + study.studyID).update({
        reportArrived: true,
        reportDownloadedAtRt: false,
        report: firebase.firestore.FieldValue.arrayUnion({
          link: filePath,
          timestamp: new Date(),
          done_by: this.authService.user.email
        })
      });
      // done_by: this.authService.user.email
      console.log('Report submitted for: ' + study.patient.name);
      delete this.files[study.studyID];
    } catch (error) {
      console.error(error);
    }

  }


  files: any[] = [];

  /**
   * on file drop handler
   */
  onFileDropped($event, studyID) {
    this.prepareFilesList($event, studyID);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files, studyID: string) {
    this.prepareFilesList(files, studyID);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(studyID: string) {
    //  this.files.splice(index, 1);
    delete this.files[studyID];
  }

  /**
     * Convert Files list to normal array list
     * @param files (Files List)
     */

  prepareFilesList(files: Array<any>, studyID: string) {
    for (const item of files) {
      item.progress = 0;
      //  this.files.push(item);
      this.files[studyID] = item;
    }
    //  this.uploadFilesSimulator(0);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  ////////////////////////////////////////////////////////////////




  multipleReportfiles: any[] = [];
  // ============ MULTIPLE FILES UPLOADS. ========= //
  //1
  multiplereportFileBrowseHandler(files) {
    this.prepareMultipleReportFilesList(files);
  }

  //2
  prepareMultipleReportFilesList(files: Array<any>) {
    this.multipleReportfiles = files;
    // for (const item of files) {
    //   item.progress = 0;
    //   console.log(item);
    // }
  }

  processMultipleFiles() {
    let count = 0;
    let nameStudIDMap: string[] = [];
    this.studyList.forEach(val => {
      let refinedPatientName = '';
      let patientNameOriginal = val.patient.name;
      patientNameOriginal = patientNameOriginal.replace(/  /g, "").toLowerCase();
      patientNameOriginal = patientNameOriginal.replace(/\//g, "").toLowerCase();
      let splitPatientName = patientNameOriginal.split(' ');
      let salCheckName = splitPatientName[0].toLowerCase();
      if (salCheckName === 'mrs' || salCheckName === 'mr') splitPatientName[0] = '';
      if (splitPatientName.length > 3) {
        refinedPatientName = splitPatientName[0] + splitPatientName[1] + splitPatientName[2] + splitPatientName[3];
      } if (splitPatientName.length === 3) {
        refinedPatientName = splitPatientName[0] + splitPatientName[1] + splitPatientName[2];
      } else if (splitPatientName.length === 2) {
        refinedPatientName = splitPatientName[0] + splitPatientName[1];
      } else if (splitPatientName.length === 1) {
        refinedPatientName = splitPatientName[0];
      }


      refinedPatientName = refinedPatientName.toLowerCase();
      nameStudIDMap[refinedPatientName] = val.studyID;
    })

    console.log(nameStudIDMap);

    // Array.from(this.multipleReportfiles)
    Array.from(this.multipleReportfiles).forEach((val) => {
      let isMatchFound = false;
      const file = val as File;
      let refinedFileName: string = file.name.replace(/-/g, '').toLowerCase();
      // console.log(refinedFileName);
      for (const refinedPatientName in nameStudIDMap) {
        if (Object.prototype.hasOwnProperty.call(nameStudIDMap, refinedPatientName)) {
          const studyId = nameStudIDMap[refinedPatientName];
          if (refinedPatientName === '') {
            console.log('Patient Name is blank for study:' + studyId);
          } else {
            if (refinedFileName.includes(refinedPatientName)) {
              console.log('found-match-[' + refinedFileName + ']-[' + refinedPatientName + ']');
              this.files[studyId] = val;
              isMatchFound = true;
              count++;
            }
          }
        }
      }

      if (!isMatchFound) console.log('Not Found Match for [' + refinedFileName + ']-[' + val.name + ']');
    });
    console.log('Report Found for: ' + count);

  }

}
