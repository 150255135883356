<div fxFlex fxLayout="column" fxLayoutAlign="start stretch" class="sample-report-container" fxLayoutGap="5px">
    <div *ngIf="!study.isConsultant">
        <ng-container *ngIf="indexedDBReport; else noLocalReport">
            In memory report saved at {{indexedDBReport.savedAt | date : 'medium'}} <a color="accent" class="load-link" (click)="loadIndexedDBReport()">click to load</a>
        </ng-container>
        <ng-template #noLocalReport>
            No in memory report found
        </ng-template>
    </div>
    <!-- {{tabIndex}} -->
    <!-- {{reportName}} -->
    <!-- <div fxLayout fxLayoutGap="5px" appDnd (fileDropped)="onFileDropped($event, study.studyID)"
        *ngIf="!files[study.studyID]" class="upload-btn-wrapper">
         <button mat-raised-button color="primary">
        <mat-icon>cloud_upload</mat-icon> Drop / Select
    </button>
        <input type="file" hidden #fileDropRef id="fileDropRef" name="myfile" class="file-input-class"
            (change)="fileBrowseHandler($event.target.files, study.studyID)" />

        <angular-editor [formControl]="templateContentFC" [config]="editorConfig"></angular-editor>
    </div> -->
<!-- {{isEditMode}} - {{submittedReportList.length}} - {{isReportLoaded}} -->
    <ng-container *ngIf="isEditMode && submittedReportList.length > 1 && !isReportLoaded; else elseTemplate">

        <div class="mat-elevation-z8">
            <table mat-table [dataSource]="dataSource" style="width: 100%;">

                <!-- displayedColumns: string[] = ['', 'name', 'timestamp', 'load']; -->
                <!-- Position Column -->
                <ng-container matColumnDef="index">
                    <th mat-header-cell *matHeaderCellDef> # </th>
                    <td mat-cell *matCellDef="let element;let j = index">{{j+1}}</td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>

                <!-- Weight Column -->
                <ng-container matColumnDef="timestamp">
                    <th mat-header-cell *matHeaderCellDef> Timestamp </th>
                    <td mat-cell *matCellDef="let element"> {{element.timestampMS | date: 'medium'}} </td>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="load">
                    <th mat-header-cell *matHeaderCellDef> load </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button color="primary" (click)="loadReport(element)"><mat-icon>forward</mat-icon></button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </div>

    </ng-container>
    <ng-template #elseTemplate>
        <div>
            <angular-editor [formControl]="templateContentFC" [config]="editorConfig"></angular-editor>
            <mat-progress-bar *ngIf="isUploading" mode="determinate" value="uploadPercent | async"></mat-progress-bar>
        </div>
    </ng-template>

    <button mat-stroked-button color="accent" (click)="reloadReport()" *ngIf="study.isConsultant">
        <span class="material-icons">refresh</span>Reload</button>
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" *ngIf="!study.isConsultant">
        <div>

            <div fxLayout fxLayoutGap="5px" appDnd (fileDropped)="onFileDropped($event, study.studyID)"
                *ngIf="!files[study.studyID]" class="upload-btn-wrapper">
                <button mat-raised-button color="primary">
                    <mat-icon>cloud_upload</mat-icon> Drop / Select
                </button>
                <input type="file" #fileDropRef id="fileDropRef" name="myfile" class="file-input-class"
                    (change)="fileBrowseHandler($event.target.files, study.studyID)" />
            </div>

            <div fxLayout fxLayoutGap="5px" *ngIf="files[study.studyID]">
                <img src="assets/images/dnd/ic-file.svg" alt="file" class="drag-drop-image">
                <span>{{files[study.studyID]?.name}}</span>
                <button mat-icon-button aria-label="Upload Button" color="primary"
                    (click)="uploadWordReport(study, SubmitStatusValues.FINAL)">
                    <mat-icon>cloud_upload</mat-icon>
                </button>
                <button mat-icon-button aria-label="Delete Button" (click)="deleteFile(study.studyID)" color="warn">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </div>
        <!-- <button mat-stroked-button color="warn" (click)="closePanel()">Cancel</button> -->
        <div  fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" *ngIf="!files[study.studyID] && !(isEditMode && submittedReportList.length > 1 && !isReportLoaded)">

            <button mat-button [matMenuTriggerFor]="menu" id="report-send-option">Options</button>

            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="uploadReportLater(2)" name="report-send-via-other-medium" id="report-send-via-other-medium">Report Send Via Other Medium</button>
                <button mat-menu-item (click)="uploadReportLater(1)" id="report-later">Report Later</button>
            </mat-menu>
            <button mat-stroked-button color="accent" *ngIf="isEditMode && submittedReportList.length > 1" (click)="prepareTable()">
                <span class="material-icons">table_view</span>Show Table</button>
            <button mat-stroked-button color="accent" (click)="reloadReport()" *ngIf="isEditMode">
                <span class="material-icons">refresh</span>Reload</button>
            <button mat-stroked-button color="accent" (click)="uploadTextReport(SubmitStatusValues.DRAFT)">
                <span class="material-icons">save</span>Draft</button>
            <button mat-stroked-button color="accent" (click)="uploadTextReport(SubmitStatusValues.READ_ONLY)">
                <mat-icon>done</mat-icon>Read</button>
            <button mat-raised-button color="primary" (click)="uploadTextReport(SubmitStatusValues.FINAL)">
                <mat-icon>done_all</mat-icon>Submit</button>
            <!-- <button mat-raised-button color="primary" (click)="test()">
                                <mat-icon>done_all</mat-icon>
                                test</button> -->
        </div>

    </div>
    <!-- <pre><code>{{ uploadPercent | async |json }}</code></pre> -->
    <!-- <pre><code>{{ meta | async |json }}</code></pre> -->
</div>
