import { Observable } from 'rxjs';
import { HttpClientService, Institute } from './../service/http/http-client.service';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import 'firebase/firestore';

@Component({
  selector: 'app-create-actors',
  templateUrl: './create-actors.component.html',
  styleUrls: ['./create-actors.component.css']
})
export class CreateActorsComponent implements OnInit {

  public instituteList: Institute[];
  // public radiologistList: Radiologist[];
  // public insRadio: InstituteRadiologist[];

  // public imagingTechnicianCollection: AngularFirestoreCollection<ImagingTechnician>;
  // public instituteRadiologistCollection: AngularFirestoreCollection<InstituteRadiologist>;
  // public radiologistCollection: AngularFirestoreCollection<Radiologist>;
  public instituteCollection: AngularFirestoreCollection<Institute>;

  constructor(firestore: AngularFirestore, private storage: AngularFireStorage, private httpClient: HttpClientService) {
    this.instituteCollection = firestore.collection<Institute>('institute');
    // this.imagingTechnicianCollection = firestore.collection<ImagingTechnician>('imaging-technician');
    // this.instituteRadiologistCollection = firestore.collection<InstituteRadiologist>('institute-radiologist');
    // this.radiologistCollection = firestore.collection<Radiologist>('radiologist');

    this.instituteCollection.valueChanges().subscribe( val => {
      this.instituteList = val;
    });


  }

  ngOnInit(): void {
  }

  createInstituteAndItsTechnician() {
    const i = new Institute('BTCT_1', 'Bhagyoday Teerth 1', 'Sagar (M.P.-India)');
    // this.instituteCollection.add({ ...i });


    this.instituteCollection.doc(i.id).set({...i});
  }

  // attachDocToInstitute() {

  //   // const ir = new InstituteRadiologist()
  // }

  // createRadiologist() {
  //   const r = new Radiologist(null, 'Agam Jain', 'agamrjain@gmail.com');
  //   this.radiologistCollection.add({ ...r });
  // }

  // createITechnician() {
  //   const it = new ImagingTechnician(null, 'Ashish Sharma', 'particleilabs@gmail.com', '' );
  // }

}
