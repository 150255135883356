<div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="0px">

  <div class="header-row mat-elevation-z2" fxLayout fxLayoutAlign="center center" fxLayoutGap="10px">
    <div fxFlex="10">
    </div>
    <div fxFlex="30" [routerLink]="['home']">
      <img class="puru-logo-image" src="assets/icon/care-icon.png" [routerLink]="['home']">
      <img class="android-logo-image" src="assets/images/android-logo.png" [routerLink]="['home']">
    </div>

    <div fxFlex="20">

    </div>


    <div (click)="downloadFile()" fxFlex="8" class="nav-item">
      Download Viewer
    </div>

    <div [routerLink]="['addsamplereport']" fxFlex="8" class="nav-item">
      Sample Reports
    </div>
    <!-- <div [routerLink]="['bulkreport']" fxFlex="8" class="nav-item" *ngIf="isAgam">
      Bulk Reports
    </div> -->


    <div *ngIf="actualUser as user; else showLogin" fxLayout="row" fxFlex class="welcome-user">
      <div>Hi, {{ user.providerData[0].displayName }} !</div>
      <mat-icon class="example-icon" aria-hidden="false" (click)="authService.logout()">exit_to_app</mat-icon>
    </div>


    <ng-template #showLogin>
      <img src="./../../assets/icon/sign_in_with_google_light_themed.png" (click)="authService.login()"
        class="login-image">
    </ng-template>

  </div>

  <div fxLayout fxLayoutAlign="center center" fxLayoutGap="10px" class="w-100"
    *ngIf="false">

    <div>

      <mat-form-field>
        <input matInput>
      </mat-form-field>
      <button mat-button>Submit</button>

    </div>

    <div [routerLink]="['users']" fxFlex="8" class="nav-item">
      Users
    </div>
    <div [routerLink]="['hospital']" fxFlex="8" class="nav-item">
      Hospital
    </div>

    <div [routerLink]="['cluster']" fxFlex="8" class="nav-item">
      Cluster
    </div>





  </div>


</div>