import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Collection } from 'src/app/service/Collection';
import { FirebaseService } from 'src/app/service/firebase/firebase.service';
import { Hospital, PropertyCluster } from 'src/app/service/http/http-client.service';

@Component({
  selector: 'app-hospital-console',
  templateUrl: './hospital-console.component.html',
  styleUrls: ['./hospital-console.component.css']
})
export class HospitalConsoleComponent implements OnInit {

  addForm: FormGroup;
  _selectedHospital: Hospital;
  clusterList: PropertyCluster[] = [];
  modalityList: string[] = ['CT', 'MR', 'CR', 'DR'];
  constructor(public fs: FirebaseService, private fb: FormBuilder, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.initForm();
    this.populateClusterList();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  private populateClusterList() {
    this.fs.getAllDocuments(Collection.PROPERTY_CLUSTER).subscribe(val => {
      val.docs.forEach(doc => {
        const pc: PropertyCluster = doc.data() as PropertyCluster;
        pc.name = doc.id;
        this.clusterList.push(pc);
      });
    });
  }

  convertToCapitalCase() {
    const value = this.addForm.get('shortName').value as string;
    const tValue = value.toUpperCase();
    this.addForm.get('shortName').patchValue(tValue);
  }


  @Input('hospital')
  set selectedHospital(h: Hospital) {
    this._selectedHospital = h;
    this.patchHospital(h);
  }


  initForm() {
    this.addForm = this.fb.group({
      name: ['', Validators.required],
      shortName: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      propertyCluster: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      onlineAllowed: ['false'],
      autoUploadModality: ['', Validators.required],
      serverIp: ['', Validators.required],
      dataLocation: ['', Validators.required]
    });
  }

  patchHospital(h: Hospital) {
    if(!h) return;
    if (h.email) this.emailStatus = 1;
    this.addForm.patchValue({
      name: h.name,
      shortName: h.shortName,
      address: h.address,
      city: h.city,
      propertyCluster: h.propertyCluster,
      email: h.email,
      onlineAllowed: h.onlineAllowed,
      autoUploadModality: h.autoUploadModality,
      serverIp: h.serverIp,
      dataLocation: h.dataLocation
    });
  }


  onSubmit() {
    try {
      const finalHospital: Hospital = this.addForm.value as Hospital;
      this.fs.saveHospital(finalHospital);
      this.openSnackBar('Hospital Added Successfully', null);
    } catch (error) {
      this.openSnackBar(error, null);
    }

  }

  emailStatus = -1;
  validateEmail() {
    this.emailStatus = 2; // Querying
    const email: string = this.addForm.get('email').value as string;
    this.fs.getHospitalByEmail(email).subscribe(val => {
      if (val.empty) {
        this.emailStatus = 1;
        this.populateClusterList();
      }
      else this.emailStatus = 0;
    });
  }

  editCluster() {
    this.populateClusterList();
  }
}
