<div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px" class="top-container">

    <div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="0" fxFill class="h-100 w-100">
        <mat-card class="service-heading">
            <h5>User</h5>
        </mat-card>
        <mat-tab-group class="mat-tab-group-container" fxFlex="90" #tabs>
            <mat-tab label="Info" fxLayout fxLayoutAlign="center start" fxLayoutGap="10px" class="add-service-tab-body">


                <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="0px">
                    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="0px" class="add-form-div">
                        <form [formGroup]="addForm" class="form-in-mat-tab">

                            <div fxLayout fxLayoutGap="10px">
                                <mat-form-field fxFlex="90%">
                                    <mat-label>Email</mat-label>
                                    <input formControlName="email" matInput placeholder="sample@puru.co.in">
                                </mat-form-field>
                            </div>


                            <div fxLayout fxLayoutGap="10px">
                                <mat-form-field fxFlex="90%">
                                    <mat-label>Name</mat-label>

                                    <input formControlName="name" matInput placeholder="Name">
                                </mat-form-field>
                            </div>

                            <div fxLayout fxLayoutGap="10px">

                                <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
                                    Hospitals 
                                    <button mat-icon-button color="warn" (click)="showHospitalEditBox = !showHospitalEditBox"><mat-icon>edit</mat-icon></button>
                                </div>
                                <mat-list *ngIf="!showHospitalEditBox">
                                    <mat-list-item
                                        *ngFor="let hospital of _selectedUserInFirebase?.instituteID;let i=index">
                                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                            <div>
                                                {{i+1}}.
                                            </div>
                                            <div>
                                                {{ hospitalList[hospital] ? hospitalList[hospital].name : hospital }}
                                            </div>
                                        </div>

                                    </mat-list-item>
                                </mat-list>



                                {{hospitalList | json}}
                                <mat-form-field *ngIf="showHospitalEditBox">
                                    <mat-label>Hospital</mat-label>
                                    <mat-select formControlName="instituteID" class="puru-mat-select" multiple>
                                        <mat-option [value]="h.shortName" *ngFor="let h of hospitalList">{{h.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                        </form>
                        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
                            <button mat-stroked-button color="warn">Cancel</button>
                            <button mat-raised-button color="primary" (click)="onSubmit()"
                                [disabled]="!addForm.valid">Submit</button>
                        </div>
                    </div>


                </div>



            </mat-tab>



            <mat-tab label="Statistics">


            </mat-tab>


        </mat-tab-group>
    </div>


</div>