import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-upload2',
  templateUrl: './upload2.component.html',
  styleUrls: ['./upload2.component.css']
})
export class Upload2Component implements OnInit {

  receivedDicomFiles: any[];

  constructor() { }

  ngOnInit(): void {
  }

  getDicomFiles(files: any[]) {
    this.receivedDicomFiles = files;
    console.log('total files: ' + this.receivedDicomFiles.length);
  }
}
