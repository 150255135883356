import { AsyncPipe } from '@angular/common';
import { environment } from './../environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import {MatSidenavModule} from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FlexLayoutModule } from '@angular/flex-layout';
// import { MatMomentDateModule, MomentDateModule } from '@angular/material-moment-adapter';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
// import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatChipsModule} from '@angular/material/chips';

import { AppRoutingModule } from './app-routing.module';
import { AngularFireStorageModule, BUCKET } from '@angular/fire/storage';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { CookieModule } from 'ngx-cookie';
import { CookieService } from 'ngx-cookie-service';

import 'firebase/storage';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { PendingStudiesComponent } from './home/pending-studies/pending-studies.component';
import { CompletedStudiesComponent, DialogDataExampleDialog } from './home/completed-studies/completed-studies.component';
import { UploadComponent } from './upload/upload.component';
import { DndDirective } from './dnd.directive';
import { CreateActorsComponent } from './create-actors/create-actors.component';
import { HttpClientModule } from '@angular/common/http';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { Upload2Component } from './upload2/upload2.component';
// import { REGION } from '@angular/fire/functions/functions';
import { ViewReportDialogComponent } from './src/app/home/completed-studies/view-report-dialog/view-report-dialog.component';
import { ViewDocumentComponent } from './view-document/view-document.component';
import { ConsultingStudiesComponent } from './consulting-studies/consulting-studies.component';
import { AgencyHomeComponent } from './agency-fn/agency-home/agency-home.component';
import { UnassignedStudyComponent } from './agency-fn/unassigned-study/unassigned-study.component';
import { AssignedStudyComponent } from './agency-fn/assigned-study/assigned-study.component';
import { PendingStudiesForAgencyRadiologistComponent } from './agency-fn/agencyRadiologist/pending-studies-for-agency-radiologist/pending-studies-for-agency-radiologist.component';
import { AgencyRadiologistHomeComponent } from './agency-fn/agencyRadiologist/agency-radiologist/agency-radiologist.component';
import { ManageUserComponent } from './manage-user/manage-user.component';
import { AgencyCompleteStudiesComponent } from './agency-fn/agency-complete-studies/agency-complete-studies.component';
import { CompletedStudiesForAgencyRadiologistComponent } from './agency-fn/agencyRadiologist/completed-studies-for-agency-radiologist/completed-studies-for-agency-radiologist.component';
import { IndiRadioPendingStudiesComponent } from './individual-radiologist-fn/indi-radio-pending-studies/indi-radio-pending-studies.component';
import { OhifViewerComponent } from './home/ohif/ohif-viewer/ohif-viewer.component';
import { AddReportSampleComponent } from './add-report-sample/add-report-sample.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ReportDialogComponent } from './report/report-dialog/report-dialog.component';
import { ViewReportNewComponent } from './view-report-new/view-report-new.component';
import { ReportContainerComponent } from './report-container/report-container.component';
import { ReportWritingPadComponent } from './report-writing-pad/report-writing-pad.component';
import { UploadReportInBulkComponent } from './upload-report-in-bulk/upload-report-in-bulk.component';
import { IndiRadioCompletedStudiesComponent } from './individual-radiologist-fn/indi-radio-completed-studies/indi-radio-completed-studies.component';
import { PrivacyComponent } from './extra-pages/privacy/privacy.component';
import { TermsOfServicesComponent } from './extra-pages/terms-of-services/terms-of-services.component';
import { HospitalListComponent } from './admin-fn/hospital/hospital-list/hospital-list.component';
import { HospitalDashboardComponent } from './admin-fn/hospital/hospital-dashboard/hospital-dashboard.component';
import { HospitalConsoleComponent } from './admin-fn/hospital/hospital-console/hospital-console.component';
import { ClusterDashboardComponent } from './admin-fn/cluster/cluster-dashboard/cluster-dashboard.component';
import { UserListComponent } from './admin-fn/users/user-list/user-list.component';
import { UserConsoleComponent } from './admin-fn/users/user-console/user-console.component';
import { UserDashboardComponent } from './admin-fn/users/user-dashboard/user-dashboard.component';
// import { ClusterComponent } from './admin-fn/cluster/cluster.component';
// import { HospitalComponent } from './admin-fn/hospital/hospital.component';
// import { AngularFireFunctionsModule, ORIGIN  } from '@angular/fire/functions';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'MMM DD, YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    LoginComponent,
    PendingStudiesComponent,
    CompletedStudiesComponent,
    UploadComponent,
    DndDirective,
    CreateActorsComponent,
    FileUploadComponent,
    Upload2Component,
    ViewReportDialogComponent,
    ViewDocumentComponent,
    ConsultingStudiesComponent,
    AgencyHomeComponent,
    UnassignedStudyComponent,
    AssignedStudyComponent,
    PendingStudiesForAgencyRadiologistComponent,
    AgencyRadiologistHomeComponent,
    ManageUserComponent,
    AgencyCompleteStudiesComponent,
    CompletedStudiesForAgencyRadiologistComponent,
    IndiRadioPendingStudiesComponent,
    OhifViewerComponent,
    AddReportSampleComponent,
    ReportDialogComponent,
    ViewReportNewComponent,
    ReportContainerComponent,
    ReportWritingPadComponent,
    UploadReportInBulkComponent,
    IndiRadioCompletedStudiesComponent,
    PrivacyComponent,
    TermsOfServicesComponent,
    HospitalListComponent,
    HospitalDashboardComponent,
    HospitalConsoleComponent,
    ClusterDashboardComponent,
    UserListComponent,
    UserConsoleComponent,
    UserDashboardComponent,
    // ClusterComponent,
    // HospitalComponent,
  ],
  entryComponents: [DialogDataExampleDialog],
  imports: [
    BrowserModule,
    // AngularFireFunctionsModule,
    ReactiveFormsModule, FormsModule,
    AppRoutingModule, HttpClientModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    MatToolbarModule, MatProgressSpinnerModule,
    MatButtonModule, MatCheckboxModule, MatInputModule, MatAutocompleteModule, MatSelectModule, MatIconModule,
    MatCardModule, MatTableModule, MatDialogModule,
    MatRadioModule, MatButtonToggleModule, MatDatepickerModule,
    MatMenuModule, MatStepperModule,
    MatNativeDateModule, MatBadgeModule, MatTabsModule, MatTooltipModule, MatProgressBarModule,
    MatExpansionModule, MatListModule, MatSnackBarModule, MatDividerModule, MatChipsModule,
    MatSlideToggleModule, MatSidenavModule, FormsModule, MatPaginatorModule, MatSortModule,FlexLayoutModule, AngularEditorModule 

  ],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: BUCKET, useValue: environment.firebase.storageBucket }, AsyncPipe, 
    CookieService
    // { provide: REGION, useValue: 'us-central1' },
    // { provide: ORIGIN, useValue: 'http://localhost:4200' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
