import { CreateActorsComponent } from './create-actors/create-actors.component';
import { UploadComponent } from './upload/upload.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Upload2Component } from './upload2/upload2.component';
import { AgencyHomeComponent } from './agency-fn/agency-home/agency-home.component';
import { AgencyRadiologistHomeComponent } from './agency-fn/agencyRadiologist/agency-radiologist/agency-radiologist.component';
import { AddReportSampleComponent } from './add-report-sample/add-report-sample.component';
import { OhifViewerComponent } from './home/ohif/ohif-viewer/ohif-viewer.component';
import { UploadReportInBulkComponent } from './upload-report-in-bulk/upload-report-in-bulk.component';
import { PrivacyComponent } from './extra-pages/privacy/privacy.component';
import { TermsOfServicesComponent } from './extra-pages/terms-of-services/terms-of-services.component';
import { HospitalDashboardComponent } from './admin-fn/hospital/hospital-dashboard/hospital-dashboard.component';
import { UserDashboardComponent } from './admin-fn/users/user-dashboard/user-dashboard.component';

const routes: Routes = [



  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'actors', component: CreateActorsComponent },


  { path: 'bulkreport', component: UploadReportInBulkComponent },
  { path: 'home', component: HomeComponent },
  { path: 'upload', component: UploadComponent },
  { path: 'agency', component: AgencyHomeComponent },
  { path: 'aradiologist', component: AgencyRadiologistHomeComponent },
  { path: 'addsamplereport', component: AddReportSampleComponent },
  { path: 'viewer', component: OhifViewerComponent},
  { path: 'privacy', component: PrivacyComponent },
  { path: 'toc', component: TermsOfServicesComponent },
  // { path: 'cluster', component: ClusterComponent },
  { path: 'hospital', component: HospitalDashboardComponent},
  { path: 'users', component: UserDashboardComponent }

  // { path: 'upload2', component: Upload2Component },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
