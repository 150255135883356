<h2 mat-dialog-title>Report</h2>
<mat-dialog-content class="mat-typography">
                <!-- {{reportList| json}}
                {{selected.value}}
                {{reportList[0].link}} -->
  <mat-tab-group [selectedIndex]="selected.value" (selectedIndexChange)="selected.setValue($event)">
    <mat-tab *ngFor="let element of reportList" label="{{element.timestamp*1000 | date:'medium'}}" > 
      <!-- {{element | json }} -->

      <div *ngIf = "element.text">
        <!-- {{element.text}} -->
        <div class="angular-with-newlines" [innerHtml]="element.text">{{element.text}}</div>
      </div>

      <div *ngIf = "element.mode === 'txt_file'">
        
      </div>
      <div *ngIf = "element.link" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
        <button mat-raised-button color="primary" (click)="previewFile(element.link)" *ngIf="!isLoading">Preview</button>
        <button mat-raised-button color="accent" (click)="downloadFile(element.link)" *ngIf="!isLoading">Download</button>

        <iframe [src]='domSanitizer.bypassSecurityTrustResourceUrl(mainUrl)' *ngIf="isLoading"
                frameborder="0" style="width:718px; height:800px;"></iframe>
      </div>
    </mat-tab>
  </mat-tab-group>
 
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button (click)="tryAgain()">Try Again</button>
  <!-- <button mat-button (click)="tryAgainForLocal()">Try Again Local</button> -->
  <button mat-button cdkFocusInitial>Download</button>
</mat-dialog-actions>