<div class="parent-div mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                </mat-checkbox>
            </td>
        </ng-container>

        <ng-container matColumnDef="mobileCol">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
            <td mat-cell *matCellDef="let element;let j = index">


                <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
                    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
                        <span>
                            {{paginator.pageSize * paginator.pageIndex + j + 1}}
                        </span>
                        <span>
                            {{element.patient.name}}
                        </span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
                        <span>
                            {{element.modality}}
                        </span>
                        <span>
                            {{element.studyDateTime | date:'d MMM yy, h:mm a'}}
                        </span>
                    </div>

                    <div fxLayout fxLayoutAlign="start start" fxLayoutGap="10px">
                        <div class="select-op">
                            <select class="form-control" id="sel1" #select>
                                <option *ngFor="let d of radiologistInAgency" [value]="d.email">{{d.name}}</option>
                            </select>
                        </div>
                        <button mat-icon-button color="primary" (click)="assignStudy(element, select.value)">
                            <mat-icon>send</mat-icon>
                        </button>
                    </div>
                    
                </div>



            </td>
        </ng-container>

        <ng-container matColumnDef="Count">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
            <td mat-cell *matCellDef="let element;let j = index">{{paginator.pageSize * paginator.pageIndex + j + 1}}
            </td>
        </ng-container>

        <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let element">

                <!-- {{element.patient.name}} // {{element.patient.patientID}}/{{element.studyID}}/report/ -->
                <!-- <button mat-button (click)="getUrlForStudy(element)" matTooltip="Click to View" [ngClass]="{'opened': element.studyUnderObservation}"> -->
                <button mat-button matTooltip="Click to View" [ngClass]="{'opened': element.studyUnderObservation}">
                    {{element.patient.name}}
                </button>



            </td>
        </ng-container>

        <ng-container matColumnDef="modality">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Modality </th>
            <td mat-cell *matCellDef="let element"> {{element.modality}} </td>
        </ng-container>
        <ng-container matColumnDef="hospital">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
            
                <!-- <select class="header-input-filter-select">
                    <option selected>Hospital</option>
                    <option [value]="h" *ngFor="let h of agencyService.hospitalListManagedByAgency">{{h}}</option>
                  </select> -->

                  <mat-form-field appearance="none" class="header-matformfeild-select">
                    <mat-label>Hospital</mat-label>
                    <mat-select [formControl]="hospitalFilterFormControl" multiple>
                      <mat-option [value]="h" *ngFor="let h of agencyService.hospitalListManagedByAgency">{{h}}</mat-option>
                    </mat-select>
                  </mat-form-field>
            
            
            </th>
            <td mat-cell *matCellDef="let element"> {{element.instituteId}} </td>
        </ng-container>

        <ng-container matColumnDef="StudyDesc">
            <th mat-header-cell *matHeaderCellDef> Study Desc </th>
            <td mat-cell *matCellDef="let element"> {{element.description}} </td>
        </ng-container>

        <ng-container matColumnDef="studyDateTime">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Study Time </th>
            <td mat-cell *matCellDef="let element"> {{element.studyDateTime | date:'d MMM yy, h:mm a'}} </td>
        </ng-container>

        <ng-container matColumnDef="refdoc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Ref. Physician </th>
            <td mat-cell *matCellDef="let element"> {{element.referringPhysicianName === 'null' ? '':
                element.referringPhysicianName}} </td>
        </ng-container>

        <ng-container matColumnDef="totalImages">
            <th mat-header-cell *matHeaderCellDef> Online Images </th>
            <td mat-cell *matCellDef="let element"> {{element.onlineImageCount}} </td>
        </ng-container>

        <ng-container matColumnDef="PatientHistory">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> History </th>
            <td mat-cell *matCellDef="let element"> {{element.patientHistory}} </td>
        </ng-container>

        <ng-container matColumnDef="AttachedDocument">
            <th mat-header-cell *matHeaderCellDef> Attached Document </th>
            <td mat-cell *matCellDef="let element"> <span *ngFor="let doc of element.additionalDocument">
                    <!-- <span (click)="openViewDocDialog(doc)">
              <img class="image-icon" src="{{getIcon(doc)}}" alt="Avatar" class="image">
            </span> -->
                </span> </td>
        </ng-container>

        <ng-container matColumnDef="assign">
            <th mat-header-cell *matHeaderCellDef> Attached Document </th>
            <td mat-cell *matCellDef="let element">
                <div fxLayout fxLayoutAlign="start start" fxLayoutGap="10px">
                    <div class="select-op">
                        <select class="form-control" id="sel1" #select>
                            <option *ngFor="let d of radiologistInAgency" [value]="d.email">{{d.name}}</option>
                        </select>
                    </div>
                    <button mat-icon-button color="primary" (click)="assignStudy(element, select.value)">
                        <mat-icon>send</mat-icon>
                    </button>
                </div>
            </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            [ngClass]="{'opened': row.studyUnderObservation, 'stillfresh': !row.studyUnderObservation}"></tr>
    </table>

    <mat-paginator #paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
</div>
{{count}}
<!-- <div *ngIf="offlineRequested">
    <span>Preparing your download</span>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div> -->
<!-- <h5>Time Remaining {{remainingTimeMin - timeElaspsed}} min | TUAT {{tokedUpdatedAt?.seconds  * 1000 | date:'hh:mm aaa' }} | {{_collectionName}}</h5> -->
<!-- Sample comment -->
<!-- <button mat-raised-button (click)="acceptAll()">View Non Accepted Studies</button>
  <button mat-raised-button (click)="acceptStudy()" *ngIf="oldNonAcceptedList.length !== 0">Accept These Studies</button>
  {{lastDate | date}} -->