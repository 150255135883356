import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Study, UserInFirebase } from 'src/app/service/http/http-client.service';
import { AgencyServiceService } from '../service/agency-service.service';

@Component({
  selector: 'app-unassigned-study',
  templateUrl: './unassigned-study.component.html',
  styleUrls: ['./unassigned-study.component.css']
})
export class UnassignedStudyComponent implements OnInit {

  hospitalFilterFormControl = new FormControl();
  studyList: Study[] = [];
  radiologistInAgency: UserInFirebase[];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = ['select', 'Count', 'Name', 'modality', 'hospital', 'refdoc', 'studyDateTime', 'StudyDesc', 'totalImages', 'PatientHistory', 'assign'];
  dataSource: MatTableDataSource<Study>;
  constructor(public agencyService: AgencyServiceService) {

    this.agencyService.eventCallbackStudyUnAssigned$.subscribe((val: Study) => {
      // console.log('[component-1]: ' + val);
      // console.log(val);
      // this.studyList.push(val);
      this.studyList[val.studyInstanceUID] = val;
      this.updateDatasource();
    });
    // agencyService.hospitalListManagedByAgency
    this.agencyService.eventCallbackRadiologist$.subscribe((val: UserInFirebase[]) => {
      this.radiologistInAgency = val;
    });


    this.onChangeHospitalFilter();

  }

  onChangeHospitalFilter() {
    this.hospitalFilterFormControl.valueChanges.subscribe((val: string[]) => {
      console.log(val);
      let filteredList: Study[] = [];
      if (val.length == 0) {
        this.updateDatasource();
        return;
      }

      for (const key in this.studyList) {
        if (Object.prototype.hasOwnProperty.call(this.studyList, key)) {
          const element = this.studyList[key];
          const index = val.indexOf(element.instituteId);
          if (index != -1) {
            filteredList.push(element);
          }
        }
      }
      this.updateDatasource(filteredList);


    })
  }


  selection = new SelectionModel<Study>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource?.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource?.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Study): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.studyID + 1}`;
  }

  ngOnInit(): void {

    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      console.log('it is mobile');
      this.displayedColumns = ['mobileCol'];
    } else {
      console.log('not a moblie');

    }

  }

  @Input('insID')
  set filterInstitute(insId: string) {
    console.log('Filter for: [' + insId + ']');
  }

  count = 0;
  updateDatasource(studyList0?: Study[]) {
    // const x: IterableIterator<Study> = this.studyList.values();
    // console.log(localStudyList);

    this.count = Object.keys(this.studyList).length
    let localStudyList: Study[] = studyList0 ? studyList0 : [];
    if (!studyList0) {
      for (const key in this.studyList) {
        if (Object.prototype.hasOwnProperty.call(this.studyList, key)) {
          const element = this.studyList[key];
          localStudyList.push(element);
        }
      }
    }
    this.dataSource = new MatTableDataSource(localStudyList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getIcon(filePath: string) {
    let fileName = 'image-icon.png';
    if (filePath.endsWith('pdf')) {
      fileName = 'pdf-icon.png';
    } else if (filePath.endsWith('jpeg') || filePath.endsWith('jpg') || filePath.endsWith('png')) {
      fileName = 'image-icon.png';
    } else if (filePath.endsWith('doc') || filePath.endsWith('docx')) {
      fileName = 'word-icon.png';
    }

    return '/assets/images/' + fileName;

  }

  assignStudy(study: Study, email: string) {

    study.reportingRadiologist = email;
    study.assignedToRadiologist = true;
    // console.log(email);
    // console.log(study);
    this.agencyService.updateStudy(study);
    delete this.studyList[study.studyInstanceUID];
    this.updateDatasource();
  }
}
