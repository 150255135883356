import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Collection } from 'src/app/service/Collection';
import { FirebaseService } from 'src/app/service/firebase/firebase.service';
import { Hospital, UserInFirebase } from 'src/app/service/http/http-client.service';

@Component({
  selector: 'app-user-console',
  templateUrl: './user-console.component.html',
  styleUrls: ['./user-console.component.css']
})
export class UserConsoleComponent implements OnInit {

  addForm: FormGroup;
  _selectedUserInFirebase: UserInFirebase;
  hospitalList: Hospital[] = [];
  showHospitalEditBox = true;

  // clusterList: PropertyCluster[] = [];
  // modalityList: string[] = ['CT', 'MR', 'CR', 'DR'];
  constructor(public fs: FirebaseService, private fb: FormBuilder, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.initForm();
    this.populateHospitalList();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  private populateHospitalList() {
    this.fs.getAllDocuments(Collection.HOSPITAL).subscribe(val => {
      val.docs.forEach(doc => {
        const hospital: Hospital = doc.data() as Hospital;
        this.hospitalList[hospital.shortName] = hospital;
      });
      // console.log(this.hospitalList);
      
    });
  }

  @Input('UserInFirebase')
  set selectedUserInFirebase(h: UserInFirebase) {
    this._selectedUserInFirebase = h;
    this.patchUserInFirebase(h);
  }


  initForm() {
    this.addForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      instituteID: ['', Validators.required],
      sampleEditedOn: [0],
      

      // name: string;
      // email: string;
      // roleList: string[];
      // instituteID: string[];
      // agencyID: string[];
      // agencyHospitals: string[];
      // agencyAndHospitals: string[];
      // sampleEditedOn: number;



    });
  }

  patchUserInFirebase(h: UserInFirebase) {
    if (!h) return;
    if (h.email) {
      this.showHospitalEditBox = false;
    };
    this.addForm.patchValue({
      name: h.name,
      // shortName: h.shortName,
      // address: h.address,
      // city: h.city,
      // propertyCluster: h.propertyCluster,
      email: h.email,
      // onlineAllowed: h.onlineAllowed,
      // autoUploadModality: h.autoUploadModality,
      // serverIp: h.serverIp,
      // dataLocation: h.dataLocation
    });
  }


  onSubmit() {
    try {
      const finalUserInFirebase: UserInFirebase = this.addForm.value as UserInFirebase;
      this.fs.saveUserInFirebase(finalUserInFirebase);
      this.openSnackBar('UserInFirebase Added Successfully', null);
    } catch (error) {
      this.openSnackBar(error, null);
    }

  }

  // emailStatus = -1;
  // validateEmail() {
  //   this.emailStatus = 2; // Querying
  //   const email: string = this.addForm.get('email').value as string;
  //   this.fs.getUserInFirebaseByEmail(email).subscribe(val => {
  //     if (val.empty) {
  //       this.emailStatus = 1;
  //       this.populateClusterList();
  //     }
  //     else this.emailStatus = 0;
  //   });
  // }

  // editCluster() {
  //   this.populateClusterList();

  // }

}
