import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import 'firebase/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage } from '@angular/fire/storage';
import firebase from 'firebase/app';
import { UserInFirebase } from './service/http/http-client.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  events: string[] = [];
  opened = true;
  showFiller = false;

  actualUser: any;
  userInSession: UserInFirebase;
  instituteIDInSession: string;
  
  title = 'PuruOnline';
  db: any;
  constructor(public firestore: AngularFirestore, public auth: AngularFireAuth) {
  }
  ngOnInit(): void {
    this.auth.onIdTokenChanged((user) => {
      this.actualUser = user;
    });

    this.auth.user.subscribe(val => {
      if (!val?.displayName) {
        this.userInSession = null;
        return;
      }
      const userEmail = val?.email;

      this.firestore.collection<UserInFirebase>('users', ref => ref.where('email', '==', val.email)).valueChanges().subscribe(val2 => {
        this.userInSession = val2[0] as UserInFirebase;

        if (this.userInSession?.roleList['radiologist'] || this.userInSession.roleList?.length > 1) {
          // this.loadStudies(firestore, this.userInSession.instituteID);
        } else {
          this.instituteIDInSession = this.userInSession.instituteID[0];
        }
      });

    });


    
  }
  login() {
    this.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
  }

  logout() {
    this.auth.signOut();
  }
}
