<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px"  fxFlexAlign="center" class="parent-div" >
  <mat-card  fxFlexAlign="center" class="example-card">
    <mat-card-header>
    </mat-card-header>

    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
      <img mat-card-image src="assets/images/android-logo.png" class="logo-name-image">
      <img mat-card-image src="assets/icon/care-icon.png" class="card-image" (click)="authService.login()">
      <!-- <img mat-card-image src="assets/icon/care-icon.png" class="card-image" (click)="authService.login2()"> -->

    </div>


    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="2px" class="login-box">

      <div fxLayout fxLayoutAlign="start center" class="w-100">
        <img class="icon_as_label" src="../../../assets/icons/username.png">
        <div class="input-group mb-2">
          <input type="email" class="form-control" id="username" aria-describedby="emailHelp"
            placeholder="Enter Username" [(ngModel)]="username" tabindex=1>
        </div>
      </div>
      <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="0px" class="w-100">
        <div fxLayout fxLayoutAlign="start center" class="w-100">
          <img class="icon_as_label" src="../../../assets/icons/enter-password.png">
          <div fxFlex [ngClass]="{'error-border': invalidLogin}">
            <input type="password" class="form-control" id="password" placeholder="Password"
              [(ngModel)]="password" tabindex=2 (keyup.enter)="login2()" (keyup)="checkCaps($event)" fxFlex>
          </div>
        </div>
        <div class="hint-div">
          <small class="warn-small" id="emailHelp" *ngIf="capsOn">Caps Lock is ON</small>
          <small class="error-small" *ngIf="invalidLogin">{{errorMessage}}</small>
        </div>
      </div>

      <!-- {{loading}} -->
      <div fxLayout="column" fxLayoutAlign="end end" fxLayoutGap="0px" class="w-100">
        <a tabindex=3 class="android-link mdl-button mdl-js-button mdl-typography--text-uppercase" id="login-button" *ngIf="!loading"
          (click)="login2()" (keyup.enter)="login2()">
          Login
          <i class="material-icons">chevron_right</i>
        </a>
        <mat-spinner *ngIf="loading"></mat-spinner>

      </div>


    </div>


    <div>


    </div>

    <!-- <mat-card-content>
      <div>
        <img src="assets/icon/sign_in_with_google_light_themed.png" (click)="authService.login()" class="google-sign-in">
      </div>
    </mat-card-content> -->
  </mat-card>
</div>