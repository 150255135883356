import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AngularFireStorage } from '@angular/fire/storage';
import { Report, Study } from 'src/app/service/http/http-client.service';
import { IndiRadioServiceService } from '../indi-radio-service.service';
import { ViewDocumentComponent } from 'src/app/view-document/view-document.component';
import { ViewReportDialogComponent } from 'src/app/src/app/home/completed-studies/view-report-dialog/view-report-dialog.component';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-indi-radio-completed-studies',
  templateUrl: './indi-radio-completed-studies.component.html',
  styleUrls: ['./indi-radio-completed-studies.component.css']
})
export class IndiRadioCompletedStudiesComponent implements OnInit {

  startDateFormControl = new FormControl();
  endDateFormControl = new FormControl();
  todayDate = new Date();

  hospitalFilterFormControl = new FormControl();
  hospitalButtonToggleFCAll = new FormControl();
  hospitalButtonToggleFC = new FormControl();
  studyList: Study[] = [];
  instituteID_studyCount: number[] = [];
  templateNames: string[];
  templateMap: string[];

  displayedColumns = ['Count', 'Name', 'reportDialog', 'modality', 'instituteId', 'refdoc', 'studyDateTime', 'StudyDesc', 'totalImages', 'PatientHistory', 'AttachedDocument', 'ReportCount'];
  dataSource: MatTableDataSource<Study>;
  count: number;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Output() sendStudyForReportingEvent = new EventEmitter<Study>();
  constructor(public radiologistService: IndiRadioServiceService,
    public dialog: MatDialog, private storage: AngularFireStorage
            ) {
    this.handleCompletedStudy();
    this.onAllChange();
    this.onFilterChange();
  }

  private handleCompletedStudy() {
    this.radiologistService.eventCallbackStudyCompletedList$.subscribe((val2: Study[]) => {
      val2.forEach(val => {
        if (!this.studyList[val.studyInstanceUID]) {
          let countForIns: number = this.instituteID_studyCount[val.instituteId];
          if (countForIns)
            this.instituteID_studyCount[val.instituteId]++;
          else
            this.instituteID_studyCount[val.instituteId] = 1;
        }
        this.studyList[val.studyInstanceUID] = val;
      });
      this.updateDatasource();
    });
  }

  ngOnInit(): void {
    this.displayOnInit();
    // this.radiologistService.loadCompletedStudiesForRadiologist(1546281000000, 1654108200000);
  }

  displayOnInit(){
      const endDate: Date = new Date();
      const startDate: Date = new Date();
      startDate.setHours(0, 0, 0, 0); // last midnight
      startDate.setDate(endDate.getDate() - 1);
      let startDateMS = startDate.getTime();
      let endDateMS = endDate.getTime();
      this.radiologistService.loadCompletedStudiesForRadiologist(startDateMS, endDateMS);

  }

  display() {

    const startDate: Date = (this.startDateFormControl.value).toDate();
    const endDate: Date = (this.endDateFormControl.value).toDate();
    endDate.setDate(endDate.getDate() + 1);

    let startDateMS = startDate.getTime();
    let endDateMS = endDate.getTime();

    this.radiologistService.loadCompletedStudiesForRadiologist(startDateMS, endDateMS);
  }

  applyFilterPredicate() {
    this.dataSource.filterPredicate = (data, filter) => {
      const str = data.patient.name?.toLowerCase() + data.description?.toLowerCase() + data.patientHistory?.toLowerCase() + data.patient.patientID + data.studyID;
      return str.indexOf(filter.trim().toLowerCase()) != -1;
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  updateDatasource(studyList0?: Study[]) {
    this.count = Object.keys(this.studyList).length
    let localStudyList: Study[] = studyList0 ? studyList0 : [];
    if (!studyList0) {
      for (const key in this.studyList) {
        if (Object.prototype.hasOwnProperty.call(this.studyList, key)) {
          const element = this.studyList[key];
          localStudyList.push(element);
        }
      }
    }


    this.dataSource = new MatTableDataSource(localStudyList);
    this.dataSource.sort = this.sort;
    this.applyFilterPredicate();
  }

  getIcon(filePath: string) {
    let fileName = 'image-icon.png';
    if (filePath.endsWith('pdf')) {
      fileName = 'pdf-icon.png';
    } else if (filePath.endsWith('jpeg') || filePath.endsWith('jpg') || filePath.endsWith('png')) {
      fileName = 'image-icon.png';
    } else if (filePath.endsWith('doc') || filePath.endsWith('docx')) {
      fileName = 'word-icon.png';
    }

    return '/assets/images/' + fileName;

  }

  downloadFile(filePath: string) {
    const fileRef = this.storage.ref(filePath);
    fileRef.getDownloadURL().subscribe(val => {
      // console.log(val);
      window.open(val);
    }, err => {
      console.log(err);
    });
  }

  openViewDocDialog(link: string) {
    this.dialog.open(ViewDocumentComponent, {
      // width: '70%',
      // maxWidth: '80%',
      data: {
        link
      }
    });
  }

  openReportPanel(study: Study) {
    let oldApproach = false;
    try {
      oldApproach = !study.report[0].mode || study.report[0].mode === 'doc_file';
    } catch (error) {

    }

    if (!oldApproach) this.sendStudyForReportingEvent.emit(study);
    else this.openReportDialog(study.report);
  }

  openReportDialog(report: Report[]) {
    this.dialog.open(ViewReportDialogComponent, {
      data: {
        report
      }
    });
  }

  getDocName(filePath: string) {
    const array = filePath.split('/');
    return array[array?.length - 1];
  }

  onAllChange() {
    this.hospitalButtonToggleFCAll.valueChanges.subscribe(val => {
        this.updateDatasource();
    });
  }

  onFilterChange(){
    this.hospitalButtonToggleFC.valueChanges.subscribe(val => {
      let filteredHospitalList: string[] = val;
      const filteredServiceList: Study[] = [];

      for (const key in this.studyList) {
        if (Object.prototype.hasOwnProperty.call(this.studyList, key)) {
          const element = this.studyList[key];
          const isIncluded = filteredHospitalList.includes(element.instituteId);
          if (isIncluded) filteredServiceList.push(element);
        }
      }

      this.updateDatasource(filteredServiceList);

    })
  }

  fileName= 'ExcelSheet.xlsx';
  exportexcel(): void
    {
       /* table id is passed over here */
       let element = document.getElementById('completedTable');
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       XLSX.writeFile(wb, this.fileName);

    }
}
