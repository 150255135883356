import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { interval, Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { HttpClientService, Report, ReportSample, Study, SubmitStatusValues } from 'src/app/service/http/http-client.service';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from 'src/app/auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

import { IndiRadioServiceService, ReportInIndexedDB } from 'src/app/individual-radiologist-fn/indi-radio-service.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { MatTableDataSource } from '@angular/material/table';
// import { ReportWritingData } from '../report-container/report-container.component';

@Component({
  selector: 'app-report-writing-pad',
  templateUrl: './report-writing-pad.component.html',
  styleUrls: ['./report-writing-pad.component.css']
})
export class ReportWritingPadComponent implements OnInit {

  subscription: Subscription;
  indexedDBReport: ReportInIndexedDB;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '25rem',
    minHeight: '20rem',
    maxHeight: '60rem',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter report here...',
    defaultParagraphSeparator: 'div',
    defaultFontName: 'Calibri',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    outline: true,
    toolbarHiddenButtons: [
      // ['strikeThrough'],
      ['customClasses', 'link', 'unlink', 'insertImage', 'insertVideo', 'toggleEditorMode'],

    ]
  };

  isEditMode = false;
  disableEditor = false;
  templateNameFC = new FormControl();
  options: string[] = [];
  filteredOptions: Observable<string[]>;
  submittedReportList: Report[] = [];
  isReportLoaded = false;
  public SubmitStatusValues: typeof SubmitStatusValues = SubmitStatusValues;
  templateContentFC = new FormControl();
  suitableTemplateName: string[];
  isUploading = false;

  displayedColumns: string[] = ['index', 'name', 'timestamp', 'load'];
  dataSource = new MatTableDataSource<Report>();

  selectedReportSample: ReportSample;
  @Input() activeTabIndex: number;
  @Input() tabIndex: number;
  @Input() reportName: string;
  @Output() reportSubmitted = new EventEmitter<Report>();;
  study: Study;
  usedTemplateName: string;
  constructor(public radiologistService: IndiRadioServiceService, private storage: AngularFireStorage,
    private httpClient: HttpClientService) {
    this.options = radiologistService.templateNames;
    this.configureSchedulingForSavingReport();

  }

  ngOnInit(): void {
    this.filteredOptions = this.templateNameFC.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  loadIndexedDBReport() {
    this.templateContentFC.setValue(this.indexedDBReport.text);
  }

  configureSchedulingForSavingReport() {
    const source = interval(10000);
    this.subscription = source.subscribe(val => {
      // console.log(val);
      this.saveInIndexedDB();
    });
  }

  saveInIndexedDB() {

    try {
      let reportText = this.templateContentFC.value as string;
      if (reportText) reportText = reportText.trim();
      else return;
      // console.log(reportText.length + '] ' + reportText);

      if (reportText === '' || reportText.length < 20) {
        console.log(this.reportName + ' Report Text is null/empty/small, hence not saving/updating,reportText:[' + reportText + ']');
        return;
      }

      if (this.indexedDBReport) {
        // console.log(this.reportName + ' updateInIndexedDB');
        this.indexedDBReport.text = reportText;
        this.indexedDBReport.savedAt = new Date();
        this.indexedDBReport.savedAtMS = new Date().getTime();
        this.radiologistService.updateReportInIndexedDB(this.indexedDBReport);

      } else {
        console.log(this.reportName + ' saveInIndexedDB-new save');
        let e: ReportInIndexedDB = {
          patientId: this.study.patient.patientID,
          studyId: this.study.studyID,
          name: this.reportName,
          text: reportText,
          savedAt: new Date(),
          savedAtMS: new Date().getTime(),
          errorInOnline: '',
          onlinePath: '',
          status: ''
        }
        this.radiologistService.saveReportInIndexedDB2(e);
        this.indexedDBReport = e;
      }
    } catch (error) {
      console.error(error);
    }

  }

  @Input('reportName')
  set _reportName(r: string){
    if (!r) return;
    this.reportName = r;
    this.findAndLoadReportVariable();
  }

  @Input('study')
  set _study(s: Study) {
    this.templateContentFC.reset();
    // console.log('resetted tempalte content fc.');

    this.study = s;
    this.disableEditor = this.study.isConsultant;

    this.radiologistService.getReportFromIndexedDB2(this.study.patient.patientID, this.study.studyID, this.reportName).then(val => {
      // console.log(2 + val);
      val?.forEach(element => {
        if (element) {
          this.indexedDBReport = element;
          // console.log('Local Saved Report found');
        }
      });
    })

    // console.log('ForReporting ' + this.study.studyID + ' expecting ' + this.study.expectedReportCount + ' name: ' + this.reportName);

    if (this.study.expectedReportCount === 1) this.editorConfig.height = '33em';

    const reportCount: number = this.study.report ? this.study.report.length : 0;
    // console.log('currentReportCount: ' + reportCount);

    let reportIndex = 0;

    if (reportCount > 0) this.isEditMode = true;
    if (this.isEditMode && this.tabIndex) reportIndex = this.tabIndex;



    if (this.study.isConsultant) {
      // console.log(this.study);

      this.editorConfig.editable = false;
      this.editorConfig.showToolbar = false;
      this.editorConfig.enableToolbar = false;
      this.editorConfig.width = '68em';
      this.findAndLoadReportVariable();

    }


    if (this.isEditMode) {
      // console.log('');
      this.findAndLoadReportVariable();
      // if (submittedReprtList.length)
    } else {
      this.templateContentFC.reset();
      // console.log('again resetting...');
    }

  }

  private findAndLoadReportVariable() {
    // console.log('findAndLoadReportVariable-' + this.reportName);
    if (this.reportName && this.study) {
      this.submittedReportList = this.study.report?.filter(r => r.name === this.reportName);
      if (this.submittedReportList) this.prepareTable();
    } else if (this.study.reportNameList?.length === 1 && this.study.report) {
      this.loadReport(this.study.report[0]);
    }
  }

  prepareTable() {
    this.isReportLoaded = false;
    this.updateDataSource();
  }

  loadReport(r: Report) {
    this.downloadTextContentFromFile(r.link);
    this.isReportLoaded = true;
  }

  reloadReport() {
    try{
      const r: Report = this.study.report[this.tabIndex];
      this.downloadTextContentFromFile(r.link);
    } catch(e) {
      console.error(e);
    }
  }

  updateDataSource() {
    this.dataSource = new MatTableDataSource(this.submittedReportList);
  }

  downloadTextContentFromFile(filePath: string) {
    const fileRef = this.storage.ref(filePath);
    fileRef.getDownloadURL().subscribe(val => {
      this.httpClient.getCall(val).subscribe((content: string) => {
        this.templateContentFC.setValue(content);
      });

    }, err => {
      console.log(err);
    });
  }

  @Input('selectedReportSample')
  set _selectedReportSample(reportSample: ReportSample) {

    if (reportSample && (this.activeTabIndex === this.tabIndex)) {
      this.templateContentFC.setValue(reportSample.text);
      this.usedTemplateName = reportSample.name;
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  closePanel() {
    this.saveInIndexedDB();
    this.templateContentFC.reset();
    // this.reportSubmitted.emit(true);
  }

  test() {
    const file = this.templateContentFC.value;
    console.log(file);

  }

  sendReportForSubmission(report: Report) {
    console.log('sendReportForSubmission');

    this.saveInIndexedDB();
    if (this.isEditMode) report.isEdited = 1;
    if (this.usedTemplateName) report.templateUsed = this.usedTemplateName;
    this.reportSubmitted.emit(report);
  }

  // reportSendViaEmail = 2
  // reportLater = 1
  //reportSendViaOtherMedium = 3
  uploadReportLater(laterMode: number) {
    let report: Report = new Report(null, null, null, null, null, null, 0, laterMode);
    this.sendReportForSubmission(report);
  }

  fileRef: AngularFireStorageReference;
  task: AngularFireUploadTask;
  uploadPercent: Observable<number>;
  uploadTextReport(submitStatus?: SubmitStatusValues) {
    this.isUploading = true;
    const reportText = this.templateContentFC.value;
    let mode = 'html_file';
    let report: Report = new Report(reportText, null, new Date(), mode, this.reportName, submitStatus, 0);

    console.log('going to save file 0');

    let filePath = 'NEED_TO_SET';
    if (report.mode === 'html_file') {
      const file = report.text;
      var myblob = new Blob([file], {
        type: 'text/plain'
      });
      const insID = this.study.instituteId;
      const fileNameAppender = new Date().getTime();
      const fileName = this.study.patient.name + '_' + this.study.studyID + '_' + fileNameAppender + '.html';
      filePath = '/' + insID + '/' + this.study.modality + '/' + this.study.patient.patientID + '/' + this.study.studyID + '/report/' + fileName;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, myblob);
      this.uploadPercent = task.percentageChanges();
      this.uploadPercent.subscribe(val => {
        console.log(val);
        if (val === 100) {
          this.isUploading = false;
          report.link = filePath
          console.log('Upload is 100%, sending report to saving in firebase');
          this.sendReportForSubmission(report);
        }
      });
    }

    // console.log('going to save file 1');
    // this.sendReportForSubmission(report);
  }


  uploadWordReport(study: Study, submitStatus?: SubmitStatusValues) {
    console.log('send file to storage and save path in db');
    const file = this.files[study.studyID];
    const insID = study.instituteId;
    const fileName = study.patient.name + '_' + study.studyID + '_' + file.name;
    const filePath = '/' + insID + '/' + study.modality + '/' + study.patient.patientID + '/' + study.studyID + '/report/' + fileName;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    let mode = 'doc_file';
    let report: Report = new Report(null, filePath, new Date(), mode, this.reportName, submitStatus, 0);
    this.sendReportForSubmission(report);

  }

  ////////////////////////------FILE F:N------///////////////////////
  files: any[] = [];

  /**
   * on file drop handler
   */
  onFileDropped($event, studyID) {
    this.prepareFilesList($event, studyID);
  }

  /**
   * handle file from browsing
   */ // 1
  fileBrowseHandler(files, studyID: string) {
    this.prepareFilesList(files, studyID);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(studyID: string) {
    //  this.files.splice(index, 1);
    delete this.files[studyID];
  }


  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */

  //2
  prepareFilesList(files: Array<any>, studyID: string) {
    for (const item of files) {
      item.progress = 0;
      //  this.files.push(item);
      this.files[studyID] = item;
    }
    //  this.uploadFilesSimulator(0);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

}
