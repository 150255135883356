<mat-drawer-container class="main-drawer-container" autosize>
  <mat-drawer #drawer class="example-sidenav" mode="over" position="end" [autoFocus]="false" disableClose>
    <app-report-container *ngIf="studyCameForReport" class="report-dialog-from-home" [study]="studyCameForReport" (needToCloseEvent)="closePanel($event)"></app-report-container>
  </mat-drawer>

  <div class="parent-div example-sidenav-content" *ngIf="userInSession;else login" >

    <ng-container *ngIf="isConsultant; else elseTemplate">
          <app-consulting-studies fxFill (sendStudyForReportingEvent)="openReportPanel($event, true)" ></app-consulting-studies>

    </ng-container>
    <ng-template #elseTemplate>
              <mat-tab-group fxFill>
                <mat-tab label="ON GOING - NEW" style="border: 2px solid blue">
                  <div class="mat-tab-content11">
                    <app-indi-radio-pending-studies (sendStudyForReportingEvent)="openReportPanel($event)">
                    </app-indi-radio-pending-studies>
                  </div>

                </mat-tab>
                <mat-tab label="Completed - New">
                  <ng-template matTabContent>
                    <app-indi-radio-completed-studies (sendStudyForReportingEvent)="openReportPanel($event)">
                    </app-indi-radio-completed-studies>
                  </ng-template>
                </mat-tab>
                <mat-tab label="Ongoing">
                  <app-pending-studies [instituteIdList]="instituteIdList" [collectionName]="collectionName"></app-pending-studies>
                </mat-tab>
                <mat-tab label="Completed">
                  <app-completed-studies [instituteIdList]="instituteIdList" [collectionName]="collectionName"
                    (sendStudyForReportingEvent)="openReportPanel($event)"></app-completed-studies>
                </mat-tab>
              </mat-tab-group>
    </ng-template>

  </div>
</mat-drawer-container>


<ng-template #login fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px" class="border-blue">
  <app-login></app-login>
</ng-template>
