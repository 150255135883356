<div class="parent-div mat-elevation-z8">
    <marquee behavior=alternate style="color: red;">
        By default, this screen contains list of studies done in last 1 day, for older studies, please select date
        range and click Display
    </marquee>
    <div fxLayout>
        <label class="mdl-button mdl-js-button mdl-button--icon" for="search-field">
            <i class="material-icons">search</i>
        </label>
        <div>
            <mat-form-field>
                <!-- <input class="mdl-textfield__input" type="text" id="search-field" matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter"> -->
                <input matInput (keyup)="applyFilter($event.target.value)" id="search-field"
                    class="mdl-textfield__input" placeholder="Filter" [formControl]="searchBarFormControl">
            </mat-form-field>
        </div>

        <label class="mdl-button mdl-js-button mdl-button--icon" for="search-field1">
            <i class="material-icons">date</i>
        </label>

        <div>

            <!-- <div fxLayout="column" fxLayoutGap="10px"> -->
            <div fxLayout fxLayoutGap="10px" fxFlex>

                <!-- <mat-button-toggle-group name="fontStyle" class="button-toggle-group" aria-label="Font Style" [formControl]="queryParamFormControl">
            <mat-button-toggle value="ed">Entry Date</mat-button-toggle>
            <mat-button-toggle value="id">Invoice Date</mat-button-toggle>
          </mat-button-toggle-group> -->

                <!-- <div fxLayout fxLayoutGap="10px"> -->
                <!-- <button mat-stroked-button color="primary" class="default-button" (click)="generatePurchaseHistoryForToday()"> for Today </button> -->
                <!-- </div> -->

                <img class="s_icon" src="./assets/icon/icon-start.png">
                <!-- <mat-form-field>
            <input matInput [matDatepicker]="startDate1" placeholder="Start Date" [formControl]="startDateFormControl">
            <mat-datepicker-toggle matSuffix [for]="startDate1" tabindex="-1"></mat-datepicker-toggle>
            <mat-datepicker #startDate1 startView="month" [startAt]="todayDate"></mat-datepicker>
          </mat-form-field>
  
          <mat-form-field>
            <input matInput [matDatepicker]="endDate1" placeholder="End Date" [formControl]="endDateFormControl">
            <mat-datepicker-toggle matSuffix [for]="endDate1" tabindex="-1"></mat-datepicker-toggle>
            <mat-datepicker #endDate1 startView="month" [startAt]="todayDate"></mat-datepicker>
          </mat-form-field> -->
                <mat-form-field>
                    <mat-label>Enter a date range</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate placeholder="Start date" [formControl]="startDateFormControl">
                        <input matEndDate placeholder="End date" [formControl]="endDateFormControl">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>

                <img class="s_icon" src="./assets/icon/icon-end.png">

                <div fxLayout fxLayoutGap="10px">
                    <!-- <button mat-button color="primary" (click)="generatePurchaseHistoryForToday()"> Today </button> -->
                    <!-- <button mat-button color="primary" class="default-button" (click)="filterStudyOnEndDate()"> Display </button> -->
                    <button mat-button color="primary" class="default-button" (click)="display()"> Display </button>
                </div>
            </div>

            <!-- </div> -->

        </div>


    </div>

    <table mat-table [dataSource]="dataSource" matSort #sort>

        <ng-container matColumnDef="Count">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
            <td mat-cell *matCellDef="let element;let j = index"> {{paginator.pageSize * paginator.pageIndex + j + 1}}
            </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element">

                <button mat-button (click)="getUrlForStudy(element)" matTooltip="Click to View">
                    {{element.patient.name}}
                </button>

            </td>
        </ng-container>

        <ng-container matColumnDef="modality">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Mod. </th>
            <td mat-cell *matCellDef="let element"> {{element.modality}}-{{element.onlineImageCount}} </td>
        </ng-container>

        <ng-container matColumnDef="referringPhysicianName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Ref. Physician </th>
            <td mat-cell *matCellDef="let element"> {{element.referringPhysicianName}} </td>
        </ng-container>

        <ng-container matColumnDef="studyDateTime">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Study Time </th>
            <td mat-cell *matCellDef="let element"> {{element.studyDateTime | date:'d MMM yy, h:mm a'}} </td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> Study Desc </th>
            <td mat-cell *matCellDef="let element"> {{element.description}} </td>
        </ng-container>

        <ng-container matColumnDef="PatientHistory">
            <th mat-header-cell *matHeaderCellDef> History </th>
            <td mat-cell *matCellDef="let element"> {{element.patientHistory}} </td>
        </ng-container>

        <!-- <ng-container matColumnDef="AttachedDocument">
        <th mat-header-cell *matHeaderCellDef> Attached Document </th>
        <td mat-cell *matCellDef="let element"> <span *ngFor="let doc of element.additionalDocument">
            <span (click)="downloadFile(doc)">
              <img class="image-icon" src="{{getIcon(doc)}}" alt="Avatar" class="image" matTooltip="{{getDocName(doc)}}">
            </span>
          </span> </td>
      </ng-container> -->
        <ng-container matColumnDef="AttachedDocument">
            <th mat-header-cell *matHeaderCellDef> Attached Document </th>
            <td mat-cell *matCellDef="let element"> <span *ngFor="let doc of element.additionalDocument">
                    <span (click)="openViewDocDialog(doc)">
                        <img class="image-icon" src="{{getIcon(doc)}}" alt="Avatar" class="image"
                            matTooltip="{{getDocName(doc)}}">
                    </span>
                </span> </td>
        </ng-container>


        <ng-container matColumnDef="View">
            <th mat-header-cell *matHeaderCellDef> View </th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button (click)="getUrlForStudy(element)">
                    <mat-icon>
                        visibility
                    </mat-icon>
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="ViewReport">
            <th mat-header-cell *matHeaderCellDef> View </th>
            <td mat-cell *matCellDef="let element">
                <button mat-button (click)="openReportDialog(element.report)">Open Report</button>
            </td>
        </ng-container>
        <ng-container matColumnDef="Options">
            <th mat-header-cell *matHeaderCellDef> Offline </th>
            <td mat-cell *matCellDef="let row">
                <!-- <button mat-menu-item (click)="sendToPubsub(row)">
            <mat-icon>cloud_download</mat-icon>
          </button> -->

                <!-- <button mat-icon-button color="primary" (click)="sendToPubsub(row)"
                    aria-label="Example icon button with a home icon">
                    <mat-icon>cloud_download</mat-icon>
                </button> -->

                <!-- <img class="image-icon" src="./assets/icon/download-dicom.png" alt="Avatar" class="image"> -->

                <!-- <img class="s_icon" src="./assets/icon/download-dicom.png"> -->

                <!-- <button mat-icon-button color="warn" (click)="editItem(row)">
      <mat-icon>more_vert</mat-icon>
    </button> -->
                <!-- <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item *ngIf="!row.offlineAvailable" (click)="sendToPubsub(row)" color="primary">
              <mat-icon>motion_photos_on</mat-icon>
              <span>Request For Offline Access</span>
            </button>
            <button mat-menu-item *ngIf="row.offlineAvailable" (click)="downloadImagesZipFile(row)" color="primary">
              <mat-icon>cloud_download</mat-icon>
              <span>Download Offline</span>
            </button>
          </mat-menu> -->

            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <!-- <div *ngIf="offlineRequested">
        <span>Preparing your download</span>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div> -->
    <mat-paginator #paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
</div>