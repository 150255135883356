<div fxLayout>

  <button mat-stroked-button color="primary" (click)="createInstituteAndItsTechnician()">Institue And I.Technician</button>
  <!-- <button mat-stroked-button color="accent" (click)="createRadiologist()">Create Radiologist</button> -->
  <!-- <button mat-stroked-button color="accent" (click)="attachDocToInstitute()">Attach Radiologist</button> -->
  <!-- <button mat-stroked-button color="warn">Warn</button> -->

  <li>
    aga,
    <ul *ngFor="let i of instituteList">
      {{i.name}}
    </ul>
  </li>


</div>
