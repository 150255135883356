import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { ReportSample, Study } from 'src/app/service/http/http-client.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { environment } from 'src/environments/environment';
import { ViewDocumentComponent } from 'src/app/view-document/view-document.component';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from 'src/app/auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

// import firestore from 'firebase/app';

import firebase from 'firebase/app'; 
import 'firebase/firestore';


import { IndiRadioServiceService } from 'src/app/individual-radiologist-fn/indi-radio-service.service';

@Component({
  selector: 'app-report-dialog',
  templateUrl: './report-dialog.component.html',
  styleUrls: ['./report-dialog.component.css']
})
export class ReportDialogComponent implements OnInit {

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '30rem',
    minHeight: '20rem',
    maxHeight: '60rem',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter report here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    outline: true,
    toolbarHiddenButtons: [
      ['strikeThrough'],
      ['link', 'unlink', 'insertImage', 'insertVideo','toggleEditorMode', 'customClasses'],
    ]
  };

  templateNameFC = new FormControl();
  options: string[] = [];
  filteredOptions: Observable<string[]>;

  templateContent = new FormControl();
  suitableTemplateName: string[];

  @Output() needToCloseEvent = new EventEmitter<boolean>(); ;
  study: Study;
  constructor(public radiologistService: IndiRadioServiceService, private storage: AngularFireStorage, private firestore: AngularFirestore, 
    private authService: AuthService, private snackBar: MatSnackBar) {
      this.options = radiologistService.templateNames;
      
     }

  ngOnInit(): void {
    this.filteredOptions = this.templateNameFC.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value)) 
      );
  }

  @Input('study')
  set _study(s: Study) {
    this.study = s;
    let docId = this.study.patient.patientID + '-' + this.study.studyID;
    if (this.study.approachType && this.study.approachType === 1) docId = this.study.instituteId + '-' + docId;
    this.study.fbDocId = docId;
    this.suitableTemplateName = this.filterTemplateNameOnStudyDesc();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  closePanel(){
    this.needToCloseEvent.emit(true);
    this.templateContent.reset();
  }

  uploadReport(study: Study) {
    let expectedReportCountLocal = 1;
    if (study.expectedReportCount) expectedReportCountLocal = study.expectedReportCount;
    const currentReportCount = study.report ? study.report.length : 0;
    console.log('send file to storage and save path in db');
    // const file = this.files[study.studyID];
    const file = this.templateContent.value;
    var myblob = new Blob([file], {
      type: 'text/plain'
  });
    const insID = study.instituteId;

    const fileNameAppender = new Date().getMilliseconds();
    const fileName = study.patient.name + '_' + study.studyID + '_' + fileNameAppender +'.txt' ;
    const filePath = '/' + insID + '/' + study.modality + '/' + study.patient.patientID + '/' + study.studyID + '/report/' + fileName;
    // console.log(filePath);
    
    const reportArrived = expectedReportCountLocal === (currentReportCount + 1);
    try {
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, myblob);
      const collectionName = this.radiologistService.prepareCollectionName(study.instituteId);

      if (!study.fbDocId) {
        let docId = study.patient.patientID + '-' + study.studyID;
        if (study.approachType && study.approachType === 1) docId = insID + '-' + docId;
        study.fbDocId = docId;
      }



      console.log('DocId used for report saving: '+ study.fbDocId);
      
      this.firestore.collection(collectionName).doc(study.fbDocId).update({
        reportArrived: reportArrived,
        reportDownloadedAtRt: false,
        report: firebase.firestore.FieldValue.arrayUnion({
          mode: 'txt_file',
          link: filePath,
          timestamp: new Date(),
          timestampMS: new Date().getTime(),
          done_by: this.authService.user.email
        })
      });



      this.closePanel();
    } catch (error) {
      console.error(error);
    }
  }

  // getPath(study: Study){
  //   const insID = study.instituteId;
  //   // const fileName = study.patient.name+ '_' +study.studyID + '_' + file.name;
  //   const filePath = '/' + insID + '/' + study.modality + '/'+ study.patient.patientID + '/' + study.studyID + '/report/';
  //   window.open(filePath)

  // }
  ////////////////////////------FILE F:N------///////////////////////
  files: any[] = [];

  /**
   * on file drop handler
   */
  onFileDropped($event, studyID) {
    this.prepareFilesList($event, studyID);
  }

  /**
   * handle file from browsing
   */ // 1
  fileBrowseHandler(files, studyID: string) {
    this.prepareFilesList(files, studyID);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(studyID: string) {
    //  this.files.splice(index, 1);
    delete this.files[studyID];
  }

  /**
   * Simulate the upload process
   */
  //  uploadFilesSimulator(index: number) {
  //    setTimeout(() => {
  //      if (index === this.files.length) {
  //        return;
  //      } else {
  //        const progressInterval = setInterval(() => {
  //          if (this.files[index]?.progress === 100) {
  //            clearInterval(progressInterval);
  //            this.uploadFilesSimulator(index + 1);
  //          } else {
  //            // this.files[index].progress += 5;
  //          }
  //        }, 200);
  //      }
  //    }, 1000);
  //  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */

  //2
  prepareFilesList(files: Array<any>, studyID: string) {
    for (const item of files) {
      item.progress = 0;
      //  this.files.push(item);
      this.files[studyID] = item;
    }
    //  this.uploadFilesSimulator(0);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  ////////////////////////////////////////////////////////////////


  loadTemplate(rs: string, isFromDropDown?: boolean){
    rs = rs.trim();
    this.templateContent.setValue(this.radiologistService.templateMap[rs]?.text);
  }



  filterTemplateNameOnStudyDesc(): string[]{
    console.log(this.radiologistService.templateNames.length + ' length of template names');
    const desc = this.study.description.toLowerCase().replace(' ', '');
    const list = this.radiologistService.templateNames.filter(name => name.toLowerCase().includes(desc));
    console.log(list);
    return list;
  }

}
