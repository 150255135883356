<div class="parent-div mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="Count">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
        <td mat-cell *matCellDef="let element;let j = index">{{paginator.pageSize * paginator.pageIndex + j + 1}} </td>
      </ng-container>

      <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let element">

          <!-- {{element.patient.name}} // {{element.patient.patientID}}/{{element.studyID}}/report/ -->
          <button mat-button (click)="radiologistService.getUrlForStudy(element)" matTooltip="Click to View" [ngClass]="{'opened': element.studyUnderObservation}">
              {{element.patient.name}}
          </button>



        </td>
      </ng-container>

      <ng-container matColumnDef="modality">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Modality </th>
        <td mat-cell *matCellDef="let element"> {{element.modality}} </td>
      </ng-container>

      <ng-container matColumnDef="StudyDesc">
        <th mat-header-cell *matHeaderCellDef> Study Desc </th>
        <td mat-cell *matCellDef="let element"> {{element.description}} </td>
      </ng-container>

      <ng-container matColumnDef="studyDateTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Study Time </th>
        <td mat-cell *matCellDef="let element"> {{element.studyDateTime | date:'d MMM yy, h:mm a'}} </td>
      </ng-container>

      <ng-container matColumnDef="refdoc">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Ref. Physician </th>
        <td mat-cell *matCellDef="let element"> {{element.referringPhysicianName === 'null' ?  '': element.referringPhysicianName}}  </td>
      </ng-container>

          <ng-container matColumnDef="reportDialog">
            <th mat-header-cell *matHeaderCellDef> Report </th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button color="primary" (click)="openReportPanel(element)" *ngIf="element.reportArrived">
                <!-- <mat-icon>quickreply</mat-icon> -->
                <mat-icon>app_registration</mat-icon>
              </button>
            </td>
          </ng-container>

      <ng-container matColumnDef="totalImages">
        <th mat-header-cell *matHeaderCellDef> Online Images </th>
        <td mat-cell *matCellDef="let element"> {{element.onlineImageCount}} </td>
      </ng-container>

      <ng-container matColumnDef="PatientHistory">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> History </th>
        <td mat-cell *matCellDef="let element"> {{element.patientHistory}} </td>
      </ng-container>

      <ng-container matColumnDef="AttachedDocument">
        <th mat-header-cell *matHeaderCellDef> Attached Document </th>
        <td mat-cell *matCellDef="let element"> <span *ngFor="let doc of element.additionalDocument">
            <span (click)="openViewDocDialog(doc)">
              <img class="image-icon" src="{{getIcon(doc)}}" alt="Avatar" class="image" matTooltip="{{getDocName(doc)}}">
            </span>
          </span> </td>
      </ng-container>

      <ng-container matColumnDef="instituteId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Hospital </th>
        <td mat-cell *matCellDef="let element"> {{element.instituteId}} </td>
    </ng-container>

      <ng-container matColumnDef="View">
        <th mat-header-cell *matHeaderCellDef> View </th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button (click)="radiologistService.getUrlForStudy(element)">
            <mat-icon>
              visibility
            </mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="Options">
        <th mat-header-cell *matHeaderCellDef> Offline </th>
        <td mat-cell *matCellDef="let row">
          <!-- <button mat-menu-item (click)="sendToPubsub(row)">
            <mat-icon>cloud_download</mat-icon>
          </button> -->

          <!-- <img (click)="sendToPubsub(row)" class="download-image" src="./assets/icon/download-dicom.png" alt="Avatar"> -->

          <!-- <button mat-icon-button color="warn" (click)="editItem(row)">
      <mat-icon>more_vert</mat-icon>
    </button> -->
          <!-- <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item *ngIf="!row.offlineAvailable" (click)="sendToPubsub(row)" color="primary">
              <mat-icon>motion_photos_on</mat-icon>
              <span>Request For Offline Access</span>
            </button>
            <button mat-menu-item *ngIf="row.offlineAvailable" (click)="downloadImagesZipFile(row)" color="primary">
              <mat-icon>cloud_download</mat-icon>
              <span>Download Offline</span>
            </button>
          </mat-menu> -->

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'opened': row.studyUnderObservation, 'stillfresh': !row.studyUnderObservation}"></tr>
    </table>

    <mat-paginator #paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
  </div>
  <!-- <div *ngIf="offlineRequested">
    <span>Preparing your download</span>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div> -->
  <!-- <h5>Time Remaining {{remainingTimeMin - timeElaspsed}} min | TUAT {{tokedUpdatedAt?.seconds  * 1000 | date:'hh:mm aaa' }} | {{_collectionName}}</h5> -->
  <!-- Sample comment -->
  <!-- <button mat-raised-button (click)="acceptAll()">View Non Accepted Studies</button> -->
