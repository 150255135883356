<div class=" height-100percent" fxLayout fxLayoutAlign="start start" fxLayoutGap="5px">

    <app-user-list fxFlex="20" fxFill (UserInFirebaseSelectionEvent)="captureUserInFirebaseSelection($event)">
    </app-user-list>


    <div fxFlex="80" class=" height-100percent">

        <app-user-console [UserInFirebase]="selectedUserInFirebase"></app-user-console>

    </div>
</div>