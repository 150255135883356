import { Injectable } from '@angular/core';
import 'firebase/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { DomSanitizer } from '@angular/platform-browser';
import { AngularFireStorage } from '@angular/fire/storage';
import { AuthService } from 'src/app/auth/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Study, TT, UserInFirebase, PropertyCluster, Hospital } from 'src/app/service/http/http-client.service';
import { Subject } from 'rxjs';

const FB_COLLECTION_HOSPITAL = "hospital";
const FB_COLLECTION_PROPERTY_CLUSTER = "cluster";

@Injectable({
  providedIn: 'root'
})
export class AgencyServiceService {

  private eventCallbackStudyAssigned = new Subject<Study>(); // Source
  eventCallbackStudyAssigned$ = this.eventCallbackStudyAssigned.asObservable(); // Stream

  private eventCallbackStudyUnAssigned = new Subject<Study>(); // Source
  eventCallbackStudyUnAssigned$ = this.eventCallbackStudyUnAssigned.asObservable(); // Stream
  
  private eventCallbackRadiologist = new Subject<UserInFirebase[]>(); // Source
  eventCallbackRadiologist$ = this.eventCallbackRadiologist.asObservable(); // Stream
  
  // private eventCallbackPendingStudyForRadiologist = new Subject<Study>(); // Source
  // eventCallbackPendingStudyForRadiologist$ = this.eventCallbackPendingStudyForRadiologist.asObservable(); // Stream
  
  // private eventCallbackCompletedStudyForRadiologist = new Subject<Study>(); // Source
  // eventCallbackCompletedStudyForRadiologist$ = this.eventCallbackCompletedStudyForRadiologist.asObservable(); // Stream

  agencyEmail: string;
  userInSession: any;
  hospitalListManagedByAgency: string[];
  radiologistInAgency: UserInFirebase[];
  aToken: string;
  clusterList: PropertyCluster[] = [];
  constructor(public firestore: AngularFirestore, public auth: AngularFireAuth, private storage: AngularFireStorage, private afirestore: AngularFirestore,
    private domSanitizer: DomSanitizer, private authService: AuthService) {
    console.log('AgencyServiceService called');

    this.authAndPrepareController();

    this.afirestore.collection<TT>('users', ref => ref.where('istt', '==', true)).valueChanges().subscribe(val => {
      // console.log('token changed' + val);
      this.aToken = val[0].at;
      localStorage.setItem('at', this.aToken);
      // this.tokedUpdatedAt = val[0].time;
      // console.log('GetAccessTokenFrom-PendingComponent' + this.tokedUpdatedAt);
    });

    this.afirestore.collection<PropertyCluster>(FB_COLLECTION_PROPERTY_CLUSTER).get().subscribe(val => {
      val.docs.forEach(doc => {
          this.clusterList[doc.id as string] = doc.data() as PropertyCluster;
      });
    });



  }

  hospital_clusterName_map: string[] = [];
  async getHospitals(hospitalIDList: string[]) {
    try {
      return this.firestore.collection<Hospital>(FB_COLLECTION_HOSPITAL, ref => ref.where('shortName', 'in', hospitalIDList)).get().toPromise();
    } catch (error) {
      console.error('Error Occurred');
      console.error(error);
    }
  }

  private authAndPrepareController() {
    console.log('authAndPrepareController called');

    this.auth.user.subscribe(val => {
      if (!val?.displayName) {
        this.userInSession = null;
        return;
      }
      this.agencyEmail = val?.email;
      
      this.getHospitalsForAgency(this.agencyEmail);

      this.firestore.collection<UserInFirebase>('users', ref => ref.where('agencyID', 'array-contains', val.email)).valueChanges().subscribe(val2 => {
        this.radiologistInAgency = val2;
        this.eventCallbackRadiologist.next(val2);
      });
    });
  }

  private getHospitalsForAgency(agencyEmail: string) {
    //TODO: Change value change to get()
    this.firestore.collection<UserInFirebase>('users', ref => ref.where('email', '==', agencyEmail)).valueChanges().subscribe(val2 => {
      this.hospitalListManagedByAgency = val2[0].agencyHospitals;
      console.log('[service]-' + this.hospitalListManagedByAgency);
      // this.updateStudyListForAllHospital();
      this.getHospitals(this.hospitalListManagedByAgency).then(val => {
        val.docs.forEach(doc=> {
          if(doc.exists) {
              const hospital: Hospital = doc.data() as Hospital;
              this.hospital_clusterName_map[hospital.shortName] = (this.clusterList[hospital.propertyCluster] as PropertyCluster).collectionName;
            }
            console.log('Hospital manipulation complete');
            console.log(this.hospital_clusterName_map);
            this.updateStudyListForAllHospital();
        })
      });
    });
  }

  private updateStudyListForAllHospital() {

    this.hospitalListManagedByAgency.forEach(hospitalName => {
      this.updateStudyListForAgency(this.prepareCollectionName(hospitalName));
    });
    this.updateStudyListForAgency('dev2-btct-studies');

  }

  private updateStudyListForAgency(collectionName: string) {
    console.log('[service]-Going to-updateStudyList():[' + collectionName + ']');

    this.afirestore.collection<Study>(collectionName,
      ref => ref.where('reportingAgency', '==', this.agencyEmail).where('assignedToRadiologist', '==', false).where('reportArrived', '==', false).orderBy('studyDateTime')).valueChanges().subscribe(val => {
        // console.log(val);
        val.forEach(element => {
          this.eventCallbackStudyUnAssigned.next(element);
        });
      });

    this.afirestore.collection<Study>(collectionName,
      ref => ref.where('reportingAgency', '==', this.agencyEmail).where('assignedToRadiologist', '==', true).where('reportArrived', '==', false).orderBy('studyDateTime')).valueChanges().subscribe(val => {
        // console.log(val);
        val.forEach(element => {
          this.eventCallbackStudyAssigned.next(element);
        });
      });
  }

  prepareCollectionName(hospitalShortName: string): string {
    // if (insName === 'DEV' || insName === 'BTCT')
    //   return 'dev-btct-studies';
    // return 'prod-' + insName.toLowerCase() + '-studies';

    let collectionName = this.hospital_clusterName_map[hospitalShortName];
    if (!collectionName) {
      collectionName = 'prod-' + hospitalShortName.toLowerCase() + '-studies';
    }
    console.log(hospitalShortName + ':' + collectionName);
    return collectionName;

  }

  updateStudy(study: Study) {
    try {
      const id = study.instituteId + '-' + study.patient.patientID + '-' + study.studyID;
      const collectionName = this.prepareCollectionName(study.instituteId);
      const studyCollection = this.afirestore.collection<Study>(collectionName);
      studyCollection.doc(id).set(study, { merge: true });
    } catch (error) {
      console.error(error);

    }
  }



}
