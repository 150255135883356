import { Component, OnInit, ViewChild } from '@angular/core';
import 'firebase/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { DomSanitizer } from '@angular/platform-browser';
import { AngularFireStorage } from '@angular/fire/storage';
import { AuthService } from 'src/app/auth/auth.service';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Study, HttpClientService, TT, UserInFirebase } from 'src/app/service/http/http-client.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { UnassignedStudyComponent } from '../unassigned-study/unassigned-study.component';
import { AssignedStudyComponent } from '../assigned-study/assigned-study.component';

@Component({
  selector: 'app-agency-home',
  templateUrl: './agency-home.component.html',
  styleUrls: ['./agency-home.component.css']
})
export class AgencyHomeComponent implements OnInit {

  hospitalFilterFormControl = new FormControl();
  userInSession: UserInFirebase;
  hospitalListManagedByAgency: string[];
  radiologistInAgency: UserInFirebase[];
  agencyEmail: string;
  // unAssigmentStudyList: Study[] = [];
  // assigmentStudyList: Study[] = [];
  insID = 'all';

  countUA = 0;
  countA = 0;

  @ViewChild(UnassignedStudyComponent) unassSC: UnassignedStudyComponent;
  @ViewChild(AssignedStudyComponent) assSC: AssignedStudyComponent;

  constructor(public firestore: AngularFirestore, public auth: AngularFireAuth, private storage: AngularFireStorage, private afirestore: AngularFirestore,
    private domSanitizer: DomSanitizer, private authService: AuthService, private snackBar: MatSnackBar, private httpClient: HttpClientService,
    public dialog: MatDialog) {
      
  }



  ngOnInit(): void {
  }


}
