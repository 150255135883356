import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms-of-services',
  templateUrl: './terms-of-services.component.html',
  styleUrls: ['./terms-of-services.component.css']
})
export class TermsOfServicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
