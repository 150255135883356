import { IndiRadioServiceService } from 'src/app/individual-radiologist-fn/indi-radio-service.service';
import { DomSanitizer } from '@angular/platform-browser';
// import { StudyInHomeController } from './../home.component';
import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
// import { environment } from 'src/environments/environment';
// import { AuthService } from 'src/app/auth/auth.service';
// import { DataSource } from '@angular/cdk/table';
// import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
// import { StudyService } from 'src/app/service/study-service.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Study } from 'src/app/service/http/http-client.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ViewDocumentComponent } from 'src/app/view-document/view-document.component';
import { MatDialog } from '@angular/material/dialog';
// import auth from 'firebase';
// import firestore from 'firebase/app';
// import { Subscription, interval } from 'rxjs';
// import { take, map } from 'rxjs/operators';

@Component({
  selector: 'app-consulting-studies',
  templateUrl: './consulting-studies.component.html',
  styleUrls: ['./consulting-studies.component.css']
})
export class ConsultingStudiesComponent implements OnInit {

  @Output() sendStudyForReportingEvent = new EventEmitter<Study>();

  studyList: Study[] = [];
  instituteID_studyCount: number[] = [];
  count: number;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = ['Count', 'Name', 'reportDialog', 'modality', 'instituteId','refdoc', 'studyDateTime', 'StudyDesc', 'totalImages', 'PatientHistory', 'AttachedDocument', 'Options'];
  dataSource: MatTableDataSource<any>;
  remainingTimeMin = 0;
  timeElaspsed = 0;
  constructor(private storage: AngularFireStorage,
    public radiologistService: IndiRadioServiceService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog) {
      console.log('handleIncomingConsultantStudies');
      // this.updateDatasource();
      this.handleIncomingConsultantStudies();
      // this.handleIncomingConsultantStudies3();

    }

  ngOnInit(): void {  }

  updateDatasource(studyList0?: Study[]) {
    console.log(this.studyList);
    this.count = Object.keys(this.studyList).length
    let localStudyList: Study[] = studyList0 ? studyList0 : [];
    if (!studyList0) {
      for (const key in this.studyList) {
        if (Object.prototype.hasOwnProperty.call(this.studyList, key)) {
          const element = this.studyList[key];
          localStudyList.push(element);
        }
      }
    }

    this.dataSource = new MatTableDataSource(localStudyList);
    this.dataSource.sort = this.sort;
  }

  public handleIncomingConsultantStudies() {
    try {
      console.log('handleIncomingConsultantStudies-1');

      this.radiologistService.eventCallbackStudyConsultantList$.subscribe((val2: Study[]) => {
        console.log('handleIncomingConsultantStudies-2');
        this.prepareStudyListFromFirebaseResults(val2);
        this.updateDatasource();
      });
    } catch (error) {
      console.log(error);

    }
  }

  private prepareStudyListFromFirebaseResults(val2: Study[]) {
    val2.forEach(val => {
      if (!this.studyList[val.studyInstanceUID]) {
        let countForIns: number = this.instituteID_studyCount[val.instituteId];
        if (countForIns)
          this.instituteID_studyCount[val.instituteId]++;

        else
          this.instituteID_studyCount[val.instituteId] = 1;
      }
      this.studyList[val.studyInstanceUID] = val;
    });
  }

  openReportPanel(study: Study) {
    this.sendStudyForReportingEvent.emit(study);
  }

  openSnackBar(message: string, action: string, duration?: number) {
    if (!duration) {
      duration = 120000;
    }
    this.snackBar.open(message, action, {
      duration,
    });
  }

  getIcon(filePath: string) {
    let fileName = 'image-icon.png';
    if (filePath.endsWith('pdf')) {
      fileName = 'pdf-icon.png';
    } else if (filePath.endsWith('jpeg') || filePath.endsWith('jpg') || filePath.endsWith('png')) {
      fileName = 'image-icon.png';
    } else if (filePath.endsWith('doc') || filePath.endsWith('docx')) {
      fileName = 'word-icon.png';
    }

    return '/assets/images/' + fileName;

  }

  downloadFile(filePath: string) {
    const fileRef = this.storage.ref(filePath);
    fileRef.getDownloadURL().subscribe(val => {
      console.log(val);
      window.open(val);
    }, err => {
      console.log(err);
    });
  }

  openViewDocDialog(link: string) {
    this.dialog.open(ViewDocumentComponent, {
      // width: '70%',
      // maxWidth: '80%',
      data: {
        link
      }
    });
  }

  getDocName(filePath: string) {
    const array = filePath.split('/');
    return array[array?.length - 1];
  }
}
