<div>WILL BE DELETED IN FUTURE</div>
<div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px" class="report-dialog-container">

    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px" fxFill>
        <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="8em" class="patient-info">
            <div fxFlex="30" fxLayout fxLayoutAlign="start start" fxLayoutGap="10px">
                <span>Patient ID:</span>
                <span>{{study.patient.patientID}}</span>
            </div>
            <div fxFlex="30">{{study.patient.name}}</div>
            <div fxFlex>{{study.modality}} {{study.description}}</div>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="2em">    
        <div fxFlex="15" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
            <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="5px" class="patient-history-panel">
                <div class="puru-label">History</div>
                <div>{{study.patientHistory}}</div>
            </div>
            <h4>Template Suggestions</h4>
            <mat-form-field tabindex="-1">
                <mat-label>Number</mat-label>
                <input type="text" placeholder="Pick one" matInput [formControl]="templateNameFC" #tName [matAutocomplete]="auto" >
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option" (focusout)="loadTemplate($event.target.textContent, true)">
                        {{option}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <mat-chip-list fxLayout="column" fxLayoutAlign="center center">
                <mat-chip color="primary" selected class="suggestion-chip" (click)="loadTemplate(rS)"
                    *ngFor="let rS of suitableTemplateName">{{rS}}</mat-chip>
            </mat-chip-list>
        </div>
        <div fxFlex fxLayout="column" fxLayoutAlign="start stretch" class="sample-report-container" fxLayoutGap="20px">


            <div fxLayout fxLayoutGap="5px" appDnd (fileDropped)="onFileDropped($event, study.studyID)"
                *ngIf="!files[study.studyID]" class="upload-btn-wrapper">
                <!-- <button mat-raised-button color="primary">
                <mat-icon>cloud_upload</mat-icon> Drop / Select
            </button> -->
                <input type="file" #fileDropRef id="fileDropRef" name="myfile" class="file-input-class"
                    (change)="fileBrowseHandler($event.target.files, study.studyID)" />

                <angular-editor [formControl]="templateContent" [config]="editorConfig"></angular-editor>
            </div>
            <div>
                <!-- <div fxLayout fxLayoutGap="5px" appDnd (fileDropped)="onFileDropped($event, study.studyID)"
                    *ngIf="!files[study.studyID]" class="upload-btn-wrapper">
                    <button mat-raised-button color="primary">
                        <mat-icon>cloud_upload</mat-icon> Drop / Select
                    </button>
                    <input type="file" #fileDropRef id="fileDropRef" name="myfile" class="file-input-class"
                        (change)="fileBrowseHandler($event.target.files, study.studyID)" />
                </div> -->

                <div fxLayout fxLayoutGap="5px" *ngIf="files[study.studyID]">
                    <img src="assets/images/dnd/ic-file.svg" alt="file" class="drag-drop-image">
                    <span>{{files[study.studyID]?.name}}</span>
                    <button mat-icon-button aria-label="Upload Button" color="primary" (click)="uploadReport(study)">
                        <mat-icon>cloud_upload</mat-icon>
                    </button>
                    <button mat-icon-button aria-label="Delete Button" (click)="deleteFile(study.studyID)" color="warn">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="20px">
                <button mat-stroked-button color="warn" (click)="closePanel()">Cancel</button>
                <button mat-raised-button color="primary" (click)="uploadReport(study)">Save</button>
            </div>
        </div>
    </div>
</div>




<!-- <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px" class="patient-history-panel" dir="rtl">
            <div>{{study.patientHistory}} : </div>
            <div class="puru-label">History</div>
        </div> -->

<!-- <mat-chip-list aria-label="Fish selection" fxLayout="column" fxLayoutAlign="center center">
                <mat-chip class="suggestion-chip" (click)="loadTemplate()">One</mat-chip>
                <mat-chip class="suggestion-chip"> Two</mat-chip>
                <mat-chip color="primary" selected class="suggestion-chip">Primary</mat-chip>
                <mat-chip color="accent" selected class="suggestion-chip">Accent</mat-chip>
            </mat-chip-list> -->