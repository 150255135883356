<div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px" class="puru-full-container">
    <!-- <ng-container *ngIf="hospitalListManagedByAgency?.length > 0">
        <mat-form-field appearance="fill">
          <mat-label>Select</mat-label>
          <mat-select [formControl]="hospitalFilterFormControl">
            <mat-option value="all">ALL</mat-option>
            <mat-option *ngFor="let instituteId of hospitalListManagedByAgency" [value]="instituteId">{{instituteId}}</mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container> -->
    <mat-tab-group class="full-length-tab-group">
        <mat-tab label="Pending">
            <app-pending-studies-for-agency-radiologist  [user]="userInSession"></app-pending-studies-for-agency-radiologist>
        </mat-tab>
        <mat-tab label="Completed">
          <app-completed-studies-for-agency-radiologist [user]="userInSession"></app-completed-studies-for-agency-radiologist>
        </mat-tab>
    </mat-tab-group>


</div>