import { Component, OnInit, Inject,SecurityContext } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularFireStorage } from '@angular/fire/storage';
import { DomSanitizer } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Report } from 'src/app/service/http/http-client.service';

export interface ReportData {
  report: Report[];
}
@Component({
  selector: 'app-view-report-dialog',
  templateUrl: './view-report-dialog.component.html',
  styleUrls: ['./view-report-dialog.component.css']
})
export class ViewReportDialogComponent implements OnInit {

  selected = new FormControl(0);
  fileUrl: string;
  mainUrl: string;
  isLoading = false;
  reportList: Report[];
  constructor(public dialogRef: MatDialogRef<ViewReportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ReportData,private storage: AngularFireStorage, public domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.reportList = this.data.report;
  }

  getDocName(filePath: string) {
    const array = filePath.split('/');
    return array[array?.length - 1];
  }

  getIcon(filePath: string) {
    let fileName = 'image-icon.png';
    if (filePath.endsWith('pdf')) {
      fileName = 'pdf-icon.png';
    } else if (filePath.endsWith('jpeg') || filePath.endsWith('jpg') || filePath.endsWith('png')) {
      fileName = 'image-icon.png';
    } else if (filePath.endsWith('doc') || filePath.endsWith('docx')) {
      fileName = 'word-icon.png';
    }

    return '/assets/images/' + fileName;

  }

  downloadTextContentFromFile() {
    let filePath: string = '';
    const fileRef = this.storage.ref(filePath);
    fileRef.getDownloadURL().subscribe(val => {
      console.log(val);
      window.open(val);
    }, err => {
      console.log(err);
    });
  }
  downloadFile(filePath: string) {
    
    const fileRef = this.storage.ref(filePath);
    fileRef.getDownloadURL().subscribe(val => {
      console.log(val);
      window.open(val);
    }, err => {
      console.log(err);
    });
  }

  previewFile(link: string) {
    this.isLoading = true;
    const fileRef = this.storage.ref(link);
    fileRef.getDownloadURL().subscribe(val => {
      console.log('[90]' + val);
      // window.open(val);
      this.fileUrl = val;
      if (!this.fileUrl){
        console.log('fileurl is nulll');
        return;
        
      }
      this.fileUrl = this.domSanitizer.sanitize(SecurityContext.URL, this.fileUrl);
      this.fileUrl = encodeURIComponent(this.fileUrl);
      this.mainUrl = 'https://docs.google.com/gview?url=' + this.fileUrl +'&embedded=true';
      // this.isLoading = false;
      return this.mainUrl;
    }, err => {
      console.log(err);
    });
  }
  
  // previewLocalFile(link: string) {
  //   // const fileRef = this.storage.ref(link);
  //   // fileRef.getDownloadURL().subscribe(val => {
  //     // console.log('[90]' + val);
  //     // window.open(val);

  //     this.fileUrl = 'http://'+ environment.serverIPDicom + ':' + environment.serverPortDicom + '/' + link;
  //     this.fileUrl = this.domSanitizer.sanitize(SecurityContext.URL, this.fileUrl);
  //     // this.fileUrl = encodeURIComponent(this.fileUrl);
  //     this.mainUrl = 'https://docs.google.com/gview?url=' + this.fileUrl +'&embedded=true';
  //     this.isLoading = false;
  //     return this.mainUrl;
  //   // }, err => {
  //     // console.log(err);
  //   // });
  // }

  tryAgain() {
    const selectedIndex = this.selected.value;
    console.log(selectedIndex);
    const link = this.reportList[selectedIndex].link;
    this.previewFile(link);
  }

  copy2(reporttext){
    reporttext.select();
    document.execCommand('copy');  
    reporttext.setSelectionRange(0, 0);  
  }

  // tryAgainForLocal() {
  //   const selectedIndex = this.selected.value;
  //   console.log(selectedIndex);
  //   // const link = this.reportList[selectedIndex].localLink;
  //   const link = 'report/CT_23125_23125.docx';
  //   this.previewLocalFile(link);
  // }

}
