import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { Report, Study, UserInFirebase } from 'src/app/service/http/http-client.service';
import { ViewDocumentComponent } from 'src/app/view-document/view-document.component';
import { environment } from 'src/environments/environment';
import { AgencyServiceService } from '../service/agency-service.service';
import { SecurityContext, Inject, AfterViewInit } from '@angular/core';
// import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { ViewReportDialogComponent } from 'src/app/src/app/home/completed-studies/view-report-dialog/view-report-dialog.component';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-agency-complete-studies',
  templateUrl: './agency-complete-studies.component.html',
  styleUrls: ['./agency-complete-studies.component.css']
})
export class AgencyCompleteStudiesComponent implements OnInit {

  hospitalFilterFormControl = new FormControl();
  studyList: Study[] = [];
  radiologistInAgency: UserInFirebase[];
  displayedColumns = ['Count', 'name', 'modality', 'hospital', 'referringPhysicianName', 'studyDateTime', 'PatientHistory', 'description', 'AttachedDocument', 'ViewReport'];
  dataSource: MatTableDataSource<any>;
  userEmail: string;
  agencyEmail: string;
  hospitalListManagedByAgency: string[];
  todayDate = new Date();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  startDateFormControl = new FormControl();
  endDateFormControl = new FormControl();
  searchBarFormControl = new FormControl();

  constructor(public agencyService: AgencyServiceService, private authService: AuthService, private domSanitizer: DomSanitizer, private firestore: AngularFirestore,
    public dialog: MatDialog) { 
      this.agencyEmail = authService.user.email;
      this.hospitalListManagedByAgency = agencyService.hospitalListManagedByAgency;
      this.display();
    }

  ngOnInit(): void {
  }

  onChangeHospitalFilter() {
    this.hospitalFilterFormControl.valueChanges.subscribe((val: string[]) => {
      // console.log(val);
      let filteredList: Study[] = [];
      if (val.length == 0) {
        this.updateDatasource();
        return;
      }

      for (const key in this.studyList) {
        if (Object.prototype.hasOwnProperty.call(this.studyList, key)) {
          const element = this.studyList[key];
          const index = val.indexOf(element.instituteId);
          if (index != -1) {
            filteredList.push(element);
          }
        }
      }
      this.updateDatasource(filteredList);
    })
  }


  // updateDatasource(studyList0?: Study[]) {
  //   let localStudyList: Study[] = studyList0 ? studyList0 : [];
  //   if (!studyList0) {
  //     for (const key in this.studyList) {
  //       if (Object.prototype.hasOwnProperty.call(this.studyList, key)) {
  //         const element = this.studyList[key];
  //         localStudyList.push(element);
  //       }
  //     }
  //   }
  //   this.dataSource = new MatTableDataSource(localStudyList);
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  // }

  // @Input('user')
  // set user(user: UserInFirebase) {
  //   console.log('UserEmailCame:[]');
  //   if (user) {
  //     // console.log(user.agencyHospitals[user.agencyID[0]]);
  //     this.userEmail = user.email;
  //     this.agencyEmail = user.agencyID[0];
  //     this.hospitalListManagedByAgency = user.agencyAndHospitals[this.agencyEmail];
  //     this.display();
  //   }

  // }

  display(startDateMS?: number, endDateMS?: number) {

    if (startDateMS) {
      const startDate: Date = (this.startDateFormControl.value).toDate();
      const endDate: Date = (this.endDateFormControl.value).toDate();
      endDate.setDate(endDate.getDate() + 1);

      startDateMS = startDate.getTime();
      endDateMS = endDate.getTime();
    }

    const endDate: Date = new Date();
    const startDate: Date = new Date();
    startDate.setDate(endDate.getDate() - 1);
    
    startDateMS = startDate.getTime();
    endDateMS = endDate.getTime();
    this.updateStudyListForRadiologist(startDateMS, endDateMS);

  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    // if (this.dataSource.paginator) {
    //   this.dataSource.paginator.firstPage();
    // }
  }

  private updateStudyListForRadiologist(startDateMS: number, endDateMS: number) {
    this.hospitalListManagedByAgency = this.agencyService.hospitalListManagedByAgency;
    this.hospitalListManagedByAgency?.forEach(hospitalName => {
      this.updateStudyListForRadiologist0(this.prepareCollectionName(hospitalName), startDateMS, endDateMS);
    });
    this.updateStudyListForRadiologist0('dev-btct-studies', startDateMS, endDateMS);

  }

  private updateStudyListForRadiologist0(collectionName: string, startDateMS: number, endDateMS: number) {
    // console.log('[service]-Going to-updateStudyList():[' + collectionName + '], startMS: ' + startDateMS + '; endDate: ' + endDateMS + '; agencyEmail:' + this.agencyEmail);

    try {
      this.firestore.collection<Study>(collectionName,
        ref => ref.where('reportingAgency', '==', this.agencyEmail)
                    .where('reportArrived', '==', true)
                      .where('studyDateTime', '>', startDateMS).where('studyDateTime', '<=', endDateMS)
                        .orderBy('studyDateTime')).valueChanges().subscribe(val => {
            // console.log(val.length);
            val.forEach(element => {
              this.studyList[element.studyInstanceUID] = element;
            });
            this.updateDatasource();
          });
    } catch (error) {
      console.error(collectionName)
      console.error(error);
    }


  }

  updateDatasource(studyList0?: Study[]) {
    // const x: IterableIterator<Study> = this.studyList.values();
    // console.log(localStudyList);

    // this.count = Object.keys(this.studyList).length
    let localStudyList: Study[] = studyList0 ? studyList0 : [];
    if (!studyList0) {
      for (const key in this.studyList) {
        if (Object.prototype.hasOwnProperty.call(this.studyList, key)) {
          const element = this.studyList[key];
          localStudyList.push(element);
        }
      }
    }
    this.dataSource = new MatTableDataSource(localStudyList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  prepareCollectionName(insName: string): string {
    if (insName === 'DEV' || insName === 'BTCT')
      return 'dev-btct-studies';
    return 'prod-' + insName.toLowerCase() + '-studies';
  }


  getIcon(filePath: string) {
    let fileName = 'image-icon.png';
    if (filePath.endsWith('pdf')) {
      fileName = 'pdf-icon.png';
    } else if (filePath.endsWith('jpeg') || filePath.endsWith('jpg') || filePath.endsWith('png')) {
      fileName = 'image-icon.png';
    } else if (filePath.endsWith('doc') || filePath.endsWith('docx')) {
      fileName = 'word-icon.png';
    }

    return '/assets/images/' + fileName;

  }

  // downloadFile(filePath: string) {
  //   const fileRef = this.storage.ref(filePath);
  //   fileRef.getDownloadURL().subscribe(val => {
  //     console.log(val);
  //     window.open(val);
  //   }, err => {
  //     console.log(err);
  //   });
  // }

  openViewDocDialog(link: string) {
    this.dialog.open(ViewDocumentComponent, {
      // width: '70%',
      // maxWidth: '80%',
      data: {
        link
      }
    });
  }

  openReportDialog(report: Report[]) {
    this.dialog.open(ViewReportDialogComponent, {
      data: {
        report
      }
    });
  }

  getUrlForStudy(s: Study) {
    const datasetName = s.dataSet ? s.dataSet : 'dev2-dataset-dicom-btct';
    const datastoreName = s.dataStore ? s.dataStore : 'dev2-datastore-dicom-btct';
    const location = s.region ? s.region : 'asia-southeast1';
    const baseUrlHCAPI = environment.baseUrlHCAPI;
    const projectId = s.projectId ? s.projectId : 'puru-255206';
    const baseUrl = baseUrlHCAPI + '/projects/' + projectId + '/locations/' + location + '/datasets/'
      + datasetName + '/dicomStores/' + datastoreName + '/dicomWeb" -r "studyUID=';
    const studyID = s.studyInstanceUID;
    const authToken = localStorage.getItem('at');
    const corePlainUrl: string = '$dicom:rs --url "' + baseUrl + studyID + '"' + ' -H "Authorization: Bearer ' + authToken + '"';

    const coreEncodedUrl0: string = encodeURIComponent(corePlainUrl);
    const coreEncodedUrl = this.domSanitizer.sanitize(SecurityContext.URL, coreEncodedUrl0);
    const unsafeUrl = 'weasis://' + coreEncodedUrl;
    window.open(unsafeUrl);

  }

  getDocName(filePath: string) {
    const array = filePath.split('/');
    return array[array?.length - 1];
  }
}
