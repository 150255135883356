import { Component, OnInit } from '@angular/core';
import { UserInFirebase } from 'src/app/service/http/http-client.service';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.css']
})
export class UserDashboardComponent implements OnInit {

  selectedUserInFirebase: UserInFirebase;
  constructor() { }

  ngOnInit(): void {
  }

  captureUserInFirebaseSelection(UserInFirebase: UserInFirebase) {
    this.selectedUserInFirebase = UserInFirebase;
  }

}
