import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from './../auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { UserInFirebase } from '../service/http/http-client.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  showFiller = false;
  actualUser: any;
  userInSession: UserInFirebase;
  instituteIDInSession: string;
  isAgam = false;


  constructor(public auth: AngularFireAuth, public authService: AuthService, public firestore: AngularFirestore, public storage: AngularFireStorage) {
  }

  ngOnInit(): void {
    this.auth.onIdTokenChanged((user) => {
      // console.log(user);
      this.actualUser = user;
    });

    this.auth.user.subscribe(val => {
      if (!val?.displayName) {
        this.userInSession = null;
        return;
      }
      const userEmail = val?.email;

      this.isAgam = val?.email.includes('agam');

      //TODO: Change value change to get()
      this.firestore.collection<UserInFirebase>('users', ref => ref.where('email', '==', val.email)).valueChanges().subscribe(val2 => {
        this.userInSession = val2[0] as UserInFirebase;

        if (this.userInSession?.roleList['radiologist'] || this.userInSession.roleList?.length > 1) {
          // this.loadStudies(firestore, this.userInSession.instituteID);
        } else {
          this.instituteIDInSession = this.userInSession.instituteID[0];
        }
      });

    });
  }

  downloadFile() {
    // gs://puru-255206.appspot.com/viewer/weasis-windows-6.0.4.zip
    const filePath = 'viewer/weasis-windows-6.0.7.zip';
    const fileRef = this.storage.ref(filePath);
    fileRef.getDownloadURL().subscribe(val => {
      // console.log(val);
      window.open(val);
    }, err => {
      console.log(err);
    });

  }
}
