<div class="parent-div mat-elevation-z8" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="15px">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" fxFill>
        <div fxFlex="40" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
            <div fxFlex="15" class="filter-label uppercase txt-off-black bold-500 font-12">
                HOSPITAL(S)
            </div>
            <mat-checkbox class="checkbox-div" fxFlex="15"
                [checked]="allComplete" [color]="task.color" [indeterminate]="someComplete()" (change)="setAll($event.checked)"
                    fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">

                <div fxFlex="70" class="checkbox-label">
                    {{task.name}}
                </div>
                <div class="count-icon">
                        {{dataSource?.data?.length}}
                </div>
            </mat-checkbox>

            <div class="checkbox-div" fxFill fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">

                <mat-checkbox *ngFor="let subtask of task.subtasks" [(ngModel)]="subtask.completed" [color]="subtask.color" (ngModelChange)="updateAllComplete()"
                    fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <div fxFlex="70" class="checkbox-label">
                        {{subtask.name}}
                    </div>
                    <div class="count-icon light-blue-bg">
                            {{instituteID_studyCount[subtask.name] ? instituteID_studyCount[subtask.name] : 0}}
                    </div>
                </mat-checkbox>

            </div>
        </div>
    </div>
    <div fxFill>
        <div class="table-div">
            <table mat-table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="Count">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
                    <td mat-cell *matCellDef="let element;let j = index"> {{j+1}}</td>
                </ng-container>

                <ng-container matColumnDef="Name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                    <td mat-cell *matCellDef="let element">

                        <button mat-button (click)="radiologistService.getUrlForStudy(element)" matTooltip="Click to View"
                            [ngClass]="{'opened': element.studyUnderObservation}">
                            {{element.patient.name}}
                        </button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="modality">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Mod. </th>
                    <td mat-cell *matCellDef="let element"> {{element.modality}} </td>
                </ng-container>

                <ng-container matColumnDef="instituteId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        <mat-form-field appearance="none" class="header-matformfeild-select">
                            <mat-label>Hospital</mat-label>
                            <mat-select class="puru-mat-select">
                                <mat-option>
                                    <mat-checkbox class="example-margin" [checked]="allComplete" [color]="task.color"
                                        [indeterminate]="someComplete()" (change)="setAll($event.checked)">
                                        {{task.name}}
                                    </mat-checkbox>
                                </mat-option>
                                <mat-option *ngFor="let subtask of task.subtasks" fxLayout="row" fxLayoutAlign="start center">
                                    <mat-checkbox [(ngModel)]="subtask.completed" [color]="subtask.color"
                                        (ngModelChange)="updateAllComplete()">
                                        {{subtask.name}}
                                    </mat-checkbox>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.instituteId}} </td>
                </ng-container>

                <ng-container matColumnDef="view-in-browser">
                    <th mat-header-cell *matHeaderCellDef> View </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button color="primary" (click)="viewInBrowser(element)" name="view-in-browser-button" id="viewInBrowser">
                            <mat-icon>play_arrow</mat-icon>
                        </button>
                    </td>
                </ng-container>
                
                <ng-container matColumnDef="reportDialog">
                    <th mat-header-cell *matHeaderCellDef> Report </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button color="primary" (click)="openReportPanel(element)" name="openReportPanel" id="openReportPanel">
                            <mat-icon>app_registration</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="StudyDesc">
                    <th mat-header-cell *matHeaderCellDef> Study Desc </th>
                    <td mat-cell *matCellDef="let element"> {{element.description}} </td>
                </ng-container>

                <ng-container matColumnDef="studyDateTime">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Study Time </th>
                    <td mat-cell *matCellDef="let element"> {{getStudyDateTime(element.studyDateTime) | date:'d MMM, hh:mm
                        a'}} </td>
                </ng-container>

                <ng-container matColumnDef="refdoc">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Ref. Physician </th>
                    <td mat-cell *matCellDef="let element"> {{element.referringPhysicianName === 'null' ? '':
                        element.referringPhysicianName}} </td>
                </ng-container>

                <ng-container matColumnDef="totalImages">
                    <th mat-header-cell *matHeaderCellDef> I# </th>
                    <td mat-cell *matCellDef="let element"> {{element.onlineImageCount}} </td>
                </ng-container>

                <ng-container matColumnDef="PatientHistory">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> History </th>
                    <td mat-cell *matCellDef="let element" matTooltip="{{element.patientHistory}}">
                        {{element.patientHistory}} </td>
                </ng-container>

                <ng-container matColumnDef="AttachedDocument">
                    <th mat-header-cell *matHeaderCellDef> Document </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngFor="let doc of element.additionalDocument">
                            <span (click)="openViewDocDialog(doc)">
                                <img class="image-icon" src="{{getIcon(doc)}}" alt="Avatar" class="image"
                                    matTooltip="{{getDocName(doc)}}">
                            </span>
                        </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Options">
                    <th mat-header-cell *matHeaderCellDef> Offline </th>
                    <td mat-cell *matCellDef="let row">

                        <!-- 1. add empty report -->

                    </td>
                </ng-container>

                <ng-container matColumnDef="ReportCount">
                    <th mat-header-cell *matHeaderCellDef> Report # </th>
                    <td mat-cell *matCellDef="let row">
                        {{row.report ? row.report.length : 0}} /{{row.expectedReportCount}}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                    [ngClass]="{'opened': row.studyUnderObservation, 'stillfresh': !row.studyUnderObservation}"></tr>
            </table>
        </div>
        <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10px" class="row-count-div">
            <div>
                Total {{dataSource ? dataSource?.data?.length : 0}} rows
            </div>
        </div>
    </div>
</div>
