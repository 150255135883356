import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
// import auth from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/auth";
// import  { User }  from 'firebase';
import firebase from 'firebase/app';
import { Observable, Subject } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: any;
  aToken: string;
  // credentialData: any;

  user$!: Observable<any>;


  private subjectService = new Subject<string>();

  constructor(public afAuth: AngularFireAuth, public router: Router) {

    this.user$ = afAuth.authState;

    
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.user = user;
        localStorage.setItem('user', JSON.stringify(this.user));
      } else {}
    });
  
    // this.afAuth.getRedirectResult().then(result => {
      // const credential = result.credential as firebase.auth.OAuthCredential;
      // this.aToken = credential?.accessToken;
      // this.credentialData = result?.credential;
      // if (this.aToken){
      //   localStorage.setItem('at', this.aToken);
      // }
      // this.router.navigate(['home']);
    // });
  
  }

  async logout() {
    await this.afAuth.signOut();
    localStorage.removeItem('user');
    this.router.navigate(['login']);
  }

  // get isLoggedIn(): boolean {
  //   const user = JSON.parse(localStorage.getItem('user'));
  //   return user !== null;
  // }

  async login() {
    const provider = new firebase.auth.GoogleAuthProvider();
    await this.afAuth.signInWithPopup(provider).then(result => {
      this.afAuth.setPersistence('session');
      const credential = result.credential as firebase.auth.OAuthCredential;
      this.aToken = credential.accessToken;
      // this.credentialData /= result.credential;
      localStorage.setItem('at', this.aToken);
      this.router.navigate(['home']);
    });
  }

  async login2(username: string, password: string) {
    await this.afAuth.signInWithEmailAndPassword(username, password).then(result => {
      this.afAuth.setPersistence('session');
      const credential = result.credential as firebase.auth.OAuthCredential;
      // this.aToken = credential.accessToken;
      // this.credentialData /= result.credential;
      // localStorage.setItem('at', this.aToken);
      this.router.navigate(['home']);
    });
  }




  // getCredentialData() {
  //   return this.credentialData;
  // }

  

}

