import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-view-report-new',
  templateUrl: './view-report-new.component.html',
  styleUrls: ['./view-report-new.component.css']
})
export class ViewReportNewComponent implements OnInit {

  
  @Output() needToCloseEvent = new EventEmitter<boolean>(); ;
  constructor() { }

  ngOnInit(): void {
  }

  closePanel(){
    this.needToCloseEvent.emit(true);
    // this.templateContent.reset();
  }

}
