<mat-card fxLayout="column" fxLayoutAlign="space-around center" fxLayoutGap="10px" *ngIf="!isWaiting">
  <mat-spinner *ngIf="isWaiting"></mat-spinner>
  <mat-card-content>
    <!-- <div> -->
      <!-- WRITE DOWN PATIENT HISTORY -->
      <!-- <mat-form-field class="example-full-width">
        <mat-label>Patient History</mat-label>
        <textarea matInput #message [formControl]="patientHistoryFormControl"></textarea>
        <mat-hint align="start"><strong>Please write clear history</strong> </mat-hint>
        <mat-hint align="end">{{message.value.length}} / 256</mat-hint>
      </mat-form-field> -->
    <!-- </div> -->
    <div>
      <!--UPLOAD ADDITION REPORT  -->
      <div class="container" appDnd (fileDropped)="onFileDropped($event)">
        <input type="file" #fileDropRef id="fileDropRef" multiple
          (change)="fileBrowseHandler($event.target.files)" accept=".dcm"/>
        <img src="assets/images/dnd/ic-upload-file.svg" alt="">
        <h3>Drag and drop your DICOM file here</h3>
        <h3>or</h3>
        <label for="fileDropRef">Browse for file</label>
      </div>
      <div class="files-list">
        <div class="single-file" *ngFor="let file of files; let i = index">
          <img src="assets/images/dnd/ic-file.svg" width="45px" alt="file">
          <div class="info">
            <h4 class="name">
              {{ file?.name }}
            </h4>
            <p class="size">
              <!-- {{ formatBytes(file?.size, null) }} -->
            </p>
            <!-- <div [progress]="file?.progress"></div> -->
          </div>
          <img src="assets/images/dnd/ic-delete-file.svg" class="delete" width="20px" alt="file"
            (click)="deleteFile(i)">
        </div>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button (click)="sendFilesToParent()">Upload</button>
    <!-- <button mat-button (click)="uploadStudy()">Send</button> -->
  </mat-card-actions>
</mat-card>
