<div>

    <div fxLayout fxLayoutAlign="start start" fxLayoutGap="5px">

        <div fxFlex="20">
            <div class="mat-elevation-z8 nscroll-100">
                <table mat-table [dataSource]="dataSource" matSort>

                    <!-- ID Column -->
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <mat-form-field>
                                <mat-label>Filter</mat-label>
                                <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Brain" #input>
                            </mat-form-field>
                            <button mat-raised-button color="primary" (click)="selectTemplate()">Add</button>
                            <button mat-raised-button color="primary" (click)="forceUpdate()">Update</button>
                        </th>
                        <td mat-cell *matCellDef="let row" (click)="selectTemplate(row)" class="table-row-with-card">
                            <mat-card fxLayout="column" appMaterialElevation fxLayoutAlign="space-between stretch"
                                fxLayoutGap="1px" class="table-row-card"
                                [ngClass]="{'selected-card': selectedTemplate === row}">
                                {{row}}
                            </mat-card>
                        </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    <!-- Row shown when there is no matching data. -->
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">No data matching the filter</td>
                    </tr>
                </table>
                <mat-paginator [pageSizeOptions]="[10, 25, 100]" #paginator></mat-paginator>
            </div>
        </div>
        <div fxFlex="80">
            <div fxLayout="column" fxLayoutAlign="start stretch" class="sample-report-container" fxLayoutGap="20px">
                <div fxLayout fxLayoutGap="10px">

                    <div fxLayout fxLayoutGap="10px" fxFlex="20">
                        <!-- <img class="d_icon" src="./assets/icons/icon-taxtype.png" fxFlex="20%"> -->
                        <mat-form-field fxFlex="80%" class="amt_input">
                            <mat-label>Modality</mat-label>
                            <mat-select [formControl]="modalityFC">
                                <mat-option value="CT">CT Scan</mat-option>
                                <mat-option value="MR">MRI</mat-option>
                                <mat-option value="CR">X Ray</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>


                    <div fxLayout fxLayoutGap="10px" fxFlex="80">
                        <img class="s_icon" src="./assets/icon/sample-report1.png">
                        <div fxFlex="15" class="template-name-label">Template Name:</div>
                        <mat-form-field fxFlex>
                            <input [formControl]="reportTemplateName" matInput>
                        </mat-form-field>
                    </div>
                </div>
                <angular-editor [formControl]="templateContent" [config]="editorConfig"></angular-editor>
                <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="20px">
                    <button mat-stroked-button color="warn" (click)="submit(true)">Delete</button>
                    <button mat-stroked-button color="warn">Cancel</button>
                    <button mat-raised-button color="primary" (click)="submit()">{{ isEditMode ? 'Edit':
                        'Save'}}</button>
                </div>
            </div>
        </div>

    </div>


</div>






<!-- {{templateContent.value}} -->
