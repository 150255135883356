import { Component, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import 'firebase/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
// import auth from 'firebase/app';
import { Observable } from 'rxjs';
// import { AngularFireStorage } from '@angular/fire/storage';
// import { DomSanitizer } from '@angular/platform-browser';
// import { AsyncPipe } from '@angular/common';
import { environment } from 'src/environments/environment';
// import { AuthService } from '../auth/auth.service';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Study, UserInFirebase } from '../service/http/http-client.service';
import { MatDrawer } from '@angular/material/sidenav';

export class Patient {
  constructor(
    public pkTBLPatientID: number,
    public patientID: string,
    public patientName: string,
    public patientBirthday: Date,
    public patientAge: string,
    public createdDate: Date,
    public modifiedDate: Date,
    public study: StudyInHomeController[]
  ) { }
}

export class StudyInHomeController {
  constructor(
    public pkTBLStudyID: number,
    public studyID: string,
    public studyDescription: string,
    public studyInstanceUID: string,
    public accessionNumber: string,
    public modality: string,
    public studyDateTime: Date,
    public referringPhysicianName: string,
    public additionalPatientHistory: string,
    public admittingDiagnosesDescription: string,
    public studyStatusID: string,
    public studyPriorityID: string,
    public createdDate: Date,
    public modifiedDate: Date,
    public totalImages: number,
    public publishedToDoctor: boolean,
    public reportArrived: number,
    public hospitalId: string,
    public updatedBy: number,
    public patient: Patient,
    public additionalDocument: string[],
    public patientHistory: string,
    public studySendTime: Date,
    public report: Report[],
    public projectID: string,
    public region: string,
    public dataSet: string,
    public dataStore: string,
  ) { }
}

export class Report {
  constructor(
    public text: string,
    public timestamp: Date
  ) { }
}

export class AdditionDocument {
  constructor(
    public downloadLink: string
  ) { }
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  title = 'PuruOnline';
  // pendingStudyList: Observable<Study[]>;
  // completedStudyList: Observable<Study[]>;
  pendingStudyList: StudyInHomeController[] = [];
  completedStudyList: StudyInHomeController[] = [];
  // aToken: string;
  test: any;

  isConsultant = false;
  isAgency = false;
  instituteFormControl = new FormControl();

  userInSession: UserInFirebase;
  instituteIdList: string[];
  doctorsEmailsOfInstitute: string[];
  collectionName = 'c-o-l-l-e-c-t-i-o-n-s'


  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  isRadiologist: any;
  isObserver: any;
  isTechnician: any;

  studyCameForReport: Study;
  studyCameForViewReport: Study;

  templateNames: string[] = [];
  templateMap: string[] = [];
  
  @ViewChild('drawer') drawer: MatDrawer;
  constructor(public firestore: AngularFirestore, public auth: AngularFireAuth, public router: Router) {
    auth.user.subscribe(val => {
      if (!val?.displayName) {
        this.userInSession = null;
        return;
      }
      this.handleUserEmailForHomeComponent(val.email);  
    });
  }

  private handleUserEmailForHomeComponent(email: string) {
    this.firestore.collection<UserInFirebase>('users', ref => ref.where('email', '==', email)).get().subscribe((val3) => {
      val3.docs.forEach((doc) => {
        this.userInSession = doc.data() as UserInFirebase;
        this.afterUserLoggedIn();
      });
    });
  }

  private afterUserLoggedIn() {
    this.isConsultant = this.userInSession.roleList['consultant'];
    this.isRadiologist = this.userInSession.roleList['radiologist'];
    this.isObserver = this.userInSession.roleList['observer'];
    this.isTechnician = this.userInSession.roleList['technician'];
    this.isAgency = this.userInSession.roleList['agency'];
    const isRelatedToHospital = this.isConsultant || this.isRadiologist || this.isTechnician || this.isObserver;
    if (this.isAgency)
      this.router.navigate(['agency']);
    if (this.userInSession.agencyID && !isRelatedToHospital)
      this.router.navigate(['aradiologist']);

    this.instituteIdList = this.userInSession.instituteID;
    this.instituteFormControl.setValue(this.instituteIdList[0]);

    if (this.userInSession.roleList['radiologist'] || this.userInSession?.roleList?.length > 1) {
      // this.loadStudies(firestore, this.userInSession.instituteID);
    } else {
      this.instituteIdList = this.userInSession.instituteID;
    }
  }

  refreshPage() {
    window.location.reload();
  }

  ngOnInit(): void {
    this.onChangeInstitute();
  }

  onChangeInstitute() {
    this.instituteFormControl.valueChanges.subscribe((val: string) => {
      // console.log('changedTo: ' + val);
      const env = environment.production ? 'prod' : 'dev';
      this.collectionName = env + '-' + val.toLowerCase() + '-' + 'studies';
    })
  }

  openReportPanel(study: Study, isConsultant?: boolean) {
    // console.log('Study Came for reporting...');
    // console.log(study/);
    study.isConsultant = isConsultant;
    this.studyCameForReport = study;
    this.drawer.open();
  }

  closePanel(needToClose: boolean) {
    // console.log('Home-closePanel' + needToClose);
    if (needToClose) this.drawer.close();
  }
}

