import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
// import { StudyInHomeController } from './../home.component';
import { SecurityContext, ViewChild, AfterViewInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/auth.service';
// import { DataSource } from '@angular/cdk/table';
import { AngularFirestore} from '@angular/fire/firestore';
// import { StudyService } from 'src/app/service/study-service.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Study, HttpClientService, TT, UserInFirebase, Report } from 'src/app/service/http/http-client.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ViewDocumentComponent } from 'src/app/view-document/view-document.component';
import { MatDialog } from '@angular/material/dialog';
import auth from 'firebase';
import firestore from 'firebase/app';
import { Subscription, interval } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { ViewReportDialogComponent } from 'src/app/src/app/home/completed-studies/view-report-dialog/view-report-dialog.component';

@Component({
  selector: 'app-completed-studies-for-agency-radiologist',
  templateUrl: './completed-studies-for-agency-radiologist.component.html',
  styleUrls: ['./completed-studies-for-agency-radiologist.component.css']
})
export class CompletedStudiesForAgencyRadiologistComponent implements OnInit {

  userEmail: string;
  agencyEmail: string;
  hospitalListManagedByAgency: any;
  studyList: Study[] = [];
  dataSource: MatTableDataSource<any>;
  displayedColumns = ['Count', 'name', 'modality', 'referringPhysicianName','studyDateTime', 'PatientHistory', 'description', 'AttachedDocument', 'ViewReport'];
 
  startDateFormControl = new FormControl();
  endDateFormControl = new FormControl();
  searchBarFormControl = new FormControl();
  
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(private storage: AngularFireStorage, private firestore: AngularFirestore,
    private domSanitizer: DomSanitizer, private authService: AuthService, private snackBar: MatSnackBar, private httpClient: HttpClientService,
    public dialog: MatDialog) { }

  @Input('user')
  set user(user: UserInFirebase){
    // console.log('UserEmailCame:[]');
    if (user){
      // console.log(user.agencyHospitals[user.agencyID[0]]);
      this.userEmail = user.email;
      this.agencyEmail = user.agencyID[0];
      this.hospitalListManagedByAgency = user.agencyAndHospitals[this.agencyEmail];
      this.updateStudyListForRadiologist();
    }
    
  }
  
  ngOnInit(): void {
  }


  display(){

  }
  
  private updateStudyListForRadiologist() {

    this.hospitalListManagedByAgency.forEach(hospitalName => {
      this.updateStudyListForRadiologist0(this.prepareCollectionName(hospitalName));
    });
    this.updateStudyListForRadiologist0('dev2-btct-studies');

  }

  private updateStudyListForRadiologist0(collectionName: string) {
    // console.log('[service]-Going to-updateStudyList():[' + collectionName + ']');

    //TODO: Change value change to get()
    this.firestore.collection<Study>(collectionName,
      ref => ref.where('assignedRadiologist', '==', this.userEmail).where('reportArrived', '==', true).orderBy('studyDateTime')).valueChanges().subscribe(val => {
        // console.log(val);
        val.forEach(element => {
          this.studyList[element.studyInstanceUID] = element;
        });
        this.updateDatasource();
      });


  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    // if (this.dataSource.paginator) {
    //   this.dataSource.paginator.firstPage();
    // }
  }

  openReportDialog(report: Report[]) {
    this.dialog.open(ViewReportDialogComponent, {
      data: {
        report
      }
    });
  }
  
  updateDatasource(studyList0?: Study[]) {
    // const x: IterableIterator<Study> = this.studyList.values();
    // console.log(localStudyList);

    // this.count = Object.keys(this.studyList).length
    let localStudyList: Study[] = studyList0 ? studyList0 : [];
    if (!studyList0) {
      for (const key in this.studyList) {
        if (Object.prototype.hasOwnProperty.call(this.studyList, key)) {
          const element = this.studyList[key];
          localStudyList.push(element);
        }
      }
    }
    this.dataSource = new MatTableDataSource(localStudyList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  prepareCollectionName(insName: string): string {
    if (insName === 'DEV' || insName === 'BTCT')
      return 'dev-btct-studies';
    return 'prod-' + insName.toLowerCase() + '-studies';
  }


  getIcon(filePath: string) {
    let fileName = 'image-icon.png';
    if (filePath.endsWith('pdf')) {
      fileName = 'pdf-icon.png';
    } else if (filePath.endsWith('jpeg') || filePath.endsWith('jpg') || filePath.endsWith('png')) {
      fileName = 'image-icon.png';
    } else if (filePath.endsWith('doc') || filePath.endsWith('docx')) {
      fileName = 'word-icon.png';
    }

    return '/assets/images/' + fileName;

  }

  downloadFile(filePath: string) {
    const fileRef = this.storage.ref(filePath);
    fileRef.getDownloadURL().subscribe(val => {
      // console.log(val);
      window.open(val);
    }, err => {
      console.log(err);
    });
  }

  openViewDocDialog(link: string) {
    this.dialog.open(ViewDocumentComponent, {
      // width: '70%',
      // maxWidth: '80%',
      data: {
        link
      }
    });
  }

  getUrlForStudy(s: Study) {
    const datasetName = s.dataSet ? s.dataSet : 'dev2-dataset-dicom-btct';
    const datastoreName = s.dataStore ? s.dataStore : 'dev2-datastore-dicom-btct';
    const location = s.region ? s.region : 'asia-southeast1';
    const baseUrlHCAPI = environment.baseUrlHCAPI;
    const projectId = s.projectId ? s.projectId : 'puru-255206';
    const baseUrl = baseUrlHCAPI + '/projects/' + projectId + '/locations/' + location + '/datasets/'
      + datasetName + '/dicomStores/' + datastoreName + '/dicomWeb" -r "studyUID=';
    const studyID = s.studyInstanceUID;
    const authToken = localStorage.getItem('at');
    const corePlainUrl: string = '$dicom:rs --url "' + baseUrl + studyID + '"' + ' -H "Authorization: Bearer ' + authToken + '"';

    const coreEncodedUrl0: string = encodeURIComponent(corePlainUrl);
    const coreEncodedUrl = this.domSanitizer.sanitize(SecurityContext.URL, coreEncodedUrl0);
    const unsafeUrl = 'weasis://' + coreEncodedUrl;
    window.open(unsafeUrl);

  }

  getDocName(filePath: string) {
    const array = filePath.split('/');
    return array[array?.length - 1];
  }

}
