import { DomSanitizer } from '@angular/platform-browser';
// import { StudyInHomeController } from './../home.component';
import { Component, OnInit, Input, SecurityContext, ViewChild, AfterViewInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/auth.service';
// import { DataSource } from '@angular/cdk/table';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
// import { StudyService } from 'src/app/service/study-service.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Study, HttpClientService, TT } from 'src/app/service/http/http-client.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ViewDocumentComponent } from 'src/app/view-document/view-document.component';
import { MatDialog } from '@angular/material/dialog';
// import auth from 'firebase';
// import firestore from 'firebase/app';

import firebase from 'firebase/app'; 
import 'firebase/firestore';

import { Subscription, interval } from 'rxjs';
import { take, map } from 'rxjs/operators';

@Component({
  selector: 'app-pending-studies',
  templateUrl: './pending-studies.component.html',
  styleUrls: ['./pending-studies.component.css']
})
export class PendingStudiesComponent implements AfterViewInit {

  @Input('instituteIdList') instituteIdList: string[];
  @Input() _collectionName: string;

  aToken: string;
  subscription: Subscription;
  offlineRequested: string;
  tokedUpdatedAt: any;

  // _pendingStudies: StudyInHomeController[];
  private studyCollection: AngularFirestoreCollection<Study>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = ['Count', 'Name', 'modality', 'instituteId', 'refdoc', 'studyDateTime', 'StudyDesc', 'totalImages', 'PatientHistory', 'AttachedDocument', 'Upload', 'Options'];
  dataSource: MatTableDataSource<any>;
  remainingTimeMin = 0;
  timeElaspsed = 0;
  constructor(private storage: AngularFireStorage, private afirestore: AngularFirestore,
    private domSanitizer: DomSanitizer, private authService: AuthService, private snackBar: MatSnackBar, private httpClient: HttpClientService,
    public dialog: MatDialog) { }

  @Input()
  set collectionName(collectionName: string) {
    this._collectionName = collectionName;
    // console.log('collectionName in ngAfterViewInit: ' + this._collectionName);
    try {
      this.studyCollection = this.afirestore.collection<Study>(this._collectionName);
      this.updateStudyList();
    } catch (error) {
      console.log(error);
      this.openSnackBar('Some problem with this institute, Please select another', null);
    }
  }

  ngAfterViewInit(): void {
    // console.log('collectionName in ngAfterViewInit: ' + this._collectionName);
    // this.studyCollection = this.afirestore.collection<Study>(this._collectionName);
    // const instituteIdList = ['BTCT'];

    this.afirestore.collection<TT>('users', ref=> ref.where('istt', '==', true)).valueChanges().subscribe(val => {
      // console.log('token changed' + val);
      this.aToken = val[0].at;
      localStorage.setItem('at', this.aToken);
      this.tokedUpdatedAt = val[0].time;
      // console.log('GetAccessTokenFrom-PendingComponent' + this.tokedUpdatedAt);
    });
    
    // this.updateStudyList();

    this.aToken = localStorage.getItem('at');
    if (this.aToken) {
      this.httpClient.tokenValidation(this.aToken).subscribe(val => {
        // console.log(val.access_type);
        // console.log(val.scope);
        if (val?.expires_in && val?.expires_in > 100) {
          // console.log('Token is valid for next:  ' + val.expires_in / 60 + ' min');
          this.remainingTimeMin = Math.round(val.expires_in / 60)
          const source = interval(60000);
          // this.subscription = source.subscribe(val => this.timeoutAutoSaveHandle());

          var timer = source.pipe(
            take(30),
          );
          timer.subscribe(val => this.timeoutAutoSaveHandle(val));
          this.openSnackBar('Token is valid for next:  ' + this.remainingTimeMin + ' min', null, 2000);
        } else {
          // console.log('Token is INVALID, login is required');
          this.openSnackBar('Token is INVALID, login is required', null, 2000);
          // this.authService.logout();
          // this.authService.login();
        }
      }, err => {
        console.error(err);
        // console.log('Token is INVALID, login is required');
        // this.openSnackBar('Token is INVALID, login is required', null, 2000);
        // this.authService.logout();
        // this.authService.login();
      });

    } else {
      console.log('Token is Null, login is required');
    }

  }

  oldNonAcceptedList: Study[] =[];
  lastDate: Date;
  public acceptAll(){
    // console.log('Going to-get acceptAll(): '+ this._collectionName);

    this.lastDate = new Date(2020, 12, 1);
    let startDateMS = this.lastDate.getTime();
    
    this.afirestore.collection<Study>(this._collectionName,
      ref => ref.where('accepted', '==', false).where('studyDateTime', '<=', startDateMS).orderBy('studyDateTime')).valueChanges().subscribe(val => {
        this.oldNonAcceptedList = val;
        this.dataSource = new MatTableDataSource(val);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }

  public acceptStudy(){
    // thi       

  }

  private updateStudyList() {
    // console.log('Going to-updateStudyList(): '+ this._collectionName);
    
    this.afirestore.collection<Study>(this._collectionName,
      // ref => ref.where('reportArrived', '==', false).where('reportingRadiologist', '==', this.authService.user.email).orderBy('studyDateTime')).valueChanges().subscribe(val => {
      ref => ref.where('reportArrived', '==', false).orderBy('studyDateTime')).valueChanges().subscribe(val => {
        if (this.offlineRequested) {
          for (let index = 0; index < val.length; index++) {
            const element = val[index];
            if (element.offlineAvailable && this.offlineRequested === element.studyInstanceUID) {
              this.openSnackBar('Downloading ...', null, 2000);
              this.downloadImagesZipFile(element);
              break;
            } else if (!element.offlineAvailable && this.offlineRequested === element.studyInstanceUID) {
              this.openSnackBar('Can not download, some error occurred', null, 2000);
              this.offlineRequested = null;
            }
          }
        }
        this.dataSource = new MatTableDataSource(val);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }

  sendToPubsub(study: Study) {
    this.offlineRequested = study.studyInstanceUID;
    // dev-btct-dicom-to-be-uploaded
    if (study.offlineAvailable){
      this.downloadImagesZipFile(study);
    } else {
      this.httpClient.postToPubSub(study.studyInstanceUID, this.aToken).subscribe(val => {
        this.openSnackBar('Study requested for offline access', null, 2000);
      }, err => {
        console.log(err);
      })
    }
  }

  downloadImagesZipFile(s: Study) {
    console.log('downloadImagesZipFile' + s.patient.name);
    
    // gs://puru-255206.appspot.com/viewer/weasis-windows-6.0.4.zip
    const insID = s.instituteId;
    // const filePath = insID + '/review_study/' + insID + s.patient.name+ '.zip';
    const filePath = insID + '/review_study/' + s.patient.patientID + '_'+ s.studyID + '.zip';
    console.log('downloading: ' + filePath);
    const fileRef = this.storage.ref(filePath);
    fileRef.getDownloadURL().subscribe(val => {
      this.offlineRequested = null;
      window.open(val);
    }, err => {
      console.log(err);
    });
  }


  timeoutAutoSaveHandle(val: any): void {
    // console.log('timeoutAutoSaveHandle():[ ' + val + '], RemainingTimeIn');
    this.timeElaspsed = val;
    if (val === (this.remainingTimeMin - 10)) {
      // this.authService.logout();
      // this.authService.login2();
    }

    // this.authService.relogin();
    // throw new Error("Method not implemented.");
  }

  relogin() {
  }

  // @Input()
  // set pendingStudies(pendingStudies: StudyInHomeController[]) {
  //   // this._pendingStudies = pendingStudies;
  // }

  // get pendingStudies(): StudyInHomeController[] {
  //   // return this._pendingStudies;
  // }

  openSnackBar(message: string, action: string, duration?: number) {
    if (!duration) {
      duration = 120000;
    }
    this.snackBar.open(message, action, {
      duration,
    });
  }

  refreshToken() {
    const refreshToken = localStorage.getItem('rt');
    console.log('Going for refreshingToken:[' + refreshToken + ']');
    this.httpClient.refreshToken(refreshToken).subscribe(val => {
      console.log(val);
    });
  }

  getIcon(filePath: string) {
    let fileName = 'image-icon.png';
    if (filePath.endsWith('pdf')) {
      fileName = 'pdf-icon.png';
    } else if (filePath.endsWith('jpeg') || filePath.endsWith('jpg') || filePath.endsWith('png')) {
      fileName = 'image-icon.png';
    } else if (filePath.endsWith('doc') || filePath.endsWith('docx')) {
      fileName = 'word-icon.png';
    }

    return '/assets/images/' + fileName;

  }

  downloadFile(filePath: string) {
    const fileRef = this.storage.ref(filePath);
    fileRef.getDownloadURL().subscribe(val => {
      console.log(val);
      window.open(val);
    }, err => {
      console.log(err);
    });
  }

  openViewDocDialog(link: string) {
    this.dialog.open(ViewDocumentComponent, {
      // width: '70%',
      // maxWidth: '80%',
      data: {
        link
      }
    });
  }

  getUrlForStudy(s: Study) {
    const datasetName = s.dataSet ? s.dataSet : 'dev2-dataset-dicom-btct';
    const datastoreName = s.dataStore ? s.dataStore : 'dev2-datastore-dicom-btct';
    const location = s.region ? s.region : 'asia-southeast1';
    const baseUrlHCAPI = environment.baseUrlHCAPI;
    const projectId = s.projectId ? s.projectId : 'puru-255206';
    const baseUrl = baseUrlHCAPI + '/projects/' + projectId + '/locations/' + location + '/datasets/'
      + datasetName + '/dicomStores/' + datastoreName + '/dicomWeb" -r "studyUID=';
    const studyID = s.studyInstanceUID;
    const authToken = localStorage.getItem('at');
    const corePlainUrl: string = '$dicom:rs --url "' + baseUrl + studyID + '"' + ' -H "Authorization: Bearer ' + authToken + '"';
    console.log(corePlainUrl);
    
    const coreEncodedUrl0: string = encodeURIComponent(corePlainUrl);
    const coreEncodedUrl = this.domSanitizer.sanitize(SecurityContext.URL, coreEncodedUrl0);
    const unsafeUrl = 'weasis://' + coreEncodedUrl;
    window.open(unsafeUrl);

  }

  getDocName(filePath: string) {
    const array = filePath.split('/');
    return array[array?.length - 1];
  }

  uploadReport(study: Study) {
    console.log('send file to storage and save path in db');
    const file = this.files[study.studyID];
    const insID = study.instituteId;
    const fileName = study.patient.name+ '_' +study.studyID + '_' + file.name;
    const filePath = '/' + insID + '/' + study.modality + '/'+ study.patient.patientID + '/' + study.studyID + '/report/' + fileName;
    // const filePath = '/' + 'BTCT' + '/' + study.modality + '/'+ study.patient.patientID + '/' + study.studyID + '/report/' + file.name;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    // this.downloadURLList.push(filePath);
    // console.log(filePath);

    this.afirestore.collection(this._collectionName).doc(study.patient.patientID + '-' + study.studyID).update({
    // this.studyCollection.doc(study.patient.patientID + '-' + study.studyID).update({
      reportArrived: true,
      reportDownloadedAtRt: false,
      report: firebase.firestore.FieldValue.arrayUnion({
        link: filePath,
        timestamp: new Date(),
        done_by: this.authService.user.email
      })
    });
    // done_by: this.authService.user.email

  }

  // getPath(study: Study){
  //   const insID = study.instituteId;
  //   // const fileName = study.patient.name+ '_' +study.studyID + '_' + file.name;
  //   const filePath = '/' + insID + '/' + study.modality + '/'+ study.patient.patientID + '/' + study.studyID + '/report/';
  //   window.open(filePath)

  // }
  ////////////////////////------FILE F:N------///////////////////////
  files: any[] = [];

  /**
   * on file drop handler
   */
  onFileDropped($event, studyID) {
    this.prepareFilesList($event, studyID);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files, studyID: string) {
    this.prepareFilesList(files, studyID);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(studyID: string) {
    //  this.files.splice(index, 1);
    delete this.files[studyID];
  }

  /**
   * Simulate the upload process
   */
  //  uploadFilesSimulator(index: number) {
  //    setTimeout(() => {
  //      if (index === this.files.length) {
  //        return;
  //      } else {
  //        const progressInterval = setInterval(() => {
  //          if (this.files[index]?.progress === 100) {
  //            clearInterval(progressInterval);
  //            this.uploadFilesSimulator(index + 1);
  //          } else {
  //            // this.files[index].progress += 5;
  //          }
  //        }, 200);
  //      }
  //    }, 1000);
  //  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */

  prepareFilesList(files: Array<any>, studyID: string) {
    for (const item of files) {
      item.progress = 0;
      //  this.files.push(item);
      this.files[studyID] = item;
    }
    //  this.uploadFilesSimulator(0);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  ////////////////////////////////////////////////////////////////

}