import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AngularFireFunctions } from '@angular/fire/functions';
import { environment } from 'src/environments/environment';

export class PathToCloud {
  constructor(
    public studyPath: string,
    public bucketName: string,
    public dicomStore: string,
    public datasetId: string,
    public cloudRegion: string,
  ) { }
}

export class Study {
  constructor(
    public studyID: string,
    public studyDescription: string,
    public description: string,
    public studyInstanceUID: string,
    public accessionNumber: string,
    public modality: string,
    public studyDateTime: Date,
    public referringPhysicianName: string,
    public additionalPatientHistory: string,
    public admittingDiagnosesDescription: string,
    public studyStatusID: string,
    public createdDate: Date,
    public modifiedDate: Date,
    public totalImages: number,
    public publishedToDoctor: string,
    public reportArrived: boolean,
    public instituteId: string,
    public updatedBy: number,
    public patient: Patient,
    public additionalDocument: string[],
    public patientHistory: string,
    public studySendTime: Date,
    public projectId: string,
    public region: string,
    public dataSet: string,
    public dataStore: string,
    public offlineAvailable: boolean,
    public assignedRadiologist: string,
    public assignedToRadiologist: boolean,
    public accepted: boolean,
    public report: Report[],
    public expectedReportCount: number,
    public reportNameList: string[],
    public studyUnderObservation: boolean,
    public reportDownloadedAtRt: boolean,
    public isConsultant?: boolean,
    public fbDocId?: string,
    public approachType?: number,
    public reportingRadiologist?: string
  ) { }
}

export class ReportSample {
    constructor(
      public id: string,
      public modality: string,
      public name: string,
      public text: string,
      public user: string,
      public docId: string
    ) { }
}

export class Institute {

  constructor(
    public id: string,
    public name: string,
    public address: string
  ) { }
}

// export class ImagingTechnician {
//   constructor(
//     public id: string,
//     public name: string,
//     public email: string,
//     public institute: Institute
//   ) { }
// }


// export class InstituteRadiologist {
//   constructor(
//     public remail: string,
//     public institute: string
//   ) { }
// }


// export class Radiologist {
//   constructor(
//     public id: string,
//     public name: string,
//     public email: string
//   ) { }
// }

export class Patient {
  constructor(
    public pkTBLPatientID: number,
    public patientID: string,
    public name: string,
    public patientBirthday: Date,
    public patientAge: string,
    public createdDate: Date,
    public modifiedDate: Date,
  ) { }
}

export interface UserInFirebase {
  name: string;
  email: string;
  roleList: string[];
  instituteID: string[];
  agencyID: string[];
  agencyHospitals: string[];
  agencyAndHospitals: string[];
  sampleEditedOn: number;
}

export class Report {
  constructor(
    public text: string,
    public link: string,
    public timestamp: Date,
    public mode: string,
    public name: string,
    //1-> Draft Saved //2-> Read Only  //3-> Final
    public submitStatus: number,
    public isEdited: number,
    public reportLater?: number,
    public templateUsed?: string
  ) { }
}

export enum SubmitStatusValues {
  DRAFT = 1,
  READ_ONLY = 2,
  FINAL = 3
}

export class GAuthValidationObject {
  constructor(
    public issued_to: string,
    public audience: string,
    public user_id: string,
    public scope: string,
    public expires_in: number,
    public email: string,
    public verified_email: boolean,
    public access_type: string,
  ) { }
}

export class RefreshRequestObject {
  constructor(
    public client_id: string,
    public client_secret: string,
    public refresh_token: string,
    public grant_type: string,
  ) { }
}
export class RefreshResponseObject {
  constructor(
    public expires_in: number,
    public access_token: string,
    public token_type: string,
  ) { }
}

export class ToPubSub {
  constructor(
    public data: string
  ) { }
}



export class Attributes {
  constructor(
  public key: string,
  public value: string
  ) { }
}

export class Message {
  constructor(
  public attributes: Attributes,
  public data: string
  ) { }
}

export class RootObject {
  constructor(
  public messages: Message[]
  ) { }
}

export class TT{
  constructor(
    public at:string,
    public time: number
  ) { }
}

export class Hospital {
  constructor(
   public name: string,
   public city: string,
   public address: string,
   public shortName: string,
   public propertyCluster: string,
   public email: string,
   public onlineAllowed: boolean,
   public autoUploadModality: string[],
   public serverIp: string,
   public dataLocation: string,
    public onlineApproachType: string  // 0 -> docId with patientId-StudyId, 1 -> docId with hopitalShortCode-patientId-StudyId
  ) { }
}

export class PropertyCluster {
  constructor(
    public name: string,
    public collectionName: string,
    public projectId: string,
    public region: string,
    public dataset: string,
    public datastore: string,
    public firestoreUrl: string,
    public bucketName: string
  ) { }
}



@Injectable({
  providedIn: 'root'
})
export class HttpClientService {
  data$: any;

  constructor(private httpClient: HttpClient, private fns: AngularFireFunctions) { }


  postToCloudFunction(path: string, bucketName: string, dataSet: string, dataStore: string, hcapiRegion: string) {
    // projects/puru-255206/locations/asia-southeast1/datasets/prod-dataset-dicom-xps
    // projects/puru-255206/locations/asia-southeast1/datasets/prod-dataset-dicom-xps/dicomStores/prod-datastore-dicom-xps
    const pathObject = new PathToCloud(path, bucketName, dataStore, dataSet, hcapiRegion);
    // const url = 'https://us-central1-puru-255206.cloudfunctions.net/upload_dicom_to_store';
    const url = 'https://asia-south1-puru-255206.cloudfunctions.net/upload_dicom_to_store_from_web';

    const jsonString: string = JSON.stringify(pathObject);
    // console.log(jsonString);
    // console.log(JSON.parse(jsonString));
    return this.httpClient.post(url, JSON.parse(jsonString));

    // const callable = this.fns.httpsCallable('upload_dicom_to_store');
    // this.data$ = callable({ path: path });
  }

  postToPubSub(path: string, token: string) {
    const m : Message = new Message(null, btoa(path));
    const r: RootObject = new RootObject([m]);

    // const pathObject = new ToPubSub(path);
    const url = 'https://pubsub.googleapis.com/v1/projects/puru-255206/topics/' + environment.offlineRequestTopic + ':publish';
    return this.httpClient.post(url, r, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        'Accept': '*/*',
      })
    })
  }


  tokenValidation(token: string) {
    return this.httpClient.get<GAuthValidationObject>('https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=' + token);
  }

  refreshToken(token: string) {
    const client_secret = '9Qk3W0zGczm2pkzT0bqEl4P_';
    const client_id = '766256023666-ho4m2r471b2e9dlboc0ie0c7gkno2gpp.apps.googleusercontent.com';
    const refresh_token = token;
    const o: RefreshRequestObject = new RefreshRequestObject(client_id, client_secret, refresh_token, 'refresh_token');
    return this.httpClient.post<RefreshResponseObject>('https://www.googleapis.com/oauth2/v4/token', o);
  }


  getCall(url: string) {
    // this.httpClient.get<string>(url {responseType: "text"})
    return this.httpClient.get(url, {responseType: "text"});
  }




}
