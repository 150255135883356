import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Study, UserInFirebase } from 'src/app/service/http/http-client.service';
import { AgencyServiceService } from '../service/agency-service.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-assigned-study',
  templateUrl: './assigned-study.component.html',
  styleUrls: ['./assigned-study.component.css']
})
export class AssignedStudyComponent implements OnInit {

  studyList: Study[] = [];
  radiologistInAgency: UserInFirebase[];
  radiologistInAgencyMap: UserInFirebase[] = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = ['Count', 'Name', 'AssignedRadiologist', 'modality', 'refdoc', 'studyDateTime', 'StudyDesc'];
  dataSource: MatTableDataSource<Study>;
  count: number;
  constructor(public agencyService: AgencyServiceService) { 

    this.agencyService.eventCallbackStudyAssigned$.subscribe((val:Study) => {
      // console.log('[component-2]: '+ val);
      // console.log(val);
      // this.studyList.push(val);
      this.studyList[val.studyInstanceUID] = val;
      this.updateDatasource();
    });

    this.agencyService.eventCallbackRadiologist$.subscribe((val: UserInFirebase[]) => {
      this.radiologistInAgency = val;
      this.radiologistInAgency.forEach(element => {
        this.radiologistInAgencyMap[element.email] = element;
      });
    });

  }
  ngOnInit(): void {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
if (isMobile){
  console.log('it is mobile');
  this.displayedColumns = ['mobileCol'];
} else {
  console.log('not a moblie');
  
}
  }

  @Input('insID')
  set filterInstitute(insId: string){
    console.log('Filter for: [' + insId + ']');
  }


  updateDatasource(){
    // const x: IterableIterator<Study> = this.studyList.values();
    // console.log(localStudyList);
    this.count = Object.keys(this.studyList).length
    let localStudyList:Study[] = [];
    for (const key in this.studyList) {
      if (Object.prototype.hasOwnProperty.call(this.studyList, key)) {
        const element = this.studyList[key];
        localStudyList.push(element);
      }
    }
    this.dataSource = new MatTableDataSource(localStudyList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getIcon(filePath: string) {
    let fileName = 'image-icon.png';
    if (filePath.endsWith('pdf')) {
      fileName = 'pdf-icon.png';
    } else if (filePath.endsWith('jpeg') || filePath.endsWith('jpg') || filePath.endsWith('png')) {
      fileName = 'image-icon.png';
    } else if (filePath.endsWith('doc') || filePath.endsWith('docx')) {
      fileName = 'word-icon.png';
    }

    return '/assets/images/' + fileName;

  }

  assignStudy(study: Study, email: string){
    study.assignedRadiologist = email;
    study.assignedToRadiologist = true;
    console.log(email);
    this.agencyService.updateStudy(study);
    delete this.studyList[study.studyInstanceUID];
    this.updateDatasource();
  }


  fileName= 'ExcelSheet.xlsx';  

exportexcel(): void 
    {
       /* table id is passed over here */   
       let element = document.getElementById('atable'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       XLSX.writeFile(wb, this.fileName);
			
    }
}
