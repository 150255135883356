<div class="mat-elevation-z8 height-100percent">

    <div class="input-group search-input-div">
        <div class="input-group-prepend">
            <div class="input-group-text"><i class="fa fa-search"></i></div>
        </div>
        <input type="text" class="form-control search-input" placeholder="Enter PPIN /Name /Queue #"
            (keyup)="applyFilter($event)">
    </div>

    <div class="list-table-div">
        <table class="list-table" mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="col1">
                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                <td mat-cell *matCellDef="let row" (click)="select(row)" class="table-row-with-card">

                    <mat-card fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="15px" class="table-row-card"
                        [ngClass]="{'selected-card': selectedUserInFirebase?.email === row.email}">

                        <div fxFlex fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="1px">
                            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                                <div>{{row.name}}</div>
                            </div>
                        </div>
                    </mat-card>
                </td>
            </ng-container>



            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter</td>
            </tr>
        </table>
    </div>

    <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10px" class="row-count-div">
        <div>
            Total {{dataSource?.data.length}} rows
        </div>
    </div>
</div>