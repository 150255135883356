<div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px" class="puru-full-container">
    <ng-container *ngIf="hospitalListManagedByAgency?.length > 0">
        <mat-form-field appearance="fill">
          <mat-label>Select</mat-label>
          <mat-select [formControl]="hospitalFilterFormControl">
            <mat-option value="all">ALL</mat-option>
            <mat-option *ngFor="let instituteId of hospitalListManagedByAgency" [value]="instituteId">{{instituteId}}</mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
    <mat-tab-group class="full-length-tab-group">
        <mat-tab label="Unassigned  [ Remaining: {{unassSC?.count}} ]">
            <!-- <app-unassigned-study [studyList]="unAssigmentStudyList" [insID]="insID"></app-unassigned-study> -->
            <app-unassigned-study [insID]="insID"></app-unassigned-study>
        </mat-tab>
        <mat-tab label="Assigned  [ Done: {{assSC?.count}} ]">
            <!-- <app-assigned-study [studyList]="assigmentStudyList" [insID]="insID"></app-assigned-study> -->
            <app-assigned-study [insID]="insID"></app-assigned-study>
        </mat-tab>
        <mat-tab label="Completed"> 
          
          <app-agency-complete-studies></app-agency-complete-studies>
          
        </mat-tab>
    </mat-tab-group>


</div>