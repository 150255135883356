import { DomSanitizer } from '@angular/platform-browser';
// import { StudyInHomeController } from './../home.component';
import { Component, OnInit, Input, SecurityContext, ViewChild, AfterViewInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/auth.service';
// import { DataSource } from '@angular/cdk/table';
import { AngularFirestore} from '@angular/fire/firestore';
// import { StudyService } from 'src/app/service/study-service.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Study, HttpClientService, TT, UserInFirebase } from 'src/app/service/http/http-client.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ViewDocumentComponent } from 'src/app/view-document/view-document.component';
import { MatDialog } from '@angular/material/dialog';
// import auth from 'firebase';
// import  firestore  from 'firebase/app';
import firebase from 'firebase/app'; 
import 'firebase/firestore';
// import { Subscription, interval } from 'rxjs';
// import { take, map } from 'rxjs/operators';

@Component({
  selector: 'app-pending-studies-for-agency-radiologist',
  templateUrl: './pending-studies-for-agency-radiologist.component.html',
  styleUrls: ['./pending-studies-for-agency-radiologist.component.css']
})
export class PendingStudiesForAgencyRadiologistComponent implements AfterViewInit {

  
  // will have multiple agency and multiple hospital (of agency)
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = ['Count', 'Name', 'modality', 'studyDateTime', 'StudyDesc', 'totalImages', 'PatientHistory', 'AttachedDocument', 'Upload', 'Options'];
  dataSource: MatTableDataSource<any>;
  remainingTimeMin = 0;
  timeElaspsed = 0;
  userEmail: string;
  agencyEmail: string;
  hospitalListManagedByAgency: any;
  studyList: Study[] = [];
  constructor(private storage: AngularFireStorage, private firestore: AngularFirestore,
    private domSanitizer: DomSanitizer, private authService: AuthService, private snackBar: MatSnackBar, private httpClient: HttpClientService,
    public dialog: MatDialog) { }

    ngAfterViewInit(){ }

    // @Input('agencyEmail')
    // set agencyEmail(agencyEmail: string){
    //   console.log('AgencyEmailCame:[' + agencyEmail + ']');
    // }
    
    @Input('user')
    set user(user: UserInFirebase){
      // console.log('UserEmailCame:[]');
      if (user){
        console.log(user);
        this.userEmail = user.email;
        this.agencyEmail = user.agencyID[0];
        this.hospitalListManagedByAgency = user.agencyAndHospitals[this.agencyEmail];
        this.updateStudyListForRadiologist();
      }
      
    }



    private updateStudyListForRadiologist() {

      this.hospitalListManagedByAgency.forEach(hospitalName => {
        this.updateStudyListForRadiologist0(this.prepareCollectionName(hospitalName));
      });
      this.updateStudyListForRadiologist0('dev2-btct-studies');
  
    }
  
    private updateStudyListForRadiologist0(collectionName: string) {
      console.log('[service]-Going to-updateStudyList():[' + collectionName + ']');
  
      //TODO: Change value change to get()
      this.firestore.collection<Study>(collectionName,
        ref => ref.where('assignedRadiologist', '==', this.userEmail).where('reportArrived', '==', false).orderBy('studyDateTime')).valueChanges().subscribe(val => {
          // console.log(val);
          val.forEach(element => {
            this.studyList[element.studyInstanceUID] = element;
          });
          this.updateDatasource();
        });
  

    }
  
    updateDatasource(studyList0?: Study[]) {
      // const x: IterableIterator<Study> = this.studyList.values();
      // console.log(localStudyList);
  
      // this.count = Object.keys(this.studyList).length
      let localStudyList: Study[] = studyList0 ? studyList0 : [];
      if (!studyList0) {
        for (const key in this.studyList) {
          if (Object.prototype.hasOwnProperty.call(this.studyList, key)) {
            const element = this.studyList[key];
            localStudyList.push(element);
          }
        }
      }
      this.dataSource = new MatTableDataSource(localStudyList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
    prepareCollectionName(insName: string): string {
      if (insName === 'DEV' || insName === 'BTCT')
        return 'dev-btct-studies';
      return 'prod-' + insName.toLowerCase() + '-studies';
    }


    getIcon(filePath: string) {
      let fileName = 'image-icon.png';
      if (filePath.endsWith('pdf')) {
        fileName = 'pdf-icon.png';
      } else if (filePath.endsWith('jpeg') || filePath.endsWith('jpg') || filePath.endsWith('png')) {
        fileName = 'image-icon.png';
      } else if (filePath.endsWith('doc') || filePath.endsWith('docx')) {
        fileName = 'word-icon.png';
      }
  
      return '/assets/images/' + fileName;
  
    }
  
    downloadFile(filePath: string) {
      const fileRef = this.storage.ref(filePath);
      fileRef.getDownloadURL().subscribe(val => {
        console.log(val);
        window.open(val);
      }, err => {
        console.log(err);
      });
    }
  
    openViewDocDialog(link: string) {
      this.dialog.open(ViewDocumentComponent, {
        // width: '70%',
        // maxWidth: '80%',
        data: {
          link
        }
      });
    }
  
    getUrlForStudy(s: Study) {
      const datasetName = s.dataSet ? s.dataSet : 'dev2-dataset-dicom-btct';
      const datastoreName = s.dataStore ? s.dataStore : 'dev2-datastore-dicom-btct';
      const location = s.region ? s.region : 'asia-southeast1';
      const baseUrlHCAPI = environment.baseUrlHCAPI;
      const projectId = s.projectId ? s.projectId : 'puru-255206';
      const baseUrl = baseUrlHCAPI + '/projects/' + projectId + '/locations/' + location + '/datasets/'
        + datasetName + '/dicomStores/' + datastoreName + '/dicomWeb" -r "studyUID=';
      const studyID = s.studyInstanceUID;
      const authToken = localStorage.getItem('at');
      const corePlainUrl: string = '$dicom:rs --url "' + baseUrl + studyID + '"' + ' -H "Authorization: Bearer ' + authToken + '"';
  
      const coreEncodedUrl0: string = encodeURIComponent(corePlainUrl);
      const coreEncodedUrl = this.domSanitizer.sanitize(SecurityContext.URL, coreEncodedUrl0);
      const unsafeUrl = 'weasis://' + coreEncodedUrl;
      window.open(unsafeUrl);
  
    }
  
    getDocName(filePath: string) {
      const array = filePath.split('/');
      return array[array?.length - 1];
    }
  
    uploadReport(study: Study) {
      console.log('send file to storage and save path in db');
      const file = this.files[study.studyID];
      const insID = study.instituteId;
      const fileName = study.patient.name+ '_' +study.studyID + '_' + file.name;
      const filePath = '/' + insID + '/' + study.modality + '/'+ study.patient.patientID + '/' + study.studyID + '/report/' + fileName;
      // const filePath = '/' + 'BTCT' + '/' + study.modality + '/'+ study.patient.patientID + '/' + study.studyID + '/report/' + file.name;
      try {
        const fileRef = this.storage.ref(filePath);
        const task = this.storage.upload(filePath, file);
        this.firestore.collection(this.prepareCollectionName(study.instituteId)).doc(study.patient.patientID + '-' + study.studyID).update({
        // this.studyCollection.doc(study.patient.patientID + '-' + study.studyID).update({
          reportArrived: true,
          report: firebase.firestore.FieldValue.arrayUnion({
            link: filePath,
            timestamp: new Date(),
            done_by: this.authService.user.email
          })
        });
        delete this.studyList[study.studyInstanceUID];
        this.updateDatasource();
      } catch (error) {
        console.error(error);
      }
    }
  
    // getPath(study: Study){
    //   const insID = study.instituteId;
    //   // const fileName = study.patient.name+ '_' +study.studyID + '_' + file.name;
    //   const filePath = '/' + insID + '/' + study.modality + '/'+ study.patient.patientID + '/' + study.studyID + '/report/';
    //   window.open(filePath)
  
    // }
    ////////////////////////------FILE F:N------///////////////////////
    files: any[] = [];
  
    /**
     * on file drop handler
     */
    onFileDropped($event, studyID) {
      this.prepareFilesList($event, studyID);
    }
  
    /**
     * handle file from browsing
     */
    fileBrowseHandler(files, studyID: string) {
      this.prepareFilesList(files, studyID);
    }
  
    /**
     * Delete file from files list
     * @param index (File index)
     */
    deleteFile(studyID: string) {
      //  this.files.splice(index, 1);
      delete this.files[studyID];
    }
  
    /**
     * Simulate the upload process
     */
    //  uploadFilesSimulator(index: number) {
    //    setTimeout(() => {
    //      if (index === this.files.length) {
    //        return;
    //      } else {
    //        const progressInterval = setInterval(() => {
    //          if (this.files[index]?.progress === 100) {
    //            clearInterval(progressInterval);
    //            this.uploadFilesSimulator(index + 1);
    //          } else {
    //            // this.files[index].progress += 5;
    //          }
    //        }, 200);
    //      }
    //    }, 1000);
    //  }
  
    /**
     * Convert Files list to normal array list
     * @param files (Files List)
     */
  
    prepareFilesList(files: Array<any>, studyID: string) {
      for (const item of files) {
        item.progress = 0;
        //  this.files.push(item);
        this.files[studyID] = item;
      }
      //  this.uploadFilesSimulator(0);
    }
  
    /**
     * format bytes
     * @param bytes (File size in bytes)
     * @param decimals (Decimals point)
     */
    formatBytes(bytes, decimals) {
      if (bytes === 0) {
        return '0 Bytes';
      }
      const k = 1024;
      const dm = decimals <= 0 ? 0 : decimals || 2;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
    ////////////////////////////////////////////////////////////////


}
