import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AngularFireStorage } from '@angular/fire/storage';
import { Study } from 'src/app/service/http/http-client.service';
import { IndiRadioServiceService } from '../indi-radio-service.service';
import { ViewDocumentComponent } from 'src/app/view-document/view-document.component';
import { ThemePalette } from '@angular/material/core';
import { environment } from 'src/environments/environment';
export class HospitalFilter {
  constructor(
    public name: string,
    public completed: boolean,
    public color: ThemePalette,
    public subtasks?: HospitalFilter[]
  ) { }
}
@Component({
  selector: 'app-indi-radio-pending-studies',
  templateUrl: './indi-radio-pending-studies.component.html',
  styleUrls: ['./indi-radio-pending-studies.component.css']
})
export class IndiRadioPendingStudiesComponent implements OnInit {

  task: HospitalFilter = {
    name: 'All',
    completed: false,
    color: 'accent',
    subtasks: [],
  };

  allComplete: boolean = false;
  studyList: Study[] = [];
  instituteID_studyCount: number[] = [];
  templateNames: string[];
  templateMap: string[];

  displayedColumns = ['Count', 'Name', 'reportDialog', 'modality', 'instituteId', 'refdoc', 'studyDateTime', 'StudyDesc', 'totalImages', 'AttachedDocument', 'PatientHistory', 'ReportCount', 'view-in-browser'];
  dataSource: MatTableDataSource<Study>;
  count: number;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Output() sendStudyForReportingEvent = new EventEmitter<Study>();
  constructor(public radiologistService: IndiRadioServiceService,
                public dialog: MatDialog,
                private storage: AngularFireStorage
              ) {
    this.updateDatasource();
    this.handleReportArrival();
    this.handleIncomingPendingStudies();
    this.handleHospitalList();
  }

  private initHospitalFilter(list: string[]) {

    if (!list) return;
    // console.log(list);
    if (list.length === 1) this.displayedColumns.splice(this.displayedColumns.indexOf('instituteId'), 1);

    const hospitalFilterList: HospitalFilter[] = [];
    list.forEach(shortCode => {
      const task: HospitalFilter = new HospitalFilter(shortCode, false, 'primary');
      hospitalFilterList.push(task);
    });
    this.task.subtasks = hospitalFilterList;
  }

  viewInBrowser(study: Study) {
    const data: string = study.projectId + ';' + study.region + ';' + study.dataSet + ';' + study.dataStore;
    // console.log(data);
    
      this.radiologistService.setCookie(study.studyInstanceUID, data);
      window.open(environment.viewerBaseUrl + '/viewer?StudyInstanceUIDs=' + study.studyInstanceUID);
  }

  updateAllComplete() {
    this.allComplete = this.task.subtasks != null && this.task.subtasks.every(t => t.completed);
    const selected: HospitalFilter[] = this.task.subtasks.filter(s => s.completed);

    let filteredHospitalList: string[] = []
    selected.forEach(val => {
      filteredHospitalList.push(val.name);
    });

    const filteredServiceList: Study[] = [];

    for (const key in this.studyList) {
      if (Object.prototype.hasOwnProperty.call(this.studyList, key)) {
        const element = this.studyList[key];
        const isIncluded = filteredHospitalList.includes(element.instituteId);
        if (isIncluded) filteredServiceList.push(element);
      }
    }

    this.updateDatasource(filteredServiceList);
  }

  someComplete(): boolean {
    if (this.task.subtasks == null) {
      return false;
    }
    return this.task.subtasks.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.task.subtasks == null) {
      return;
    }
    this.task.subtasks.forEach(t => (t.completed = completed));
    this.updateDatasource();

  }

  private handleIncomingPendingStudies() {
    this.radiologistService.eventCallbackStudyPendingList$.subscribe((val2: Study[]) => {
      val2.forEach(val => {
        if (!this.studyList[val.studyInstanceUID]) {
          let countForIns: number = this.instituteID_studyCount[val.instituteId];
          if (countForIns)
            this.instituteID_studyCount[val.instituteId]++;
          else
            this.instituteID_studyCount[val.instituteId] = 1;
        }
        this.studyList[val.studyInstanceUID] = val;
      });
      this.updateDatasource();
    });
  }

  private handleReportArrival() {
    this.radiologistService.getStudiesWithReportArrived().subscribe(val => {
      delete this.studyList[val.studyInstanceUID];
      this.instituteID_studyCount[val.instituteId]--;
      this.updateDatasource();
    });
  }

  ngOnInit(): void {

  }

  handleHospitalList(){
    this.radiologistService.eventCallbackStudyHospitalList$.subscribe(val => {
      this.initHospitalFilter(val);
    });
  }

  openReportPanel(study: Study) {
    this.sendStudyForReportingEvent.emit(study);
  }

  updateDatasource(studyList0?: Study[]) {
    // console.log(this.studyList);
    this.count = Object.keys(this.studyList).length
    let localStudyList: Study[] = studyList0 ? studyList0 : [];
    if (!studyList0) {
      for (const key in this.studyList) {
        if (Object.prototype.hasOwnProperty.call(this.studyList, key)) {
          const element = this.studyList[key];
          localStudyList.push(element);
        }
      }
    }

    this.dataSource = new MatTableDataSource(localStudyList);
    this.dataSource.sort = this.sort;
  }

  getIcon(filePath: string) {
    let fileName = 'image-icon.png';
    if (filePath.endsWith('pdf')) {
      fileName = 'pdf-icon.png';
    } else if (filePath.endsWith('jpeg') || filePath.endsWith('jpg') || filePath.endsWith('png')) {
      fileName = 'image-icon.png';
    } else if (filePath.endsWith('doc') || filePath.endsWith('docx')) {
      fileName = 'word-icon.png';
    }

    return '/assets/images/' + fileName;

  }

  downloadFile(filePath: string) {
    const fileRef = this.storage.ref(filePath);
    fileRef.getDownloadURL().subscribe(val => {
      // console.log(val);
      window.open(val);
    }, err => {
      console.log(err);
    });
  }

  openViewDocDialog(link: string) {
    this.dialog.open(ViewDocumentComponent, {
      data: {
        link
      }
    });
  }


  getStudyDateTime(studyDateTime: any): Date{
      try{
        const type = typeof (studyDateTime);
        if (studyDateTime instanceof Date) return studyDateTime;
        if ( type === 'string'){
          return new Date(studyDateTime);
        } else if (type === 'number') {
          return new Date(studyDateTime);
        }
      } catch(e){
        console.error(e);
        return null;
      }
  }

  getDocName(filePath: string) {
    const array = filePath.split('/');
    return array[array?.length - 1];
  }
}
