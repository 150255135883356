import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Collection } from 'src/app/service/Collection';
import { FirebaseService } from 'src/app/service/firebase/firebase.service';
import { Hospital } from 'src/app/service/http/http-client.service';

@Component({
  selector: 'app-hospital-list',
  templateUrl: './hospital-list.component.html',
  styleUrls: ['./hospital-list.component.css']
})
export class HospitalListComponent implements OnInit {
  dataSource: MatTableDataSource<Hospital>;

  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  // @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = ['col1'];
  @Output() hospitalSelectionEvent = new EventEmitter<Hospital>();
  
  list: Hospital[] = [];
  selectedHospital: Hospital;
  constructor(public fs: FirebaseService) {
    // this.list = fs.hospitalList;
    this.populateHospitalList();
  }

  ngOnInit(): void {
  }
  
  private populateHospitalList() {
    this.fs.getAllDocuments(Collection.HOSPITAL).subscribe(val => {
      val.docs.forEach(doc => {
        this.list.push(doc.data() as Hospital);
      });

      this.configureDataSource();
    });
  }


  select(h: Hospital) {
    this.selectedHospital = h;
    this.hospitalSelectionEvent.emit(this.selectedHospital);

  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  private configureDataSource() {
    this.dataSource = new MatTableDataSource(this.list);
  }

}
