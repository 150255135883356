<div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px" class="top-container">

    <div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="0" fxFill class="h-100 w-100">
        <mat-card class="service-heading">
            <h5>Hospital</h5>
        </mat-card>
        <mat-tab-group class="mat-tab-group-container" fxFlex="90" #tabs>
            <mat-tab label="Info" fxLayout fxLayoutAlign="center start" fxLayoutGap="10px" class="add-service-tab-body">


                <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="0px">
                    <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="0px" class="add-form-div">
                        <form fxFlex="50" [formGroup]="addForm" class="form-in-mat-tab">

                            <div fxLayout fxLayoutGap="10px">
                                <!-- <img class="s_icon" src="../../../../../assets/icons/icon-item-generic-name-bw.png"> -->
                                <mat-form-field fxFlex="90%">
                                    <mat-label>Email</mat-label>
                                    <input formControlName="email" matInput placeholder="sample@puru.co.in">
                                </mat-form-field>


                                <div *ngIf="addForm.get('email').valid">
                                <button mat-raised-button color="primary" (click)="validateEmail()" color="accent"
                                    *ngIf="emailStatus === -1">
                                    Validate
                                </button>
                                <button mat-icon-button color="primary" (click)="validateEmail()" color="warn"
                                    *ngIf="emailStatus === 0">
                                    <mat-icon>dangerous</mat-icon>
                                </button>
                                <button mat-icon-button color="primary" (click)="validateEmail()" color="primary"
                                    *ngIf="emailStatus === 1">
                                    <mat-icon>verified</mat-icon>
                                </button>
                                <mat-spinner *ngIf="emailStatus === 2"></mat-spinner>
                                </div>
                            </div>

                            <div *ngIf="emailStatus === 1">



                                <div fxLayout fxLayoutGap="10px">
                                    <!-- <img class="s_icon" src="../../../../../assets/icons/icon-item-generic-name-bw.png"> -->
                                    <mat-form-field fxFlex="90%">
                                        <mat-label>Name</mat-label>

                                        <input formControlName="name" matInput placeholder="Name">
                                    </mat-form-field>
                                </div>

                                <div fxLayout fxLayoutGap="10px">
                                    <!-- <img class="s_icon" src="../../../../../assets/icons/icon-item-generic-name-bw.png"> -->
                                    <mat-form-field fxFlex="90%">
                                        <mat-label>Address</mat-label>

                                        <input formControlName="address" matInput placeholder="Name">
                                    </mat-form-field>
                                </div>

                                <div fxLayout fxLayoutGap="10px">
                                    <!-- <img class="s_icon" src="../../../../../assets/icons/icon-item-generic-name-bw.png"> -->
                                    <mat-form-field fxFlex="90%">
                                        <mat-label>City</mat-label>

                                        <input formControlName="city" matInput placeholder="Name">
                                    </mat-form-field>
                                </div>

                                <div fxLayout fxLayoutGap="10px">
                                    <!-- <img class="s_icon" src="../../../../../assets/icons/icon-item-generic-name-bw.png"> -->
                                    <mat-form-field fxFlex="90%">
                                        <mat-label>Short Code</mat-label>

                                        <input formControlName="shortName" matInput placeholder="Name" maxlength="15" (focusout)="convertToCapitalCase()">
                                    </mat-form-field>
                                </div>



                                <div fxLayout fxLayoutGap="10px">
                                    <!-- <img class="s_icon" src="../../../../../assets/icons/icon-item-generic-name-bw.png"> -->
                                    <!-- <mat-form-field fxFlex="90%">
                                        <mat-label>Cluster</mat-label>
                                        <input formControlName="propertyCluster" matInput>
                                    </mat-form-field> -->
                                    <mat-form-field>
                                        <mat-label>Cluster</mat-label>
                                        <mat-select formControlName="propertyCluster" class="puru-mat-select">
                                            <mat-option [value]="c.name" *ngFor="let c of clusterList">{{c.name}}</mat-option>                                    
                                        </mat-select>
                                    </mat-form-field>
                                </div>




                                <div fxLayout fxLayoutGap="20px">

                                    <div fxLayout fxLayoutGap="10px" fxFlex="50">
                                        <!-- <img class="s_icon" src="../../../../../assets/icons/icon-item-generic-name-bw.png"> -->
                                        <mat-form-field fxFlex="90%">
                                            <mat-label>Server IP</mat-label>

                                            <input formControlName="serverIp" matInput placeholder="172.0.0.1" pattern="^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$">
                                        </mat-form-field>
                                    </div>


                                    <div fxLayout fxLayoutGap="10px">
                                        <!-- <img class="s_icon" src="../../../../../assets/icons/icon-item-generic-name-bw.png"> -->

                                        <mat-form-field>
                                            <mat-label>Data Location</mat-label>
                                            <mat-select formControlName="dataLocation" class="puru-mat-select">
                                                <mat-option value="C:\\PuruData">C</mat-option>
                                                <mat-option value="D:\\PuruData">D</mat-option>
                                                <mat-option value="E:\\PuruData">E</mat-option>
                                                <mat-option value="F:\\PuruData">F</mat-option>
                                                <mat-option value="G:\\PuruData">G</mat-option>
                                                <mat-option value="H:\\PuruData">H</mat-option>

                                            </mat-select>
                                        </mat-form-field>
                                    </div>





                                </div>


                                <div fxLayout fxLayoutGap="20px">

                                    <div fxLayout fxLayoutGap="10px" fxFlex="50">
                                        <!-- <img class="s_icon" src="../../../../../assets/icons/icon-item-generic-name-bw.png"> -->

                                        <mat-form-field>
                                            <mat-label>Mode</mat-label>
                                            <mat-select formControlName="onlineAllowed" class="puru-mat-select">
                                                <mat-option value="true">Online</mat-option>
                                                <mat-option value="false">Offline</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>


                                    <mat-form-field>
                                        <mat-label>Auto Upload</mat-label>
                                        <mat-select formControlName="autoUploadModality" multiple>
                                            <mat-option *ngFor="let modality of modalityList" [value]="modality">
                                                {{modality}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                </div>
                            </div>
                        </form>
                        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
                            <button mat-stroked-button color="warn">Cancel</button>
                            <button mat-raised-button color="primary" (click)="onSubmit()"
                                [disabled]="!addForm.valid">Submit</button>
                        </div>
                    </div>


                </div>



            </mat-tab>
            <!-- </div> -->




            <mat-tab label="Statistics">


            </mat-tab>

            <!-- <form [formGroup]="addServiceForm" class="form-in-mat-tab" *ngIf="service">
                <mat-tab label="General" fxLayout fxLayoutAlign="center start" fxLayoutGap="10px"
                    class="add-service-tab-body">
                    <app-add-service [addServiceForm]="addServiceForm" [serviceInput]="service"
                        [pathoUOMList]="pathoUOMList" [machineList]="machineList"></app-add-service>
                </mat-tab>
                <mat-tab label="Period">
                    <app-add-service-period [addServiceForm]="addServiceForm"></app-add-service-period>
                </mat-tab>
                <mat-tab label="Sub Services">
                    <app-add-sub-service [addServiceForm]="addServiceForm" [subServiceInput]="service?.subServices"
                        [pathoUOMList]="pathoUOMList">
                    </app-add-sub-service>
                </mat-tab>
                <mat-tab label="Charges">
                    <app-add-charge [addServiceForm]="addServiceForm" [chargesInput]="service?.charges"></app-add-charge>
                </mat-tab>
                <mat-tab label="Statistics"> Content 3 </mat-tab>
            </form> -->
        </mat-tab-group>
        <!-- <mat-card fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10px">
            <button mat-stroked-button color="warn">Disable</button>
            <button mat-raised-button color="primary" (click)="onSubmit()">Submit</button>
        </mat-card> -->
    </div>


</div>