<div class="parent-div mat-elevation-z8" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="2px">

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
        <mat-button-toggle-group name="fontStyle" class="hospital-select-button-grp" align="end"
            [formControl]="hospitalButtonToggleFCAll">

            <mat-button-toggle value="all" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2px">
                <span>All</span>
                <span> [ {{(studyList | keyvalue)?.length}} ] </span>
            </mat-button-toggle>
        </mat-button-toggle-group>

        <mat-button-toggle-group name="fontStyle" class="hospital-select-button-grp" multiple align="end"  appearance="legacy"
            [formControl]="hospitalButtonToggleFC">

            <mat-button-toggle appearance="legacy" value="{{h}}" *ngFor="let h of radiologistService.hospitalList"
                class="hospital-select-button" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2px">
                <span>{{h}}</span>
                <span> [ {{instituteID_studyCount[h] ? instituteID_studyCount[h] : 0}} ] </span>
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" fxFill>
        <mat-form-field fxFlex="50" >
            <input matInput (keyup)="applyFilter($event.target.value)" id="search-field" class="mdl-textfield__input"
                placeholder="Search Study Id, Patient Name, Patient Id, Study Desc">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [rangePicker]="picker">
                <input matStartDate [formControl]="startDateFormControl" placeholder="Start date">
                <input matEndDate [formControl]="endDateFormControl" placeholder="End date">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>

            <!-- <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error> -->
            <!-- <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error> -->
        </mat-form-field>

        <button mat-raised-button color="primary" (click)="display()">Display</button>
        <!-- <button mat-stroked-button color="primary">Download As Excel</button> -->


    </div>
    <div class="table-div">
        <table mat-table [dataSource]="dataSource" matSort fixedLayout="true" id="completedTable">

            <ng-container matColumnDef="Count">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
                <td mat-cell *matCellDef="let element;let j = index">
                    {{j + 1}}
                </td>
            </ng-container>

            <ng-container matColumnDef="Name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let element">

                    <button mat-button (click)="radiologistService.getUrlForStudy(element)" matTooltip="Click to View"
                        [ngClass]="{'opened': element.studyUnderObservation}">
                        {{element.patient.name}}
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="modality">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Mod. </th>
                <td mat-cell *matCellDef="let element"> {{element.modality}} </td>
            </ng-container>

            <ng-container matColumnDef="instituteId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>


                    <mat-form-field appearance="none" class="header-matformfeild-select">
                        <mat-label>Hospital</mat-label>
                        <mat-select [formControl]="hospitalFilterFormControl" multiple class="puru-mat-select">
                            <mat-option [value]="h" *ngFor="let h of radiologistService.hospitalList" fxLayout="row"
                                fxLayoutAlign="start center">

                                <span fxFlex="50">
                                    {{h}}
                                </span>
                                <span>
                                    {{instituteID_studyCount[h] ? instituteID_studyCount[h] : 0}}
                                </span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>



                </th>
                <td mat-cell *matCellDef="let element"> {{element.instituteId}} </td>
            </ng-container>

            <ng-container matColumnDef="reportDialog">
                <th mat-header-cell *matHeaderCellDef> Report </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button color="primary" (click)="openReportPanel(element)">
                        <!-- <mat-icon>quickreply</mat-icon> -->
                        <mat-icon>app_registration</mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="StudyDesc">
                <th mat-header-cell *matHeaderCellDef> Study Desc </th>
                <td mat-cell *matCellDef="let element"> {{element.description}} </td>
            </ng-container>

            <ng-container matColumnDef="studyDateTime">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Study Time </th>
                <td mat-cell *matCellDef="let element"> {{element.studyDateTime | date:'d MMM, hh:mm a'}} </td>
            </ng-container>

            <ng-container matColumnDef="refdoc">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Ref. Physician </th>
                <td mat-cell *matCellDef="let element"> {{element.referringPhysicianName === 'null' ? '':
                    element.referringPhysicianName}} </td>
            </ng-container>

            <ng-container matColumnDef="totalImages">
                <th mat-header-cell *matHeaderCellDef> Images # </th>
                <td mat-cell *matCellDef="let element"> {{element.onlineImageCount}} </td>
            </ng-container>

            <ng-container matColumnDef="PatientHistory">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> History </th>
                <td mat-cell *matCellDef="let element" matTooltip="{{element.patientHistory}}"> {{element.patientHistory}} </td>
            </ng-container>

            <ng-container matColumnDef="AttachedDocument">
                <th mat-header-cell *matHeaderCellDef> Document </th>
                <td mat-cell *matCellDef="let element">
                    <span *ngFor="let doc of element.additionalDocument">
                        <span (click)="openViewDocDialog(doc)">
                            <img class="image-icon" src="{{getIcon(doc)}}" alt="Avatar" class="image"
                                matTooltip="{{getDocName(doc)}}">
                        </span>
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="ReportCount">
                <th mat-header-cell *matHeaderCellDef> Report # </th>
                <td mat-cell *matCellDef="let row">

                    {{row.report ? row.report.length : 0}} /{{row.expectedReportCount}}


                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                [ngClass]="{'opened': row.studyUnderObservation, 'stillfresh': !row.studyUnderObservation}"></tr>
        </table>
    </div>
    <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10px" class="row-count-div">
        <div>
            Total {{dataSource ? dataSource?.data.length : 0}} rows
        </div>
    </div>

    <button mat-raised-button (click)="exportexcel()">Download</button>
</div>
