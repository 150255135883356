<div class="parent-div mat-elevation-z8">
    <div>
        
        <img class="s_icon" src="./assets/icon/icon-start.png">
        <mat-form-field>
          <input matInput [matDatepicker]="startDate1" placeholder="Start Date" [formControl]="startDateFormControl">
          <mat-datepicker-toggle matSuffix [for]="startDate1" tabindex="-1"></mat-datepicker-toggle>
          <mat-datepicker #startDate1 startView="month" [startAt]="todayDate"></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <input matInput [matDatepicker]="endDate1" placeholder="End Date" [formControl]="endDateFormControl">
          <mat-datepicker-toggle matSuffix [for]="endDate1" tabindex="-1"></mat-datepicker-toggle>
          <mat-datepicker #endDate1 startView="month" [startAt]="todayDate"></mat-datepicker>
        </mat-form-field>
        <img class="s_icon" src="./assets/icon/icon-end.png">

        <button mat-raised-button color="primary" (click)="updateStudyList()">Display</button>
    </div>
    <table mat-table [dataSource]="dataSource" matSort>
  
      <ng-container matColumnDef="Count">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
        <td mat-cell *matCellDef="let element;let j = index">{{paginator.pageSize * paginator.pageIndex + j + 1}} </td>
      </ng-container>
  
      <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let element"> 
          
            {{element.patient.name}}
          <!-- {{element.patient.name}} // {{element.patient.patientID}}/{{element.studyID}}/report/ -->
          <!-- <button mat-button matTooltip="Click to View" >
              
          </button> -->
        </td>
      </ng-container>
  
      <ng-container matColumnDef="modality">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Modality </th>
        <td mat-cell *matCellDef="let element"> {{element.modality}} </td>
      </ng-container>
  
      <ng-container matColumnDef="instituteId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Institute </th>
        <td mat-cell *matCellDef="let element"> {{element.instituteId}} </td>
      </ng-container>
  
      <ng-container matColumnDef="StudyDesc">
        <th mat-header-cell *matHeaderCellDef> Study Desc </th>
        <td mat-cell *matCellDef="let element"> {{element.description}} </td>
      </ng-container>
  
      <ng-container matColumnDef="studyDateTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Study Time </th>
        <td mat-cell *matCellDef="let element"> {{element.studyDateTime | date:'d MMM yy, h:mm a'}} </td>
      </ng-container>
      '', 'reportArrived'
      <ng-container matColumnDef="reportDownloadedAtRt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> is Downloaded </th>
        <td mat-cell *matCellDef="let element"> {{element.reportDownloadedAtRt}} </td>
      </ng-container>
      <ng-container matColumnDef="reportArrived">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Report Arrived </th>
        <td mat-cell *matCellDef="let element"> {{element.reportArrived}} 
          
          <button mat-icon-button aria-label="Delete Button" (click)="fixStudyList_reportArrivedFalse(element)" color="warn">
            <mat-icon>swap_horiz</mat-icon>
          </button>
        
        </td>
      </ng-container>
    

  
      <ng-container matColumnDef="Upload">
        <th mat-header-cell *matHeaderCellDef> Upload Report </th>
        <td mat-cell *matCellDef="let element">
          <div fxLayout fxLayoutGap="5px" appDnd (fileDropped)="onFileDropped($event, element.studyID)" *ngIf = "!files[element.studyID]" class="upload-btn-wrapper">
            <button mat-raised-button color="primary">
              <mat-icon>cloud_upload</mat-icon> Drop / Select </button>
            <input type="file" #fileDropRef id="fileDropRef" multiple name="myfile" class="file-input-class" (change)="fileBrowseHandler($event.target.files, element.studyID)" />
          </div>
  
          <div fxLayout fxLayoutGap="5px" *ngIf = "files[element.studyID]">
            <img src="assets/images/dnd/ic-file.svg" alt="file" class="drag-drop-image">
            <!-- <img src="assets/images/dnd/ic-delete-file.svg" class="delete" width="20px" alt="file" (click)="deleteFile(element.studyID)"> -->
            <button mat-icon-button aria-label="Upload Button" color="primary" (click)="uploadReport(element)">
              <mat-icon>cloud_upload</mat-icon>
            </button>
            <button mat-icon-button aria-label="Delete Button" (click)="deleteFile(element.studyID)" color="warn">
              <mat-icon>delete</mat-icon>
            </button>
            <span>{{files[element.studyID]?.name}}</span>
          </div>
  
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  
    <mat-paginator #paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
  </div>
  <!-- <div *ngIf="offlineRequested">
    <span>Preparing your download</span>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div> -->
  <!-- <h5>Time Remaining {{remainingTimeMin - timeElaspsed}} min | TUAT {{tokedUpdatedAt?.seconds  * 1000 | date:'hh:mm aaa' }} | {{_collectionName}}</h5> -->
  <!-- Sample comment -->
  <!-- <button mat-raised-button (click)="acceptAll()">View Non Accepted Studies</button>
  <button mat-raised-button (click)="acceptStudy()" *ngIf="oldNonAcceptedList.length !== 0">Accept These Studies</button>
  {{lastDate | date}} -->

  <input type="file" #fileDropRef id="fileDropRef" name="myfile" class="file-input-class" multiple
  (change)="multiplereportFileBrowseHandler($event.target.files)" />
<button mat-raised-button color="primary" matBadgePosition="before" matBadgeColor="accent"
  (click)="processMultipleFiles()">
  Upload All
</button>
  