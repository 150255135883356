import { Component, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import 'firebase/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { DomSanitizer } from '@angular/platform-browser';
import { AngularFireStorage } from '@angular/fire/storage';
import { AuthService } from 'src/app/auth/auth.service';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Study, HttpClientService, TT, UserInFirebase } from 'src/app/service/http/http-client.service';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-agency-radiologist',
  templateUrl: './agency-radiologist.component.html',
  styleUrls: ['./agency-radiologist.component.css']
})
export class AgencyRadiologistHomeComponent implements OnInit {
  userInSession: UserInFirebase;
  userEmail: string;
  studyList: Study[];
  hospitalListManagedByAgency: string[];
  agencyEmail: string;

  constructor(public firestore: AngularFirestore, public auth: AngularFireAuth, private storage: AngularFireStorage,
    private domSanitizer: DomSanitizer, private authService: AuthService) { 

    this.auth.user.subscribe(val => {
      if (!val?.displayName) {
        this.userInSession = null;
        return;
      } else {
        this.userEmail = val.email;
      }

      
      // this.firestore.collection<UserInFirebase>('users', ref => ref.where('email', '==', val.email)).valueChanges().subscribe(val2 => {
      //   this.hospitalListManagedByAgency = val2[0].agencyHospitals;
      //   console.log('[service]-' + this.hospitalListManagedByAgency);
      // });
      
      this.firestore.collection<UserInFirebase>('users', ref => ref.where('email', '==', val.email)).get().subscribe(val => {
        val.forEach(x => {
          this.userInSession = x.data() as UserInFirebase;
          this.agencyEmail = this.userInSession.agencyID[0];

        })
      })
      // this.firestore.collection<UserInFirebase>('users', ref => ref.where('email', '==' , val.email)).get().toPromise().then(c => {
      //   c
      // })



    });



  }



  

  private updateStudyListForAllHospital() {

    this.hospitalListManagedByAgency.forEach(hospitalName => {
      this.updateStudyListForAgency(this.prepareCollectionName(hospitalName));
    });
    this.updateStudyListForAgency('dev2-btct-studies');

  }

  private updateStudyListForAgency(collectionName: string) {
    console.log('[service]-Going to-updateStudyList():[' + collectionName + ']');

    this.firestore.collection<Study>(collectionName,
      ref => ref.where('reportingAgency', '==', this.agencyEmail).where('assignedToRadiologist', '==', false).where('reportArrived', '==', false).orderBy('studyDateTime')).valueChanges().subscribe(val => {
        console.log(val);
        val.forEach(element => {
          // this.eventCallbackStudyUnAssigned.next(element);
        });
      });

    this.firestore.collection<Study>(collectionName,
      ref => ref.where('reportingAgency', '==', this.agencyEmail).where('assignedToRadiologist', '==', true).where('reportArrived', '==', false).orderBy('studyDateTime')).valueChanges().subscribe(val => {
        console.log(val);
        val.forEach(element => {
          // this.eventCallbackStudyAssigned.next(element);
        });
      });
  }

  prepareCollectionName(insName: string): string {
    if (insName === 'DEV' || insName === 'BTCT')
      return 'dev-btct-studies';
    return 'prod-' + insName.toLowerCase() + '-studies';
  }



  ngOnInit(): void {
  }

}
