<div class="parent-div mat-elevation-z8">
  <marquee behavior=alternate style="color: red;">
    By default, this screen contains list of studies done in last 1 days, for older studies, please select date range
    and click Display
  </marquee>
  <div fxLayout>
    <label class="mdl-button mdl-js-button mdl-button--icon" for="search-field">
      <i class="material-icons">search</i>
    </label>
    <div>
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" id="search-field" class="mdl-textfield__input"
          placeholder="Filter" [formControl]="searchBarFormControl">
      </mat-form-field>
    </div>

    <label class="mdl-button mdl-js-button mdl-button--icon" for="search-field1">
      <i class="material-icons">date</i>
    </label>

    <div>

      <div fxLayout fxLayoutGap="10px" fxFlex>


        <img class="s_icon" src="./assets/icon/icon-start.png">
        <mat-form-field>
          <input matInput [matDatepicker]="startDate1" placeholder="Start Date" [formControl]="startDateFormControl">
          <mat-datepicker-toggle matSuffix [for]="startDate1" tabindex="-1"></mat-datepicker-toggle>
          <mat-datepicker #startDate1 startView="month" [startAt]="todayDate"></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <input matInput [matDatepicker]="endDate1" placeholder="End Date" [formControl]="endDateFormControl">
          <mat-datepicker-toggle matSuffix [for]="endDate1" tabindex="-1"></mat-datepicker-toggle>
          <mat-datepicker #endDate1 startView="month" [startAt]="todayDate"></mat-datepicker>
        </mat-form-field>
        <img class="s_icon" src="./assets/icon/icon-end.png">

        <div fxLayout fxLayoutGap="10px">
          <button mat-button color="primary" class="default-button" (click)="display()"> Display </button>
        </div>
      </div>


    </div>


  </div>

  <table mat-table [dataSource]="dataSource" matSort #sort>

    <ng-container matColumnDef="Count">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
      <td mat-cell *matCellDef="let element;let j = index"> {{paginator.pageSize * paginator.pageIndex + j + 1}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element">

        <button mat-button (click)="getUrlForStudy(element)" matTooltip="Click to View">
          {{element.patient.name}}
        </button>

      </td>
    </ng-container>

    <ng-container matColumnDef="modality">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Mod. </th>
      <td mat-cell *matCellDef="let element"> {{element.modality}}-{{element.onlineImageCount}} </td>
    </ng-container>

    <ng-container matColumnDef="hospital">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>

        <mat-form-field appearance="none" class="header-matformfeild-select">
          <mat-label>Hospital</mat-label>
          <mat-select [formControl]="hospitalFilterFormControl" multiple>
            <mat-option [value]="h" *ngFor="let h of agencyService.hospitalListManagedByAgency">{{h}}</mat-option>
          </mat-select>
        </mat-form-field>


      </th>
      <td mat-cell *matCellDef="let element"> {{element.instituteId}} </td>
    </ng-container>

    <ng-container matColumnDef="referringPhysicianName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Ref. Physician </th>
      <td mat-cell *matCellDef="let element"> {{element.referringPhysicianName}} </td>
    </ng-container>

    <ng-container matColumnDef="studyDateTime">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Study Time </th>
      <td mat-cell *matCellDef="let element"> {{element.studyDateTime | date:'d MMM yy, h:mm a'}} </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef> Study Desc </th>
      <td mat-cell *matCellDef="let element"> {{element.description}} </td>
    </ng-container>

    <ng-container matColumnDef="PatientHistory">
      <th mat-header-cell *matHeaderCellDef> History </th>
      <td mat-cell *matCellDef="let element"> {{element.patientHistory}} </td>
    </ng-container>

    <ng-container matColumnDef="AttachedDocument">
      <th mat-header-cell *matHeaderCellDef> Attached Document </th>
      <td mat-cell *matCellDef="let element"> <span *ngFor="let doc of element.additionalDocument">
          <span (click)="openViewDocDialog(doc)">
            <img class="image-icon" src="{{getIcon(doc)}}" alt="Avatar" class="image" matTooltip="{{getDocName(doc)}}">
          </span>
        </span> </td>
    </ng-container>


    <ng-container matColumnDef="View">
      <th mat-header-cell *matHeaderCellDef> View </th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="getUrlForStudy(element)">
          <mat-icon>
            visibility
          </mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="ViewReport">
      <th mat-header-cell *matHeaderCellDef> View </th>
      <td mat-cell *matCellDef="let element">
        <button mat-button (click)="openReportDialog(element.report)">Open Report</button>
      </td>
    </ng-container>
    <ng-container matColumnDef="Options">
      <th mat-header-cell *matHeaderCellDef> Offline </th>
      <td mat-cell *matCellDef="let row">

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator #paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
</div>