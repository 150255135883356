import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
// import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Report, ReportSample, Study } from 'src/app/service/http/http-client.service';
import { AngularFireStorage } from '@angular/fire/storage';
// import { environment } from 'src/environments/environment';
// import { ViewDocumentComponent } from 'src/app/view-document/view-document.component';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from 'src/app/auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
// import firestore from 'firebase/app';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { IndiRadioServiceService } from 'src/app/individual-radiologist-fn/indi-radio-service.service';
import { MatTabChangeEvent } from '@angular/material/tabs';


export interface StorageMetadata {
  type: string;
  bucket: string;
  generation: string;
  metageneration: string;
  fullPath: string;
  name: string;
  size: number;
  timeCreated: Date;
  updated: string;
  md5Hash: string;
  contentDisposition: string;
  contentEncoding: string;
  contentType: string;
}

@Component({
  selector: 'app-report-container',
  templateUrl: './report-container.component.html',
  styleUrls: ['./report-container.component.css']
})
export class ReportContainerComponent implements OnInit {

  showNewReportNameInput = false;
  newReportNameFC = new FormControl();
  activeTabIndex = 0;
  selectedReportSample: ReportSample;
  countReportSubmitted = 0;
  reportCount = 0;

  // valueInTemplateSuggeestion = 'Clear me';
  templateNameFC = new FormControl();
  options: string[] = [];
  filteredOptions: Observable<string[]>;

  // reportTemplateName = new FormControl();
  // templateContent = new FormControl();
  suitableTemplateName: string[];

  @Output() needToCloseEvent = new EventEmitter<boolean>();
  study: Study;
  reportNameList: string[] = [];
  // @Input() study: Study;
  constructor(public radiologistService: IndiRadioServiceService,
    private storage: AngularFireStorage, private firestore: AngularFirestore,
    private authService: AuthService, private snackBar: MatSnackBar

  ) {
    this.options = radiologistService.templateNames;
    this.countReportSubmitted = 0;

  }

  ngOnInit(): void {
    this.filteredOptions = this.templateNameFC.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  resetTemplateNameFC() {
    this.templateNameFC.reset();
  }

  openSnackBar(message: string, action: string, duration?: number) {
    if (!duration) {
      duration = 2000;
    }
    this.snackBar.open(message, action, {
      duration,
    });
  }


  @Input('study')
  set _study(s: Study) {
    if (!s) return;
    let docId = s.patient.patientID + '-' + s.studyID;
    if (s.approachType && s.approachType === 1) docId = s.instituteId + '-' + docId;
    s.fbDocId = docId;
    this.setupReportContainers(s);
  }

  setupReportContainers(s: Study) {
    this.selectedReportSample = null;
    this.countReportSubmitted = 0;

    if (!s.expectedReportCount) s.expectedReportCount = 1;

    if (s.report?.length > 0){
        this.reportCount = s.report.length;
    } else {
      this.reportCount = s.expectedReportCount;
    }

    this.reportNameList = s.reportNameList;

    this.study = s;
    this.suitableTemplateName = this.filterTemplateNameOnStudyDesc(this.reportNameList[0]);
  }

  private _filter(value: string): string[] {
    let filterValue = '';
    if (value) filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  reportLater(report: Report) {
    try {

      if (!this.study.fbDocId) {
        let docId = this.study.patient.patientID + '-' + this.study.studyID;
        if (this.study.approachType && this.study.approachType === 1) docId = this.study.instituteId + '-' + docId;
        this.study.fbDocId = docId;
      }


      console.log(this.countReportSubmitted + ',' + this.study.expectedReportCount);
      this.firestore.collection(this.radiologistService.prepareCollectionName(this.study.instituteId)).doc(this.study.fbDocId).update({
        reportArrived: true,
        reportLater: 1
      });
      this.openSnackBar('Report: marked for later', null);
      this.closePanel();
      this.radiologistService.notifyPendingStudiesReportArrived(this.study);
    } catch (error) {
      console.error('Error Occurred while submitting report');
      throw error;
    }
  }

  addNewReport(){
    this.study.expectedReportCount =  this.study.expectedReportCount + 1;
    const rName = this.newReportNameFC.value as string;
    this.study.reportNameList?.push(rName);
    this.showNewReportNameInput = false;
    this.reportCount++;

    this.firestore.collection(this.radiologistService.prepareCollectionName(this.study.instituteId)).doc(this.study.fbDocId).update({
      reportNameList: this.study.reportNameList
    });
  }

  reportSubmitted(report: Report) {
    if (report.reportLater) {
      this.reportLater(report);
      return;
    }
    // console.log('reportSubmitted called');

    // console.log(this.countReportSubmitted);
    this.countReportSubmitted++;
    if (report.mode === 'html_file') {
      const fileRef = this.storage.ref(report.link);
      fileRef.getMetadata().subscribe((val: StorageMetadata) => {

        if (val.size > 0) {
          this.openSnackBar('Size of Report: ' + val.size, 'OK');
          report.link = val.fullPath;
          console.log('fullPath: ' + val.fullPath);
          this.updateReportInFirebase(report);
        } else {
          this.openSnackBar('Unable to upload report, please try again ' + val.size, 'OK');
        }

      }, err => {
        console.error('Uploaded File not found, please try again. ' + err);
        this.openSnackBar('Unable to upload report, please try again ', 'OK');
      });
    }
  }

  updateReportInFirebase(report: Report) {

    let reportArrived = false;
    try {

      if (report.isEdited) reportArrived = true;
      else reportArrived = this.countReportSubmitted >= this.study.expectedReportCount;
      // console.log(this.countReportSubmitted + ',' + this.study.expectedReportCount);

      if (!this.study.fbDocId) {
        let docId = this.study.patient.patientID + '-' + this.study.studyID;
        if (this.study.approachType && this.study.approachType === 1) docId = this.study.instituteId + '-' + docId;
        this.study.fbDocId = docId;
      }


      this.firestore.collection(this.radiologistService.prepareCollectionName(this.study.instituteId)).doc(this.study.fbDocId).update({
        reportArrived: reportArrived,
        reportDownloadedAtRt: false,
        report: firebase.firestore.FieldValue.arrayUnion({
          mode: report.mode,
          link: report.link,
          timestamp: report.timestamp,
          timestampMS: new Date().getTime(),
          done_by: this.authService.user.email,
          submitStatus: report.submitStatus,
          name: report.name,
          isEdited: report.isEdited
        })
      });

      // this.openSnackBar('Report: ' + report.name + ' submitted', 'OK');
      if (reportArrived) {
        this.closePanel();
        this.radiologistService.notifyPendingStudiesReportArrived(this.study);
      }
    } catch (error) {
      console.error('Error Occurred while submitting report');
      throw error;
    }

  }

  closePanel() {
    this.templateNameFC.reset();
    this.needToCloseEvent.emit(true);
    // this.templateContent.reset();
  }

  tabChanged(event: MatTabChangeEvent) {
    this.activeTabIndex = event.index;
    this.templateNameFC.reset();
    // console.log(event.tab.textLabel);
    this.suitableTemplateName = this.filterTemplateNameOnStudyDesc(event.tab.textLabel);
  }

  ////////////////////////------FILE F:N------///////////////////////
  files: any[] = [];

  /**
   * on file drop handler
   */
  onFileDropped($event, studyID) {
    this.prepareFilesList($event, studyID);
  }

  /**
   * handle file from browsing
   */ // 1
  fileBrowseHandler(files, studyID: string) {
    this.prepareFilesList(files, studyID);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(studyID: string) {
    //  this.files.splice(index, 1);
    delete this.files[studyID];
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */

  //2
  prepareFilesList(files: Array<any>, studyID: string) {
    for (const item of files) {
      item.progress = 0;
      //  this.files.push(item);
      this.files[studyID] = item;
    }
    //  this.uploadFilesSimulator(0);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  ////////////////////////////////////////////////////////////////


  loadTemplate(rs: string, isFromDropDown?: boolean) {
    rs = rs.trim();
    this.selectedReportSample = this.radiologistService.templateMap[rs];
  }



  filterTemplateNameOnStudyDesc(desc0: string): string[] {
    // console.log(this.radiologistService.templateNames.length + ' length of template names ' + desc0);
    let desc = desc0.toLowerCase().replace(' ', '');
    desc = desc.replace('ct', '');
    desc = desc.replace('mr', '');
    const list = this.radiologistService.templateNames.filter(name => name.toLowerCase().includes(desc));
    // console.log(list);
    return list;
  }

}
