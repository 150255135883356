<div class="row main-parent">
  <div class="col-md-7">
    <table>
      <tbody>
        <tr>
          <td># of Series</td>
          <td>{{seriesList.length}}</td>
        </tr>
        <tr>
          <td># of Images</td>
          <td>{{files.length}}</td>
        </tr>
        <tr>
          <td>Patient ID</td>
          <td>{{patientID}}</td>
        </tr>
        <tr>
          <td>Study ID</td>
          <td>{{studyId}}</td>
        </tr>
        <tr>
          <td>Study UID</td>
          <td>{{studyInstanceUID}}</td>
        </tr>
        <tr>
          <td>Modality</td>
          <td>{{modality}}</td>
        </tr>
        <tr>
          <td>Study Desc</td>
          <td>{{studyDesc}}</td>
        </tr>
        <tr>
          <td>Study Desc</td>
          <td>{{studyDesc}}</td>
        </tr>
        <tr>
          <td></td>
          <td><button type="button" class="btn btn-primary" (click)="uploadStudy()">Send</button></td>
        </tr>
      </tbody>
    </table>
  
    <!-- <li>
        <ul>
          No of Series: {{seriesList.length}}</ul>
          <ul>  No of Images: {{files.length}}</ul>
          <ul>  Patient Name: {{patientName}}</ul>
          <ul> Patient ID: {{patientID}} </ul>
          <ul> Study ID: {{studyId}} </ul>
          <ul> Study I UID: {{studyInstanceUID}} </ul>
          <ul> Modality: {{modality}}</ul>
          <ul> studyDesc: {{studyDesc}}</ul>
          <ul> uploadPercent: {{uploadPercent | json}}</ul>
          <button type="button" class="btn btn-primary"(click)="uploadStudy()">Send</button>

      </li> -->
  </div>
  <div class="col-md-5 rightDiv">
    <mat-card fxLayout="column" fxLayoutAlign="space-around center" fxLayoutGap="10px" *ngIf="!isWaiting">
      <mat-spinner *ngIf="isWaiting"></mat-spinner>
      <mat-card-content>
        <div>
          <mat-form-field class="example-full-width">
            <mat-label>Patient History</mat-label>
            <textarea matInput #message [formControl]="patientHistoryFormControl"></textarea>
            <mat-hint align="start"><strong>Please write clear history</strong> </mat-hint>
            <mat-hint align="end">{{message.value.length}} / 256</mat-hint>
          </mat-form-field>
        </div>
        <div>
          <div class="container" appDnd (fileDropped)="onFileDropped($event)">
            <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)"
              accept=".dcm" />
            <img src="assets/images/dnd/ic-upload-file.svg" alt="">
            <h3>Drag and drop your DICOM file here</h3>
            <h3>or</h3>
            <label for="fileDropRef">Browse files</label>
          </div>
          <div class="files-list">
            <div class="single-file" *ngFor="let file of files; let i = index">
              <img src="assets/images/dnd/ic-file.svg" width="45px" alt="file">
              <div class="info">
                <h4 class="name">
                  {{ file?.name }}
                </h4>
                <p class="size">
                </p>
              </div>
              <img src="assets/images/dnd/ic-delete-file.svg" class="delete" width="20px" alt="file"
                (click)="deleteFile(i)">
            </div>
          </div>
          <button type="button" class="btn btn-primary" (click)="uploadFile()">Upload</button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

</div>