import { AuthService } from './../auth/auth.service';
import { Component, OnInit } from '@angular/core';
import 'firebase/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import auth from 'firebase/app';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  aToken: string;


  username: string;
  password: string;
  invalidLogin = false;
  capsOn = false;
  loading = false;
  errorMessage = '';



  constructor(public auth: AngularFireAuth, public authService: AuthService) { }

  ngOnInit(): void {
  }

  checkCaps(event) {
    this.capsOn = false;
    try {
      if (event.getModifierState('CapsLock')) { this.capsOn = true; }
    } catch (error) {}
  }

  login2(){

    this.authService.login2(this.username, this.password);

  }
}
